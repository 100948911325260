<template>
  <div class="flex flex-column pt-110">
    <CommonHeader title="我的订单"></CommonHeader>
    <div
      class=" flex tab-list justify-center position-fixed left-0 right-0 bg-white">
      <div class="tab-item"
        :class="[tabIdx == idx && 'active']"
        v-for="(item,idx) in tabList" :key="idx"
        @click="tabChange(item,idx)">{{item.name}}</div>
    </div>
    <div class="flex flex-column px-38 order-list mt-110">
      <van-pull-refresh v-model="refreshing"
        @refresh="onRefresh">
        <van-list v-model="loading" :offset="1500"
          :finished="finished" finished-text="没有更多了"
          @load="onLoad">
          <div class="order-item flex flex-column"
            v-for="(item,idx) in currentList" :key="idx">
            <div class="flex justify-between">
              <div class="flex flex-column w-416">
                <div
                  class="font-27 text-default line-h-38 font-weight-bolder text-left">{{item.product &&
                                    item.product.name}}
                </div>
                <div
                  class="font-23 text-gray line-h-32 mt-10 text-left">
                  {{item.product && item.product.desc}}
                </div>
                <div
                  class="font-23 text-gray line-h-32 mt-10 text-left">
                  {{item.created_at}}</div>
              </div>
              <div class="">
                <div class="font-23 line-h-32"
                  :style="{color: calStatusColor(item.status)}">
                  {{item.status_cn}}
                </div>
                <div
                  class="font-38 font-weight-bolder text-default mt-20">
                  <span class="font-26">¥ </span><span
                    class="ml-04">{{item.pay_price / 100}}</span>
                </div>
              </div>
            </div>
            <div class="flex justify-end mt-20"
              v-if="item.status == 0">
              <div class="cancel-btn mr-20"
                @click="cacelOrder(item)">取消订单</div>
              <div class="submit-primary-btn"
                @click="coinOrderPayReq(item)">
                <van-count-down
                  v-if="defaultConfig && defaultConfig.coin_order && defaultConfig.coin_order.cancel_time"
                  :time="defaultConfig.coin_order.cancel_time * 1000" />
                <span class="ml-04">去支付</span>
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>

      <van-empty v-if="currentList.length == 0"
        description="暂无订单" />
    </div>
  </div>
</template>

<script>
import util from "@/utils/util";
import {
  getCoinOrderList,
  deleteCoinOrder,
  coinOrderPay,
  coinOrderCancel,
  getCoinOrderInfo,
} from "@/api/coinOrder";
import { getDefaultConfig } from "@/api/user";
import {
  Icon,
  Notify,
  Empty,
  CountDown,
  Dialog,
  List,
  PullRefresh,
  Toast,
} from "vant";
import CommonHeader from "@/components/header/commonHeader.vue";
export default {
  components: {
    VanEmpty: Empty,
    VanCountDown: CountDown,
    CommonHeader,
    VanPullRefresh: PullRefresh,
    VanList: List,
  },
  data() {
    return {
      tabList: [
        {
          name: "全部订单",
        },
        {
          name: "待支付",
        },
      ],
      tabIdx: 0,
      currentList: [],
      timestamp: 900,
      content: "确定取消该订单",
      show: false,
      statusLoadmore: "loadmore",
      current: 1,
      isLoading: true,
      params: {
        status: 99,
        pageSize: 0,
        offset: 0,
        limit: 20,
      },
      currentItem: null,
      canClick: true,
      orderInfo: null,
      defaultConfig: null,
      loading: false,
      finished: false,
      refreshing: false,
      isFirst: true,
    };
  },
  created() {
    util.onLogin().then((res) => {
      if (!res) {
        this.$bus.$emit("openLogin");
      } else {
        this.getDefaultConfigReq();
      }
    });

    this.$bus.$off("initData");
    this.$bus.$on("initData", () => {
      this.getDefaultConfigReq();
    });
  },
  methods: {
    onLoad() {
      console.log("onload");
      if (this.refreshing) {
        this.currentList = [];
        this.refreshing = false;
      }

      this.finished = true;
      if (!this.isFirst) {
        this.params.pageSize += 1;
        this.params.offset = this.params.pageSize * this.params.limit;
      } else {
        this.isFirst = false;
      }

      this.getCoinOrderListReq();
    },
    onRefresh() {
      console.log("ref");
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.params.pageSize = 0;
      this.params.offset = 0;
      this.getCoinOrderListReq();
    },
    getDefaultConfigReq() {
      getDefaultConfig().then((res) => {
        this.defaultConfig = res.data || null;
      });
    },
    tabChange(item, idx) {
      this.currentList = [];
      this.params.pageSize = 0;
      this.params.offset = 0;
      this.tabIdx = idx;
      if (this.tabIdx == 0) {
        this.params.status = 99;
      } else {
        this.params.status = 0;
      }
      this.getCoinOrderListReq();
    },
    calStatusColor(status) {
      switch (status) {
        case 3:
          return "#3672FD";
          break;
        case 0:
          return "#FF5959";
          break;
        default:
          return "#1A232F";
          break;
      }
    },
    calPayStatus(status) {
      switch (status) {
        case 1:
          return "已支付";
          break;
        case 2:
          return "待支付";
          break;
      }
    },
    cacelOrder(item) {
      this.show = true;
      this.currentItem = item;
      Dialog.confirm({
        title: "提示",
        message: "确定取消该订单",
      })
        .then(() => {
          // on confirm
          this.confirm();
        })
        .catch(() => {
          // on cancel
        });
    },
    confirm() {
      if (!this.canClick) {
        return;
      }
      this.canClick = false;
      coinOrderCancel({
        id: this.currentItem.id,
      })
        .then((res) => {
          console.log("coinOrderCancel", res);
          this.canClick = true;
          if (res && res.error == 0) {
            Toast.success("取消订单成功");
            this.getCoinOrderInfoReq(this.currentItem.id);
          } else {
            Toast.fail((err && err.msg) || "服务器异常，请稍后重试");
          }
        })
        .catch((err) => {
          this.canClick = true;
          Toast.fail((err && err.msg) || "服务器异常，请稍后重试");
          this.getCoinOrderListReq(this.currentItem.id);
        });
    },
    getCoinOrderListReq() {
      getCoinOrderList({
        status: this.params.status,
        offset: this.params.offset,
        limit: this.params.limit,
      })
        .then((res) => {
          console.log("res", res);
          this.loading = false;
          this.refreshing = false;
          if (res && res.data && res.data.list) {
            res.data.list.forEach((billItem) => {
              const isExist = this.currentList.find(
                (item) => item.id === billItem.id
              );
              const tempItem = {
                id: billItem.id,
                created_at: billItem.created_at,
                pay_price: billItem.pay_price,
                price: billItem.price,
                no: billItem.no,
                status: billItem.status,
                status_cn: billItem.status_cn,
                product: billItem.product,
              };
              if (isExist) {
                return;
              } else {
                this.currentList.push(tempItem);
              }
            });
          }

          if (res && res.data && res.data.has_more) {
            this.finished = false;
          } else {
            this.finished = true;
          }
          console.log("loading", this.loading);
        })
        .catch((err) => {
          this.loading = false;
          this.refreshing = false;
          this.finished = true;
          Toast.fail((err && err.msg) || "服务器异常，请稍后重试");
        });
    },
    getCoinOrderInfoReq(id) {
      getCoinOrderInfo(id).then((res) => {
        console.log("getCoinOrderInfo", res);
        if (res && res.error == 0 && res.data) {
          const isExist = this.currentList.find(
            (item) => item.id === res.data.id
          );
          if (isExist) {
            let idx = this.currentList.findIndex(
              (item) => item.id === res.data.id
            );
            console.log("idx", idx);
            this.$set(this.currentList, idx, res.data);
          }
        }
      });
    },
    coinOrderPayReq(item) {
      console.log("item",item)
      this.$router.push({
        path: "/Pay?orderCurrentInfo=" +
          JSON.stringify(item) +
          "&from=order",
      })
      // this.orderInfo = item;
      // if (this.orderInfo && this.orderInfo.id) {
      //   if (!this.canClick) {
      //     return;
      //   }
      //   this.canClick = false;
      //   let param = {
      //     channel: "wx_official",
      //     id: this.orderInfo && this.orderInfo.id,
      //   };
      //   coinOrderPay(param)
      //     .then((res) => {
      //       if (res && res.error == 0 && res.data) {
      //         let data = res.data;

      //         const payParam = {
      //           timeStamp: data.timeStamp.toString(),
      //           nonceStr: data.nonceStr,
      //           package: data.package,
      //           signType: data.signType,
      //           paySign: data.paySign,
      //         };
      //         console.log("pay param :>> ", payParam);
      //         // wx.requestPayment({
      //         //   timeStamp: data.timeStamp.toString(),
      //         //   nonceStr: data.nonceStr,
      //         //   package: data.package,
      //         //   signType: data.signType,
      //         //   paySign: data.paySign,
      //         //   success: (sucRes) => {
      //         //     this.canClick = true;
      //         //     console.log("pay success res :>> ", sucRes);
      //         //     setTimeout(() => {
      //         //       // uni.hideLoading();
      //         //       this.getCoinOrderInfoReq(this.orderInfo.id);
      //         //       // success && success();
      //         //     }, 500);
      //         //   },
      //         //   fail: (failRes) => {
      //         //     this.canClick = true;
      //         //   //   uni.hideLoading();
      //         //     this.getCoinOrderInfoReq(this.orderInfo.id);
      //         //     console.log("pay fail res :>> ", failRes);
      //         //     // fail && fail();
      //         //   },
      //         // });
      //       }
      //     })
      //     .catch((err) => {
      //       this.canClick = true;
      //       Toast.fail((err && err.msg) || "服务器异常，请稍后重试");
      //       // this.getCoinOrderInfoReq();
      //     });
      // } else {
      //   Toast.fail("暂无订单信息");
      // }
    },
  },
};
</script>

<style lang="less" scoped>
@import url(@/assets/css/tabList.less);

.order-list {
  .order-item {
    width: 100%;
    background: #f8f8fa;
    border-radius: 19px;
    padding: 32px 38px 38px 32px;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    ::v-deep .van-count-down {
      color: #fff !important;
      font-size: 23rpx !important;
    }

    .cancel-btn {
      padding: 0 40px;
      height: 53px;
      background: #ffffff;
      box-shadow: 0 4px 8px 0 #e7e8ed;
      border-radius: 34px;
      border: 1px solid #e7e8ed;
      font-weight: 400;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 23px;
      color: #626a83;
    }

    .submit-primary-btn {
      padding: 0 30px;
      height: 53px;
      background: #3672fd;
      box-shadow: 0 4px 8px 0 #d9e5ff;
      border-radius: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 23px;
      color: #fff;
    }
  }
}
</style>