<template>
  <div id="app" v-cloak>
    <!-- <transition name="fade-transform" mode="out-in">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    </transition> -->
    <keep-alive>
      <router-view
        v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view
      v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {};
  },
};
</script>

<style>
#app {
  text-align: center;
  width: 100%;
  height: 100%;
  position: relative;
  user-select: none;
}
.van-popup {
  z-index: 10004 !important;
}
.van-overlay {
  z-index: 10003 !important;
}
.van-dialog {
  z-index: 10004 !important;
}
.van-image-preview {
  z-index: 10004 !important;
}
</style>
