<template>
  <div class="community-list mt-38">
    <!-- <div class="sort-list">
      <div class="sort-item" :class="[item.sortBy == params.sort_by && 'active']" v-for="(item,idx) in sortList" :key="idx" @click="changeSort(item)">
        {{item.name}}
      </div>
    </div> -->
    <van-dropdown-menu>
      <van-dropdown-item v-model="sortBy" :options="sortList" @change="selectSort"/>
    </van-dropdown-menu>
    <van-pull-refresh v-model="refreshing"
      @refresh="onRefresh">
      <van-list v-model="loading" :offset="1500"
        :finished="finished" finished-text="没有更多了"
        @load="onLoad">
        <div class="community-item"
          v-for="(item,idx) in postList" :key="idx" @click="goDetail(item)">
          <div class="community-img" v-if="item.imgSrc">
            <img :src="item.imgSrc">
          </div>
          <div class="community-info">
            <div class="name two-text">{{item.title || ""}}
            </div>
            <!-- <div class="desc" v-if="!item.imgSrc && item.desc">
            {{item.desc}}</div> -->
            <div class="user-info">
              <img class="avatar"
                v-if="item.user && item.user.avatar"
                :src="item.user.avatar">
              <img class="avatar" v-else
                src="https://files.sssrise.com/files/uploads/static/default_avatar.png">
              <div class="user-name">
                {{item.user && item.user.nickname || ""}}
              </div>
              <!-- <img class="bade-img"
                src="https://files.sssrise.com/files/uploads/static/cyhz.png">
              <img class="bade-img"
                src="https://files.sssrise.com/files/uploads/static/zshz.png">
              <img class="bade-img"
                src="https://files.sssrise.com/files/uploads/static/xzhz.png">
              <div class="follow-box ml-30">
                <div class="follow-btn">+ 关注</div>
              </div> -->
            </div>
            <div class="bottom">
              <div class="create-time">{{item.created_at}}
              </div>
              <div class="fav-box">

                <img src="@/assets/images/home/good.png">
                <div class="fav">{{item.like}}</div>
              </div>
            </div>

          </div>
        </div>
      </van-list>
    </van-pull-refresh>

  </div>
</template>
<script>
import { getPostList, getPostInfo } from "@/api/content";
import { Empty, List, PullRefresh, Toast,DropdownMenu, DropdownItem } from "vant";
export default {
  components: {
    VanEmpty: Empty,
    VanPullRefresh: PullRefresh,
    VanList: List,
    VanDropdownMenu: DropdownMenu,
    VanDropdownItem: DropdownItem
  },
  data() {
    return {
      sortList: [
        {
          id: 2,
          text: "发布时间",
          value: "created_at",
        },
        {
          id: 3,
          text: "点赞量",
          value: "like",
        },
      ],
      sortBy: "created_at",
      postList: [],
      params: {
        sort: "desc",
        pageSize: 0,
        offset: 0,
        limit: 20,
        sort_by: "created_at", //created_at发布时间 like点赞量 price价格
      },
      statusLoadmore: "loadmore",
      isLoading: true,
      loading: false,
      finished: false,
      refreshing: false,
      isFirst: true,
    };
  },
  methods: {
    selectSort(val) {
      this.sortBy = val;
      this.params["sort_by"] = val;
      this.clear();
      this.getPostListReq();
    },
    changeSort(item) {
      this.params["sort_by"] = item.sortBy;
      this.clear();
      this.getPostListReq();
    },
    onLoad() {
      console.log("onload");
      if (this.refreshing) {
        this.currentList = [];
        this.refreshing = false;
      }

      this.finished = true;
      if (!this.isFirst) {
        this.params.pageSize += 1;
        this.params.offset = this.params.pageSize * this.params.limit;
      } else {
        this.isFirst = false;
      }

      this.getPostListReq();
    },
    onRefresh() {
      console.log("ref");
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.params.pageSize = 0;
      this.params.offset = 0;
      this.getPostListReq();
    },
    clear() {
      this.params.pageSize = 0;
      this.params.offset = 0;
      this.postList = [];
    },
    getPostListReq() {
      getPostList(this.params)
        .then((res) => {
          if (res.error == 0) {
            this.loading = false;
            this.refreshing = false;
            let list = (res && res.data && res.data.list) || [];
            if (list && list.length > 0) {
              list.forEach((billItem) => {
                const isExist = this.postList.find(
                  (item) => item.id === billItem.id
                );
                const tempItem = billItem;
                if (isExist) {
                  return;
                } else {
                  this.postList.push(tempItem);
                }
              });
            }
            if (res && res.data && res.data.has_more) {
              this.statusLoadmore = "loadmore";
              this.finished = false;
            } else {
              this.statusLoadmore = "nomore";
              this.finished = true;
            }
          } else {
            this.statusLoadmore = "loadmore";
            this.loading = false;
            this.refreshing = false;
            this.finished = true;
            Toast.fail((res && res.msg) || "服务器异常，请稍后重试");
          }
        })
        .catch((err) => {
          this.statusLoadmore = "loadmore";
          this.loading = false;
          this.refreshing = false;
          this.finished = true;
          Toast.fail((err && err.msg) || "服务器异常，请稍后重试");
        });
    },
    goDetail(item) {
      this.$router.push({
        path: "ContentDetail?id=" + item.id + "&from=creative",
      });
    }
  },
};
</script>
<style lang="less" scoped>
.sort-list {
  display: flex;
  justify-content: flex-end;
  padding: 0 0 20px 0;
  .sort-item {
    height: 42px;
    font-size: 28px;
    color: #1a232f;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    &.active {
      border: 1px solid #3672fd;
      font-weight: 400;
      color: #3672fd;
    }
  }
}
.community-list {
  display: flex;
  flex-direction: column;
  padding-bottom: 60px;
  position: relative;
  ::v-deep .van-dropdown-menu {
    position: absolute;
    right: 10px;
    top: -90px;
    width: 160px;
    z-index: 999;
    .van-dropdown-menu__bar {
      height: 40px;
      background: transparent;
      box-shadow: none;
      border: 1px solid rgba(151, 157, 177, 0.4);
      border-radius: 20px;
      .van-dropdown-menu__title {
        font-size: 24px;
      }
    }
  }
  .community-item {
    width: 100%;
    background: #f8f8fa;
    border-radius: 20px;
    margin-bottom: 20px;
    padding: 20px 25px 20px 20px;
    display: flex;

    .community-img {
      padding-right: 20px;

      img {
        width: 138px;
        height: 138px;
      }
    }

    .community-info {
      display: flex;
      flex-direction: column;
      width: 100%;

      .name {
        font-weight: 400;
        font-size: 28px;
        color: #1a232f;
        line-height: 38px;
        text-align: left;
      }

      .desc {
        font-weight: 400;
        font-size: 24px;
        color: #626a83;
        line-height: 32px;
        margin-top: 14px;
        text-align: left;
      }

      .user-info {
        display: flex;
        margin-top: 12px;
        align-items: center;

        .avatar {
          width: 46px;
          height: 46px;
          border-radius: 100%;
        }

        .user-name {
          font-weight: 400;
          font-size: 24px;
          color: #1a232f;
          line-height: 32px;
          margin-left: 12px;
        }

        .bade-img {
          width: 40px;
          height: 40px;
          margin-left: 4px;
        }

        .follow-box {
          .follow-btn {
            height: 32px;
            background: #d9e5ff;
            box-shadow: 0 4px 8px 0 #d9e5ff;
            border-radius: 10px;
            border: 1px solid #3672fd;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 10px;
            font-weight: 400;
            font-size: 19px;
            color: #3672fd;
          }

          .folowed {
            height: 32px;
            background: #f3f3f8;
            border-radius: 10px;
            border: 1px solid #979db1;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 10px;
            font-weight: 400;
            font-size: 19px;
            color: #626a83;
          }
        }
      }

      .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 15px;

        .create-time {
          font-weight: 400;
          font-size: 20px;
          color: #979db1;
        }

        .fav-box {
          display: flex;
          align-items: center;

          img {
            width: 34px;
            height: 34px;
          }

          .fav {
            font-weight: 400;
            font-size: 24px;
            color: #626a83;
            line-height: 32px;
            margin-left: 8px;
          }
        }
      }
    }
  }
}
</style>