<template>
    <div class="report-day-box">
      <div class="tab-list">
        <div class="tab-item"
          :class="[currentType == item.type ? 'active' :'']"
          v-for="(item,idx) in tabList" :key="idx"
          @click="changeTab(item)">
          {{item.name}}
        </div>
      </div>
      <div class="current-day-info" @click="showYear = true">
        {{year || "请选择年月"}}
        <div class="icon-down">
          <img src="@/assets/images/common/icon_down.png">
        </div>
  
      </div>
      <van-popup v-model="showYear" position="bottom">
        <van-datetime-picker class="year-picker-box"
          v-model="yearPick" type="year-month" title="选择年月"
          :formatter="formatter" item-height="50"
          @confirm="selectYear" @cancel="closePup" />
      </van-popup>
    </div>
  </template>
  <script>
  import { DatetimePicker, Popup } from "vant";
  export default {
    components: {
      vanPopup: Popup,
      vanDatetimePicker: DatetimePicker,
    },
    data() {
      return {
        tabList: [
          {
            name: "周",
            type: 1,
          },
          {
            name: "月",
            type: 2,
          },
          {
            name: "年",
            type: 3,
          },
        ],
        currentType: 1,
        yearPick: new Date(),
        showYear: false,
        year: "",
      };
    },
    methods: {
      changeTab(item) {
        this.currentType = item.type;
      },
      closePup() {
        this.showYear = false;
      },
      formatter(type, val) {
        if (type === "year") {
          return `${val}年`;
        } else if (type === "month") {
          return `${val}`;
        }
        return val;
      },
      selectYear(val) {
        console.log("selectYear", val);
        if (val) {
          this.year = val.getFullYear() + "年" + (val.getMonth() + 1) + "月";
        }
        this.showYear = false;
      },
    },
  };
  </script>
  <style lang="less" scoped>
  .report-day-box {
    background: #fff;
    z-index: 99;
    .tab-list {
      display: flex;
      width: 360px;
      height: 60px;
      background: #f3f3f8;
      border-radius: 31px;
      align-items: center;
      margin: 0 auto;
      .tab-item {
        flex: 1;
        font-weight: 400;
        font-size: 27px;
        color: #626a83;
        display: flex;
        justify-content: center;
        height: 100%;
        align-items: center;
        &.active {
          background: #3672fd;
          box-shadow: 0 4px 8px 0 #d9e5ff;
          border-radius: 31px;
          color: #fff;
        }
      }
    }
    .current-day-info {
      width: 225px;
      height: 46px;
      background: #f3f3f8;
      border-radius: 31px;
      position: relative;
      padding-left: 20px;
      font-weight: 400;
      font-size: 23px;
      color: #1a232f;
      display: flex;
      align-items: center;
      margin-top: 20px;
      .icon-down {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 20px;
        align-items: center;
        display: flex;
        img {
          width: 24px;
        }
      }
    }
  }
  </style>
  