<template>
    <div class="rank-box">
        <div class="flex align-center pl-20 mb-20" v-if="!hideTitle">
            <div class="text-border"></div>
            <div class="default-title mt-0">{{title}}<span class="sub-title" v-if="subTitle">({{subTitle}})</span></div>

        </div>
        <div class="rank-list">
            <div class="rank-item" v-for="(item,idx) in currentList" :key="idx">
                <div class="rank-info-list" v-for="(subItem,secIdx) in item.rankList" :key="secIdx">

                    <div class="rank-info">
                        <div class="num"><span>{{secIdx + 1}}</span>
                        </div>
                        <div class="name one-text">{{subItem.name}}
                        </div>
                        <div class="percent">
                            <van-rate v-model="+subItem.star" :size="20" void-color="#D9E5FF" color="#88ACFF" readonly  />
                        </div>
                        <div class="percent-count">{{subItem.star}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { Rate } from 'vant';
    export default {
        props: ["title", "subTitle", "currentList", "bgColor", "hideTitle"],
        components: {
            vanRate: Rate
        },
        methods: {
            calPerWidth(rankList, percent) {
                let sum = 0;
                for (let i = 0, j = rankList.length; i < j; i++) {
                    sum += Number(rankList[i].percent);
                }
                if (percent == 0) {
                    return 0;
                }
                if (sum == 0) {
                    return 0;
                } else {
                    return parseFloat(Number(percent) / sum * 100).toFixed(2);
                }

            }
        }
    };
</script>
<style lang="less" scoped>
    @import url("@/assets/css/rankTitle.less");

    .rank-box {
        display: flex;
        width: 100%;
        background: #ffffff;
        margin-bottom: 20px;
        flex-direction: column;

        .rank-list {
            display: flex;
            width: 100%;
            padding: 0 20px;

            .rank-item {
                flex: 1;
                display: flex;
                flex-direction: column;
                position: relative;

                .zhanbi {
                    font-weight: 400;
                    font-size: 24px;
                    color: #979db1;
                    line-height: 28px;
                    position: absolute;
                    right: 20px;
                    top: -60px;
                }

                .rank-info-list {
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    margin-bottom: 16px;
                    justify-content: center;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                .rank-info {
                    display: flex;
                    padding: 0 6px 0 10px;
                    align-items: center;

                    .num {
                        width: 32px;
                        height: 32px;
                        background: #fbfbfc;
                        box-shadow: 0px 4px 8px 0px #e7e8ed;
                        border: 1px solid #e7e8ed;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 100%;

                        span {
                            font-weight: bold;
                            font-size: 19px;
                            color: #1a232f;
                        }
                    }

                    .name {
                        width: 120px;
                        font-weight: 400;
                        font-size: 23px;
                        line-height: 32px;
                        color: #1a232f;
                        text-align: left;
                        margin-left: 20px;
                    }

                    .percent {
                        flex: 1;
                        display: flex;
                    }

                    .percent-count {
                        width: 72px;
                        font-weight: 400;
                        font-size: 24px;
                        color: #626a83;
                        margin-left: 12px;
                    }
                }
            }
        }
    }
</style>