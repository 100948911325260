<template>
    <div class="desc-list-box">
        <div class="desc-list">
            <div class="desc-item" v-for="(item,idx) in descList" :key="idx">
                <div class="desc-img pr-30" v-if="idx % 2 == 0">
                    <img :src="item.imgSrc">
                </div>
                <div class="desc-info">
                    <div class="name">{{item.name}}</div>
                    <div class="desc">{{item.desc}}</div>
                </div>
                <div class="desc-img pl-30" v-if="idx % 2 == 1">
                    <img :src="item.imgSrc">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                descList: [{
                    name: "主流AI大模型接入",
                    desc: '我是您的育儿智能工具，随时准备为您提供专业的育儿建议和支持。我是您的育儿智能工具，随时准备为您提供专业的育儿建议和支持。',
                    imgSrc: require("@/assets/images/common/ai_model.png")
                },{
                    name: "精细化行业适配",
                    desc: '我是您的育儿智能工具，随时准备为您提供专业的育儿建议和支持。我是您的育儿智能工具，随时准备为您提供专业的育儿建议和支持。',
                    imgSrc: require("@/assets/images/common/shipei.png")
                }]
            }
        }
    }
</script>
<style lang="less" scoped>
    .desc-list-box {
        padding: 0 70px;
        margin-top: 78px;
        .desc-list {
            display: flex;
            flex-direction: column;
            .desc-item {
                display: flex;
                margin-bottom: 56px;
                .desc-img {
                    img {
                        width: 240px;
                        height: 240px;
                        border-radius: 20px;
                    }
                }
                .desc-info {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    .name {
                        font-weight: bold;
                        font-size: 32px;
                        color: #3672FD;
                        line-height: 46px;
                    }
                    .desc {
                        font-weight: 400;
                        font-size: 23px;
                        color: #626A83;
                        line-height: 32px;
                        margin-top: 10px;
                        text-align: left;
                    }
                }
            }
        }
    }
</style>