<template>
    <div class="market-box px-38">
        <NavTab :currentType="currentType"></NavTab>
        <div class="market-content">
            <ContentList></ContentList>
        </div>
        <div class="creative-bottom">
            <div class="create-btn" @click="goCollect">
                <img src="@/assets/images/common/icon_plus.png">征集创意
            </div>
        </div>
    </div>
</template>
<script>
import NavTab from "@/components/header/navTab.vue";
import ContentList from "@/components/creative/contentList.vue";
export default {
    components: {
        NavTab,
        ContentList
    },
    data() {
        return {
            currentType: 2
        }
    },
    methods: {
        goCollect() {
            this.$router.push({
                path: "Collect"
            })
        }
    }
}
</script>
<style lang="less" scoped>
.market-box {
    padding-top: 200px;
    .market-content {
        margin-top: 30px;
    }
    .creative-bottom {
        height: 168px;
        background: rgba(243,243,248,0.8);
        box-shadow: inset 0 1px 0 0 #E7E8ED;
        backdrop-filter: blur(10px);
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        .create-btn {
            width: 674px;
            height: 84px;
            background: #D9E5FF;
            box-shadow: 0 4px 8px 0 #D9E5FF;
            border-radius: 46px;
            border: 1px solid #3672FD;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 400;
            font-size: 32px;
            color: #3672FD;
            img {
                width: 46px;
                height: 46px;
                margin-right: 20px;
            }
        }
    }
}
</style>