<template>
  <div class="category-box">

    <div class="comprehensive-box">

      <div class="radar-list">
        <div class="flex align-center pl-20 mb-20">
          <div class="text-border"></div>
          <div class="default-title mt-0">综合评分</div>
        </div>
        <!-- <div class="radar-item"
          v-for="(item,idx) in radarList" :key="idx">
          <div class="name">{{item.name}}</div>
          <div class="count">{{item.value}}</div>
        </div> -->
        <div class="ip-img" >
          <van-image v-if="ipInfo && ipInfo.cover_img" :src="ipInfo.cover_img" fit="contain"  @click="preview(ipInfo.cover_img)"></van-image>
          <!-- <van-image :src="testImg" fit="contain" @click="preview(testImg)"></van-image> -->
        </div>
      </div>
      <div class="radar-ana">
        <div id="radarAna"></div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import {Image, ImagePreview  } from 'vant';
export default {
  props: ["ipInfo","allScore"],
  components: {
    VanImage: Image
  },
  data() {
    return {
      testImg: require("@/assets/images/test.png")
    };
  },
  watch: {
    allScore() {
      this.$nextTick(() => {
        this.initRadar();
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initRadar();
    });
  },
  methods: {
    preview(img) {
      ImagePreview([img]);
    },
    initRadar() {
      let chartDom = document.getElementById("radarAna");
      let myChart = echarts.init(chartDom);
      let option;
      let seriesData = [];
      let indicator = [];
      for (let i = 0, j = this.allScore.length; i < j; i++) {
        seriesData.push(this.allScore[i].value);
        indicator.push(this.allScore[i].name);
      }
      console.log("seriesData", seriesData);
      option = {
        radar: {
          // shape: 'circle',
          indicator: this.allScore,
          axisName: {
            formatter: function (value, params) {
              console.log("params", params);
              return (
                "" +
                params.name +
                "" +
                "\n" +
                "" +
                params.value +
                ""
              );
            },
            rich: {
              // 属性上部分的样式
              top: {
                color: "#626A83",
                fontSize: 12,
                fontWeight: 400,
                align: "center",
                lineHeight: 20,
              },
              // 属性下部分样式
              end: {
                color: "#3672FD",
                fontSize: 12,
                align: "center",
                lineHeight: 20,
              },
            },
          },
          radius: 80,
        },
        tooltip: {
          trigger: "axis",
          show: false
        },
        series: [
          {
            name: "",
            type: "radar",
            tooltip: {
              trigger: "item",
            },
            lineStyle: {
              color: "rgba(136, 172, 255, 1)",
            },
            areaStyle: {
              color: "rgba(217,229,255,0.6)",
            },
            data: [
              {
                value: seriesData,
                name: "综合评分",
              },
            ],
          },
        ],
      };
      console.log("option", option);
      option && myChart.setOption(option);
    },
  },
};
</script>
<style lang="less" scoped>
.category-box {
  display: flex;
  flex-direction: column;
  .comprehensive-box {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    .ip-img {
      /* width: 90%; */
      display: flex;
      justify-content: center;
    }
    .radar-list {
      display: flex;
      padding-left: 20px;
      flex-direction: column;
      padding-top: 10px;

      .radar-item {
        display: flex;
        height: 50px;
        align-items: center;
        width: 200px;

        .name {
          font-weight: 500;
          font-size: 24px;
          color: #626a83;
          line-height: 50px;
          text-align: left;
          font-style: normal;
          padding-right: 20px;
        }

        .count {
          font-weight: bold;
          font-size: 24px;
          color: #3672fd;
          line-height: 50px;
          text-align: left;
          font-style: normal;
        }
      }
    }

    .radar-ana {
      flex: 1;
      div {
        width: 90%;
        height: 520px;
        margin: 0 auto;
      }
    }
  }
}
</style>