<template>
  <div class="sales-data-box">
    <div class="rank-title"><span>IP分析</span></div>
    <div class="evaluate-box">
      <div class="evaluate-list ip-evaluate-list">
        <div class="evaluate-item">
          <div class="left-info">
            <img src="@/assets/images/market/yingshi.png">
          </div>
          <div class="right-info">
            <div class="tag-list">
              <div class="tag-item tag-year">
                <span>{{ipInfo && ipInfo.year}}</span>
              </div>
              <div class="flex" v-if="ipInfo && ipInfo.tags">
                  <div class="tag-item tag-action" v-for="(item,idx) in ipInfo.tags" :key="idx"><span>{{item}}</span></div>
              </div>
             
            </div>
            <div class="user-desc">
              用户评价<span>({{ipInfo && ipInfo.rating_people || 0}}人参与评分）</span></div>
            <div class="user-rate">
              <van-rate v-model="rateNum" :size="20"
                void-color="#F3F3F8" color="#ECCD8E"
                readonly />
              <span class="rate-score">{{ipInfo && ipInfo.rate || 0}}</span>
              <!-- <span class="rate-before">超越了85%影视IP</span> -->
            </div>
            <div class="star-list">
              <div class="star-item">
                <div class="star-desc">5星</div>
                <van-progress :percentage="ipInfo && ipInfo.star5_pct || 0"
                  stroke-width="10" color="#ECCD8E"
                  track-color="#F3F3F8"
                  :show-pivot="false" />
              </div>
              <div class="star-item">
                <div class="star-desc">4星</div>
                <van-progress :percentage="ipInfo && ipInfo.star4_pct || 0"
                  stroke-width="10" color="#ECCD8E"
                  track-color="#F3F3F8"
                  :show-pivot="false" />
              </div>
              <div class="star-item">
                <div class="star-desc">3星</div>
                <van-progress :percentage="ipInfo && ipInfo.star3_pct || 0"
                  stroke-width="10" color="#ECCD8E"
                  track-color="#F3F3F8"
                  :show-pivot="false" />
              </div>
              <div class="star-item">
                <div class="star-desc">2星</div>
                <van-progress :percentage="ipInfo && ipInfo.star2_pct || 0"
                  stroke-width="10" color="#ECCD8E"
                  track-color="#F3F3F8"
                  :show-pivot="false" />
              </div>
              <div class="star-item">
                <div class="star-desc">1星</div>
                <van-progress :percentage="ipInfo && ipInfo.star1_pct || 0"
                  stroke-width="10" color="#ECCD8E"
                  track-color="#F3F3F8"
                  :show-pivot="false" />
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="evaluate-item">
          <div class="left-info">
            <img src="@/assets/images/market/youxi.png">
          </div>
          <div class="right-info">
            <div class="tag-list">
              <div class="tag-item tag-year">
                <span>2018</span>
              </div>
              <div class="tag-item tag-action">
                <span>即时战略</span>
              </div>
              <div class="tag-item tag-type"><span>奇幻</span>
              </div>
            </div>
            <div class="user-desc">
              用户评价<span>(12345人参与评分）</span></div>
            <div class="user-rate">
              <van-rate v-model="rateNum" :size="20"
                void-color="#F3F3F8" color="#ECCD8E"
                readonly />
              <span class="rate-score">8.8</span>
              <span class="rate-before">超越了85%影视IP</span>
            </div>
            <div class="star-list">
              <div class="star-item">
                <div class="star-desc">5星</div>
                <van-progress :percentage="50"
                  stroke-width="10" color="#ECCD8E"
                  track-color="#F3F3F8"
                  :show-pivot="false" />
              </div>
              <div class="star-item">
                <div class="star-desc">4星</div>
                <van-progress :percentage="50"
                  stroke-width="10" color="#ECCD8E"
                  track-color="#F3F3F8"
                  :show-pivot="false" />
              </div>
              <div class="star-item">
                <div class="star-desc">3星</div>
                <van-progress :percentage="50"
                  stroke-width="10" color="#ECCD8E"
                  track-color="#F3F3F8"
                  :show-pivot="false" />
              </div>
              <div class="star-item">
                <div class="star-desc">2星</div>
                <van-progress :percentage="50"
                  stroke-width="10" color="#ECCD8E"
                  track-color="#F3F3F8"
                  :show-pivot="false" />
              </div>
              <div class="star-item">
                <div class="star-desc">1星</div>
                <van-progress :percentage="50"
                  stroke-width="10" color="#ECCD8E"
                  track-color="#F3F3F8"
                  :show-pivot="false" />
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import RankCom from "./rank.vue";
import { Rate, Progress } from "vant";
export default {
  props: ["ipInfo"],
  components: {
    RankCom,
    VanRate: Rate,
    VanProgress: Progress,
  },
  data() {
    return {
      topRank: [
        {
          name: "",
          rankList: [
            {
              name: "电动玩具",
              percent: "15.5",
            },
            {
              name: "益智玩具",
              percent: "14.7",
            },
            {
              name: "绘画玩具",
              percent: "12.1",
            },
            {
              name: "积木拼装",
              percent: "9.2",
            },
            {
              name: "橡皮泥",
              percent: "7.3",
            },
          ],
        },
      ],
      rateNum: 50
    };
  },
  methods: {},
};
</script>
<style lang="less" scoped>
@import url("@/assets/css/rankTitle.less");
.sales-data-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 20px;
  border: 1px solid #e7e8ed;
  .evaluate-box {
    padding: 20px;

    .evaluate-list {
      display: flex;
        &.ip-evaluate-list {
            flex-direction: column;
        }
      .evaluate-item {
        display: flex;
        flex: 1;

        .left-info {
          padding-right: 20px;

          img {
            width: 68px;
            height: 68px;
          }
        }

        .right-info {
          display: flex;
          flex-direction: column;
          flex: 1;

          .tag-list {
            display: flex;
            margin-top: 20px;

            .tag-item {
              margin-right: 10px;
            }

            .tag-year {
              padding: 0 16px;
              height: 32px;
              background: #f3f3f8;
              border-radius: 6px;
              border: 1px solid #e7e8ed;
              display: flex;
              align-items: center;
              justify-content: center;

              span {
                font-weight: 400;
                font-size: 24px;
                color: #1a232f;
              }
            }

            .tag-action {
              padding: 0 16px;
              height: 32px;
              background: #d9e5ff;
              border-radius: 6px;
              border: 1px solid #3672fd;
              display: flex;
              align-items: center;
              justify-content: center;

              span {
                font-weight: 400;
                font-size: 24px;
                color: #1a232f;
              }
            }

            .tag-type {
              padding: 0 16px;
              height: 32px;
              background: #f8e5ff;
              border-radius: 6px;
              border: 1px solid #d664ff;
              display: flex;
              align-items: center;
              justify-content: center;

              span {
                font-weight: 400;
                font-size: 24px;
                color: #1a232f;
              }
            }
          }

          .user-desc {
            font-weight: bold;
            font-size: 28px;
            color: #1a232f;
            line-height: 38px;
            text-align: left;
            display: flex;
            align-items: center;
            margin-top: 24px;

            span {
              font-weight: 400;
              font-size: 24px;
              color: #626a83;
              margin-left: 20px;
            }
          }

          .user-rate {
            display: flex;
            align-items: center;
            margin-top: 12px;

            .rate-score {
              font-weight: bold;
              font-size: 28px;
              color: #626a83;
              line-height: 40px;
              margin-left: 16px;
            }

            .rate-before {
              font-weight: 400;
              font-size: 24px;
              color: #1a232f;
              text-align: left;
              font-style: normal;
              margin-left: 16px;
            }

            ::v-deep .van-rate__item {
              i {
                font-size: 24px !important;
              }
            }
          }

          .star-list {
            display: flex;
            flex-direction: column;
            margin-top: 20px;

            .star-item {
              margin-bottom: 20px;
              display: flex;
              align-items: center;
              width: 100%;

              .star-desc {
                font-weight: 400;
                font-size: 24px;
                color: #1a232f;
                line-height: 32px;
                padding-right: 14px;
              }

              ::v-deep .van-progress {
                width: 140px;
              }
            }
          }
        }
      }
    }
  }
}
</style>