<template>
	<div class="positon-relative">
		<img style="max-width:460px;padding:0 20px;"  :src="item" @load="onImageLoad">
		<div class="position-relative" style="position: absolute;top:0;right:0;bottom: 0;left:0;z-index: 11;display:flex;align-items: center;
		justify-content: center;backdrop-filter: blur(10px);" v-if="loading">
			<div class="position-absolute loading-img" style="color: #3672FD;font-size: 24px;">图片加载中...</div>
			<van-loading mode="circle" color="#3672FD" size="200rpx;">
			</van-loading>
		</div>
	</div>
</template>

<script>
	import { Loading  } from "vant";
	export default {
		components: {
			VanLoading: Loading
		},
		props: ["item"],
		data() {
			return {
				loading: true
			}
		},
		created() {
			this.loading = true;
		},
		methods: {
			onImageLoad(e) {
				console.log("onImageLoad",e)
			  this.loading = false;
			}
		}
	}
</script>

<style>
</style>