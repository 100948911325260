import { render, staticRenderFns } from "./navTab.vue?vue&type=template&id=594b612a&scoped=true"
import script from "./navTab.vue?vue&type=script&lang=js"
export * from "./navTab.vue?vue&type=script&lang=js"
import style0 from "./navTab.vue?vue&type=style&index=0&id=594b612a&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "594b612a",
  null
  
)

export default component.exports