<template>
	<div class="problem-box">
		<div class="flex mr-30" v-if="problemItem && problemItem.showZhuanFa">
			<img v-if="problemItem && problemItem.zhuanFaChoose" class="icon-select mt-30" src="https://files.sssrise.com/files/uploads/static/icon_select_check.png" @click="chooseZhuanfa(1)">
			<img v-else class="icon-select mt-30" src="https://files.sssrise.com/files/uploads/static/icon_select_default.png" @click="chooseZhuanfa(2)">
		</div>
		<div class="problem-avatar">
			<img src="https://files.sssrise.com/files/uploads/static/ai_avatar.png">
		</div>
		<div class="problem-info">
			<div class="problem-desc position-relative">
				<div class="dhk-arrow"><img style="width: 40px;height: 32px;" src="https://files.sssrise.com/files/uploads/static/dhk_arrow.png"></div>
				<div class="strong">{{problemItem && problemItem.desc}}</div>
			</div>
			<div class="problem-two-list" v-if="problemItem && problemItem.children">
				<div class="problem-two-item" :class="[problemItem && problemItem.chooseIdx == idx && 'active']" v-for="(item,idx) in problemItem.children"
					:key="idx" @click="chooseProblem(item,idx)">
					<div class="problem-img">
						<img v-if="problemItem && problemItem.chooseIdx == idx" :src="item.check_image">
						<img v-else :src="item.default_image">
					</div>
					<div class="problem-detail">
						<div class="name">{{item.name}}</div>
						<div class="desc">{{item.tip_message}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ["problemItem","problemIdx"],
		name: "problemTwo",
		data() {
			return {
			};
		},
		created() {
		},
		methods: {
			chooseProblem(item,idx) {
				this.$emit("changeIdx",{
					problemIdx: this.problemIdx,
					idx: idx,
					item: item
				})
			},
			chooseZhuanfa(type) {
				this.$emit("chooseZhuanfa", {
					idx: this.problemIdx,
					type: type
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	@import url(@/assets/css/problemDesc.less);
</style>