<template>
    <div class="sales-data-box">
        <div class="rank-title"><span>竞品IP</span></div>
        <SaleTab class="mt-16" :tabList="saleList" :currentType="saleType" @changeTab="changeSaleType"></SaleTab>
        <CompareCom class="mt-20" :hideTitle="true" title="竞品IP" :currentList="compareIp" :showIdx="saleType"></CompareCom>
    </div>
</template>
<script>
    import SaleTab from "./saleTab.vue";
    import CompareCom from "./compare.vue";
    export default {
        props: ["compareIp"],
        components: {
            SaleTab,
            CompareCom
        },
        data() {
            return {
                saleList: [{
                    name: "销量",
                    type: 1
                }, {
                    name: "销售额",
                    type: 2
                }],
                saleType: 1
            }
        },
        methods: {
            changeSaleType(type) {
                this.saleType = type;
            }
        }
    }
</script>
<style lang="less" scoped>
    @import url("@/assets/css/rankTitle.less");

    .sales-data-box {
        width: 100%;
        display: flex;
        flex-direction: column;
        background: #FFFFFF;
        border-radius: 20px;
        border: 1px solid #E7E8ED;
    }
</style>