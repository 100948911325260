<template>
	<div class="fav-popup-box">
		<div class="fav-popup-layer" @click="closeReason"></div>
		<div class="fav-box">
			<div class="font-27 text-default font-weight-bolder line-h-38">您点赞的原因是</div>
			<div class="flex reason-box  flex-wrap ">
				<div class="flex align-center justify-center reason-item mt-20" :class="[reasonIdx == idx ? 'active' : '']"
				 v-for="(item,idx) in reasonList" :key="idx" @click="chooseReason(item,idx)">
					{{item.desc}}
				</div>
			</div>
			<div class="flex justify-end mt-38">
				<div class="submit-normal-btn" @click="submitReason">提交</div>
			</div>
		</div>
		
	</div>
</template>

<script>
	export default {
		data() {
			return {
				reasonList:[{
					id: 1,
					desc: "内容非常专业"
				},{
					id: 2,
					desc: "角度新奇"
				},{
					id: 3,
					desc: "非常创新"
				},{
					id: 4,
					desc: "其他"
				}],
				reasonIdx: -1
			}
		},
		created() {
			this.reasonIdx = -1;
		},
		methods: {
			chooseReason(item,idx) {
				this.reasonIdx = idx;
			},
			submitReason() {
				let reason = "";
				if(this.reasonIdx >=0 && this.reasonList[this.reasonIdx]) {
					reason = this.reasonList[this.reasonIdx].desc;
				}
				this.$emit("chooseReason",reason)
			},
			closeReason() {
				this.$emit("chooseReason","")
			}
		}
	}
</script>

<style lang="less" scoped>
.fav-popup-box {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 100003;
	.fav-popup-layer {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		background: transparent;
	}
	.fav-box {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 30px 38px 50px 38px;
		background: rgba(243,243,248,0.8);
		box-shadow: inset 0px 1px 0px 0px #E7E8ED;
		backdrop-filter: blur(10px);
		z-index: 1;
		.reason-box {
			
			.reason-item {
				width: 324px;
				height: 69px;
				background: #FFFFFF;
				box-shadow: 0px 4px 8px 0px #E7E8ED;
				border-radius: 10px;
				border: 1px solid #E7E8ED;
				margin-right: 24px;
				font-size: 23px;
				color: #626A83;
				&:nth-child(2n) {
					margin-right: 0;
				}
				&.active {
					background: #D9E5FF;
					box-shadow: 0px 4px 8px 0px #D9E5FF;
					border: 1px solid #3672FD;
					color: #3672FD;
					font-weight: bolder;
				}
			}
		}
	}
}

</style>
