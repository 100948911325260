<template>
  <div class="ip-flow-box">
    <div class="rank-title"><span>流量指数趋势</span></div>
    <AnalysisData  :currentAnalysisList="currentAnalysisList" :flowIndex="flowIndex"></AnalysisData>
    <div class="flow-analysis">
        <div class="flex align-center"><div class="text-border"></div><div class="default-title mt-0">人群年龄分布</div></div>
        <LineCom :ipInfo="ipInfo" :distributionAge="distributionAge" :distributionAgeDefault="distributionAgeDefault"></LineCom>
    </div>
    <!-- <RankCom class="mt-20" :hideTitle="false" title="IP营销分析"
      :currentList="ipMarket" bgColor="#9893EB" :hiddenLabel="true"></RankCom> -->
  </div>
</template>
<script>
import RankCom from "./rank.vue";
import AnalysisData from "./flowAnalysis.vue";
import LineCom from "./line.vue";
export default {
  props: ["flowIndex","ipInfo","distributionAge","distributionAgeDefault"],
  components: {
    RankCom,
    AnalysisData,
    LineCom
  },
  data() {
    return {
      currentAnalysisList: [
        {
          name: "需求指数",
          count: 4,
          percent: "30",
        },
        {
          name: "媒体关注度",
          count: 400,
          percent: "-20",
        },
      ],
      saleList: [
        {
          name: "销量",
          type: 1,
        },
        {
          name: "销售额",
          type: 2,
        },
      ],
      saleType: 1,
      topRank: [
        {
          name: "",
          rankList: [
            {
              name: "电动玩具",
              percent: "15.5",
            },
            {
              name: "益智玩具",
              percent: "14.7",
            },
            {
              name: "绘画玩具",
              percent: "12.1",
            },
            {
              name: "积木拼装",
              percent: "9.2",
            },
            {
              name: "橡皮泥",
              percent: "7.3",
            },
          ],
        },
      ],
    };
  },
  methods: {
    changeSaleType(type) {
      this.saleType = type;
    },
  },
};
</script>
<style lang="less" scoped>
@import url("@/assets/css/rankTitle.less");
.ip-flow-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 20px;
  border: 1px solid #e7e8ed;
  .flow-analysis {
    padding-left: 20px;
    margin-bottom: 20px;
  }
}
</style>