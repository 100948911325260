<template>
  <div class="header-box">
    <div class="left-content">
      <img src="@/assets/images/home/menu.png"
        @click="showMenuFun">
    </div>
    <div class="right-content">
      <div class="login-register" v-if="!isLogin"
        @click="showLogin = !showLogin">登录/注册</div>
      <div class="user-box" v-else>
        <div class="avatar-box" @click="goUser">
          <img v-if="userInfo && userInfo.avatar"
            :src="userInfo.avatar">
          <img v-else
            src="https://files.sssrise.com/files/uploads/static/default_avatar.png">
        </div>
        <div class="user-info">
          <div class="info-left">
            <div class="name cursor" @click="goUser">
              {{userInfo && userInfo.nickname || ""}}
            </div>
            <div class="vip">
              <div class="vip-level">VIP
                {{userInfo && userInfo.vip_level || 0}}
              </div>
            </div>
          </div>
          <div class="info-right">
            <div class="point-item" @click="goPath(1)">
              <div class="desc">我的收入</div>
              <div class="count">
                <CountTo :startVal='0'
                  :endVal='(userInfo && userInfo.income) ? userInfo.income / 100 : 0 ' />
              </div>
            </div>
            <div class="point-item" @click="goPath(2)">
              <div class="desc">我的积分</div>
              <div class="count">
                <CountTo :startVal='0'
                  :endVal='(userInfo && userInfo.coin) ? userInfo.coin : 0' />

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <MenuCom v-if="showMenu" :showMenu="showMenu"
      @closeMenu="closeMenu" @showHelperFun="showHelperFun"></MenuCom>
    <LoginCom v-if="showLogin" @closeLogin="closeLogin">
    </LoginCom>
    <HelperCom v-if="showHelperCom" :showHelperCom="showHelperCom" @closeHelper="closeHelper"></HelperCom>
  </div>

</template>
<script>
import { NavBar, Popover, Toast } from "vant";
import MenuCom from "@/components/menu/index.vue";
import LoginCom from "@/components/login/index.vue";
import HelperCom from '@/components/helper/index.vue';
import { getToken, removeToken } from "@/utils/auth";
import CountTo from "vue-count-to";
import { EventBus } from '@/utils/eventBus.js';
export default {
  components: {
    NavBar,
    MenuCom,
    LoginCom,
    vanPopover: Popover,
    CountTo,
    HelperCom
  },
  data() {
    return {
      showMenu: false,
      showLogin: false,
      isLogin: getToken() ? true : false,
      userInfo: {
        id: "",
        nickname: "",
        mobile: "",
        avatar: "",
        gender: "",
        desc: "",
        real_name: "",
        character: "",
        vip_level: 0,
        vip_expires_in: "",
        coin: 0,
        income: 0,
        balance: 0,
        freeze: 0,
        token: "",
      },
      showPopover: false,
      actions: [{ text: "退出登录", type: 1 }],
      showHelperCom: false
    };
  },
  created() {
    this.$bus.$off("openLogin");
    this.$bus.$on("openLogin",() => {
      this.showLogin = true;
    })

    EventBus.$off("openLogin");
    EventBus.$on("openLogin",() => {
      this.showLogin = true;
    })

    
    this.$bus.$off("refreshHeaderUser");
    this.$bus.$on("refreshHeaderUser",() => {
      this.getUserInfo();
    })

    if (getToken()) {
      this.getUserInfo();
    }
  },
  methods: {
    showHelperFun() {
      this.showHelperCom = true;
    },
    closeHelper() {
      console.log("closeHelper")
      this.showHelperCom = false;
    },
    onSelect(action) {
      if (action.type == 1) {
        removeToken();
        this.isLogin = getToken() ? true : false;
        Toast.success("退出登录成功");
      }
    },
    getUserInfo() {
      this.$store
        .dispatch("GetInfo", "")
        .then((res) => {
          this.userInfo = res;
        })
        .catch((err) => {
          Toast.fail((err && err.msg) || "服务器出错，请稍后重试");
        });
    },
    showMenuFun() {
      this.showMenu = true;
    },
    closeMenu() {
      this.showMenu = false;
    },
    closeLogin() {
      this.showLogin = false;
      this.isLogin = getToken() ? true : false;
      if (this.isLogin) {
        this.getUserInfo();
        this.$bus.$emit("initData", true);
      }
    },
    goIndex() {
      this.$router.push({
        path: "Index",
      });
    },
    goPath(type) {
      this.$router.push({
          path: "Bill?tabIdx=" + type
      })
    },
    goUser() {
      this.$router.push({
        path: "User"
      })
    }
  },
};
</script>
<style lang="less" scoped>
.header-box {
  height: 110px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  padding: 0 38px;
  background: #fff;
  .left-content {
    img {
      width: 48px;
      height: 48px;
    }
  }
  .right-content {
    display: flex;
    flex: 1;
    padding-left: 38px;
    .login-register {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-size: 27px;
      color: #3672fd;
    }
    .user-box {
      display: flex;
      align-items: center;
      flex: 1;
      .avatar-box {
        margin-right: 20px;
        display: flex;
        align-items: center;
        img {
          width: 92px;
          height: 92px;
          border-radius: 100%;
        }
      }
      .user-info {
        display: flex;
        flex: 1;
      }
      .login-btn {
        width: 100px;
        height: 36px;
        background: #3672fd;
        box-shadow: 0px 2px 4px 0px #d9e5ff;
        border-radius: 18px;
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .info-left {
        flex: 1;
        margin-right: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .name {
          font-weight: bold;
          font-size: 27px;
          color: #1a232f;
          line-height: 38px;
          text-align: right;
          font-style: normal;
          text-align: left;
          display: flex;
          align-items: center;
          img {
            width: 40px;
            height: 40px;

            &:first-child {
              margin-left: 6px;
            }
          }
        }
        .vip {
          display: flex;
          .vip-level {
            min-width: 48px;
            padding: 0 6px;
            height: 20px;
            background: linear-gradient(135deg, #626a83 0%, #1a232f 100%);
            border-radius: 0px 10px 10px 10px;
            font-weight: normal;
            font-size: 14px;
            color: #eccd8e;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      .info-right {
        display: flex;
        flex-direction: column;
        .point-item {
          display: flex;
          align-items: center;
          .desc {
            font-weight: 400;
            font-size: 23px;
            color: #979db1;
            line-height: 32px;
          }
          .count {
            font-weight: bold;
            font-size: 31px;
            color: #3672fd;
            line-height: 36px;
            margin-left: 20px;
          }
        }
      }
    }
  }
}
</style>