<template>
  <div class="ip-index-box px-38">
    <CommonHeader :title="title" :showOrder="showOrder" :showFav="showFav" @goFavorite="goFavorite"></CommonHeader>
    <div class="market-pay-content">
      <div class="common-title">请介绍您的玩具产品</div>
      <div class="info-box">
        <van-field v-model="category" is-link readonly label="品类" placeholder="请选择品类" @click="show = true" />
        <div class="ip-box">
          <van-field v-model="ipVal" label="IP" placeholder="请输入IP名称" :clearable="true" :autocomplete="false"
            @input="triggerInput" />
          <div class="ip-select" v-if="showSelect">
            <div class="ip-list">
              <div class="ip-item" :class="[ipId == item.value ? 'active' : '']" v-for="(item,idx) in dropdownOption"
                :key="idx" @click="selectIp(item)">{{item.name}}</div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="idea-bottom">
      <div class="submit-idea" @click="goReport">开始分析</div>
    </div>
    <van-popup v-model="show" round position="bottom">
      <van-cascader v-model="cascaderValue" title="请选择品类" :options="categoryOptions"
        :field-names="{value:'id', text: 'name'}" @close="show = false" @finish="onFinish" />
    </van-popup>

  </div>
</template>
<script>
  import CommonHeader from "@/components/header/commonHeader.vue";
  import { Icon, Cascader, Popup, Field, DropdownMenu, DropdownItem, Toast } from "vant";
  import {
    getStatisticsCategoryList,
    getStatisticsProduct,
    getStatisticsSearchIp,
  } from "@/api/statistics";
  export default {
    components: {
      CommonHeader,
      vanIcon: Icon,
      VanCascader: Cascader,
      VanPopup: Popup,
      VanField: Field,
      VanDropdownMenu: DropdownMenu,
      VanDropdownItem: DropdownItem
    },
    data() {
      return {
        title: "产品策略",
        showOrder: false,
        showFav: false,
        category: "",
        categoryId: "",
        categoryOptions: [],
        ipVal: "",
        ipId: "",
        restaurants: [],
        autoName: "name",
        show: false,
        cascaderValue: "",
        dropdownOption: [],
        showSelect: false
      };
    },
    created() {
      this.getStatisticsCategoryListReq();
    },
    methods: {
      getStatisticsCategoryListReq() {
        getStatisticsCategoryList()
          .then((res) => {
            this.categoryOptions = (res && res.data && res.data.list) || [];
          })
          .catch(() => {
            this.categoryOptions = [];
          });
      },
      goFavorite() {
        this.$router.push({
          path: "/Favorite"
        })
      },
      goReport() {
        if (!this.categoryId) {
          Toast.fail("请选择品类");
          return;
        }
        console.log("ipVal", this.ipVal);
        if (!this.ipVal) {
          Toast.fail("请输入ip");
          return;
        }

        if (this.ipVal) {
          let isExist = this.dropdownOption.filter((res) => {
            return res.name == this.ipVal;
          })
          console.log("isExist",isExist)
          if (isExist && isExist.length > 0) {
            this.ipId = isExist[0].id;
          } else {
            Toast.fail("请选择匹配的ip");
            return;
          }
        }
        this.$router.push({
          path: "/ProductReport?categoryId=" + this.categoryId + "&ipId=" + this.ipId + "&ipVal=" + encodeURIComponent(this.ipVal) + "&category=" + encodeURIComponent(this.category)
        })
      },
      onFinish({ selectedOptions }) {
        console.log("selectedOptions", selectedOptions)
        this.show = false;
        this.category = selectedOptions.map((option) => option.name).join("/");
        this.categoryId = selectedOptions.map((option) => option.id).join("/");
        console.log("category", this.category, "categoryId", this.categoryId);
      },
      triggerInput() {
        console.log("this.ipVal", this.ipVal)
        getStatisticsSearchIp({
          q: this.ipVal,
        })
          .then((res) => {
            if (res && res.error == 0) {
              let restaurants = (res && res.data && res.data.list) || [];
              this.dropdownOption = restaurants;
              console.log("this.dropdownOption", this.dropdownOption)
              if (this.dropdownOption && this.dropdownOption.length > 0) {
                this.showSelect = true;
              }
            }
          })
          .catch(() => {
            this.dropdownOption = [];
          });

        if (this.dropdownOption && this.dropdownOption.length > 0) {
          this.showSelect = true;
        }
      },
      selectIp(val) {
        this.ipVal = val.name;
        this.ipId = val.id;
        this.showSelect = false;
      }
    },
  };
</script>
<style lang="less" scoped>
  .ip-index-box {
    padding-top: 110px;

    .market-pay-content {
      margin-top: 30px;

      .info-box {
        width: 100%;
        background: #f8f8fa;
        border-radius: 20px;
        padding: 38px 30px 38px 40px;
        margin-top: 32px;

        .ip-box {
          position: relative;
        }

        .ip-select {
          position: absolute;
          left: 0;
          right: 0;
          background: #f8f8fa;

          .ip-list {
            width: 100%;
            display: flex;
            flex-direction: column;
            max-height: 500px;
            overflow-y: auto;

            .ip-item {
              width: 100%;
              padding: 20px;
              font-weight: 400;
              font-size: 27px;
              color: #1A232F;
              text-align: center;
              font-style: normal;
              position: relative;

              &.active {
                color: #3672FD;
                font-weight: bold;
              }

              &::after {
                position: absolute;
                box-sizing: border-box;
                content: ' ';
                pointer-events: none;
                right: 20px;
                bottom: 0;
                left: 20px;
                border-bottom: 1px solid #ebedf0;
                -webkit-transform: scaleY(0.5);
                transform: scaleY(0.5);
              }
            }
          }

        }
      }
    }

    .idea-bottom {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      height: 168px;
      background: rgba(243, 243, 248, 0.8);
      box-shadow: inset 0 1px 0 0 #E7E8ED;
      backdrop-filter: blur(10px);
      display: flex;
      align-items: center;
      justify-content: center;

      .submit-idea {
        width: 611px;
        height: 92px;
        background: #3672FD;
        box-shadow: 0 4px 8px 0 #D9E5FF;
        border-radius: 46px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 32px;
        color: #fff;
      }
    }
  }
</style>