<template>
    <div class="user-box flex flex-column px-38">
        <CommonHeader :title="title"></CommonHeader>
        <div class="flex user-info mt-50">
            <div class="avatar" @click="goInfo">
                <img v-if="userInfo && userInfo.avatar" :src="userInfo.avatar">
                <img v-else src="https://files.sssrise.com/files/uploads/static/default_avatar.png">
            </div>
            <div class="flex-1 flex align-center pl-20">
                <div v-if="isLogin" class="detail" @click="goInfo">
                    <div class="name">{{userInfo && userInfo.nickname || ""}}
                    </div>
                    <div class="vip-box" @click.stop="goVip">
                        <div class="vip">VIP {{userInfo && userInfo.vip_level || 0}}</div>
                    </div>
                </div>
                <div v-else class=" font-27 flex align-center justify-center">
                    <div class=" font-27 login-btn text-white font-weight-bolder justify-center flex align-center flex-1" @click="goLogin">登录/注册</div>
                </div>

            </div>
        </div>
        <div class="company-name flex justify-between align-center mt-50" @click="goInfo" v-if="userInfo && userInfo.company_name">
            <div class="default-title one-text">{{userInfo && userInfo.company_name || ""}}</div>
            <div class="flex align-center w-200 content-end"><span class="font-27">修改</span><van-icon name="arrow"
                    color="#CCCFDA" size="24" /></div>
        </div>
        <div class="company-desc flex justify-between align-center mt-14" @click="goInfo" v-if="userInfo && userInfo.company_desc">
            <div class="normal-title text-left flex-1 three-text" style="word-break: break-all;">{{userInfo && userInfo.company_desc || ""}}
            </div>
            <div class="flex align-center w-200 content-end"><span class="font-27">编辑</span><van-icon name="arrow"
                    color="#CCCFDA" size="24" /></div>
        </div>
        <div class="default-title text-left mt-16">我的账户</div>
        <div class="flex mt-28 justify-between">
            <div class="point-box" @click="goBill(0)">
                <div class="font-23 text-normal line-h-32 text-left">我的积分</div>
                <div class="font-46 text-default font-weight-bolder line-h-53 text-left">
                    {{userInfo && isLogin ? userInfo.coin : "--"}}
                </div>
            </div>
            <div class="point-box income-box position-relative" @click="goBill(1)">
                <div class="position-absolute auth-box flex align-center flex-column">
                    <div class="flex align-center">
                        <img class="icon-rz" src="@/assets/images/rz_check.png">
                        <span class="font-20 line-h-28 text-primary ml-08">{{userInfo && isLogin ? '已认证' :
                            '去认证'}}</span>
                    </div>
                    <!-- <span class="font-20 line-h-28 text-primary ml-08 mt-20 pl-10 pr-10" @click.stop="goDetail(5)">提现</span> -->
                </div>
                <div class="font-23 text-normal line-h-32 text-left">我的收入</div>
                <div class="font-46 text-default font-weight-bolder line-h-53 text-left">
                    {{isLogin ? (userInfo.income ? parseFloat(userInfo.income / 100).toFixed(0) : 0 ): "--"}}
                </div>
            </div>
        </div>
        <!-- <div class="mt-38 flex sign-in position-relative justify-between align-center" @click="sign">
            <img class="position-absolute left-0 right-0 bottom-0 top-0 rounded-20"
                src="https://files.sssrise.com/files/uploads/static/bg.png">
            <div class="position-relative pl-30 font-24 text-white">签到领积分</div>
            <div class="sign-in-btn font-27 text-default font-weight-bolder mr-20 flex align-center justify-center">
                {{userInfo && userInfo.daily_checked ? "已签到" : "签到"}}
            </div>

        </div> -->
        <div class="menu-list flex flex-column mt-38">
            <div class="flex menu-item" @click="goDetail(1)">
                <img src="https://files.sssrise.com/files/uploads/static/icon_order.png">
                <span class="font-27 text-default font-weight-bolder pl-40 flex-1 text-left">我的订单</span>
                <van-icon name="arrow" color="#CCCFDA" size="24" />
            </div>
            <div class="flex menu-item" @click="goDetail(2)">
                <img src="https://files.sssrise.com/files/uploads/static/jifen.png">
                <span class="font-27 text-default font-weight-bolder pl-40 flex-1 text-left">积分加油站</span>
                <van-icon name="arrow" color="#CCCFDA" size="24" />
            </div>
            <div class="flex menu-item" @click="goDetail(3)">
                <img src="https://files.sssrise.com/files/uploads/static/nicheng@2x.png">
                <span class="font-27 text-default font-weight-bolder pl-40 flex-1 text-left">会员中心</span>
                <van-icon name="arrow" color="#CCCFDA" size="24" />
            </div>
            <div class="flex menu-item" @click="goDetail(4)">
                <img src="https://files.sssrise.com/files/uploads/static/neirong.png">
                <span class="font-27 text-default font-weight-bolder pl-40 flex-1 text-left">我的内容</span>
                <van-icon name="arrow" color="#CCCFDA" size="24" />
            </div>
        </div>

        <div class="loginout-btn" v-if="isLogin" @click="loginout">退出登录</div>
    </div>
</template>

<script>
    import util from "@/utils/util";
    import CommonHeader from '@/components/header/commonHeader.vue';
    import {
        userDialyCheck,
        getDefaultConfig
    } from "@/api/user";
    import { Icon, Notify,Toast } from "vant";
    import { removeToken } from "@/utils/auth";
    export default {
        components: {
            CommonHeader,
            VanIcon: Icon
        },
        data() {
            return {
                title: "个人中心",
                userInfo: {
                    "id": "",
                    "nickname": "",
                    "mobile": "",
                    "avatar": "",
                    "gender": "",
                    "desc": "",
                    "real_name": "",
                    "character": "",
                    "vip_level": 0,
                    "vip_expires_in": "",
                    "coin": 0,
                    "income": 0,
                    "balance": 0,
                    "freeze": 0,
                    "token": ""
                },
                userId: null,
                isLogin: false,
                defaultConfig: false
            };
        },
        mounted() {
            util.onLogin().then((res) => {
                if (!res) {
                    this.$bus.$emit("openLogin");
                } else {
                    this.getUserInfo();
                    this.getDefaultConfigReq();
                }
            });

            this.$bus.$off("initData");
            this.$bus.$on("initData", () => {
                this.getUserInfo();
                this.getDefaultConfigReq();
            });
        },
        methods: {
            getDefaultConfigReq() {
                getDefaultConfig().then((res) => {
                    this.defaultConfig = res.data || null;
                    this.isLogin = this.defaultConfig.is_login || false;
                }).catch(() => {
                    this.isLogin = false;
                })
            },
            goInfo() {
                this.$router.push({
                    path: "Info"
                })
            },
            goVip() {
                this.$router.push({
                    path: "Vip"
                })
            },
            login() {
                this.userInfo.isLogin = true;

            },
            goLogin() {
                this.$bus.$emit("showCommonLogin");
            },
            loginout() {
                removeToken();
                this.userInfo = {
                    "id": "",
                    "nickname": "",
                    "mobile": "",
                    "avatar": "",
                    "gender": "",
                    "desc": "",
                    "real_name": "",
                    "character": "",
                    "vip_level": 0,
                    "vip_expires_in": "",
                    "coin": 0,
                    "income": 0,
                    "balance": 0,
                    "freeze": 0,
                    "token": ""
                }
                this.login = false;
                // this.$bus.$emit("loginout");
                // this.$router.push("index");
            },
            goBill(type) {
                this.$router.push({
                    path: "Bill?tabIdx=" + type
                })
            },
            goDetail(type) {
                switch (type) {
                    case 1:
                    this.$router.push({
                            path: "Order"
                        })
                        break;
                    case 2:
                        this.$router.push({
                            path: "Station"
                        })
                        break;
                    case 3:
                        this.$router.push({
                            path: "Vip"
                        })
                        break;
                    case 4:
                        this.$router.push({
                            path: "Content"
                        })
                        break;
                    case 5:
                        this.$router.push({
                            path: "Withdrawal"
                        })
                        break;
                }
            },
            getUserInfo() {
                this.$store
                    .dispatch("GetInfo", "")
                    .then((res) => {
                        this.userInfo = res;
                        console.log("userInfo", this.userInfo)
                    })
                    .catch((err) => {
                        Toast.fail((err && err.msg) || "服务器出错，请稍后重试");
                    });
            },
            sign() {
                if (this.userInfo && this.userInfo.daily_checked) {
                    return;
                }
                userDialyCheck().then((res) => {
                    console.log("userDialyCheck", res);
                    if (res && res.error == 0) {
                        Notify({
                            message: "签到成功,获得" + res.coin + "积分",
                            type: "success",
                            duration: 3000
                        });
                        localStorage.setItem("isReload", 1);
                        this.getUserInfo();
                    } else {
                        Notify({
                            message: err && err.msg || "签到失败，请稍后重试",
                            type: "danger",
                            duration: 3000
                        });
                    }


                }).catch((err) => {
                    Notify({
                        message: err && err.msg || "签到失败，请稍后重试",
                        type: "danger",
                        duration: 3000
                    });
                })
            },
        }
    };
</script>

<style lang="less" scoped>
    .user-box {
        padding-top: 100px;
        padding-bottom: 160px;
    }

    .user-info {
        .avatar {
            img {
                width: 136px;
                height: 136px;
                border-radius: 100%;
            }
        }

        .login-btn {
            width: 191px;
            height: 69px;
            background: #3672FD;
            box-shadow: 0 4px 8px 0 #D9E5FF;
            border-radius: 34px;
        }

    }

    .detail {
        display: flex;
        flex-direction: column;
        padding-left: 20px;
        flex: 1;
        justify-content: center;

        .name {
            font-weight: bolder;
            font-size: 27px;
            color: #1A232F;
            line-height: 38px;
            display: flex;


        }

        img {
            width: 36px;
            height: 36px;
            border-radius: 100%;
            margin-right: 8px;
        }


    }

    .point-box {
        width: 324px;
        height: 134px;
        background: #E0DEFF;
        border-radius: 19px;
        padding: 24px 0 0 28px;

        &.income-box {
            background: #D9E5FF;
        }

        .auth-box {
            top: 28px;
            right: 28px;
        }

        .icon-rz {
            width: 36px;
            height: 36px;
        }
    }

    .sign-in {
        width: 674px;
        height: 107px;

        img {
            width: 100%;
            height: 100%;
        }

        .sign-in-btn {
            width: 168px;
            height: 69px;
            background: #ECCD8E;
            border-radius: 34px;
            backdrop-filter: blur(2px);
        }
    }

    .menu-list {
        width: 674px;
        background: #F8F8FA;
        border-radius: 19px;
        padding: 50px 40px;

        .menu-item {
            line-height: 70px;
            margin-bottom: 20px;
            align-items: center;

            &:last-child {
                margin-bottom: 0;
            }

            img {
                width: 46px;
                height: 46px;
            }
        }
    }

    .loginout-btn {
        width: 229px;
        height: 69px;
        background: #FFFFFF;
        box-shadow: 0 4px 8px 0 #E7E8ED;
        border-radius: 34px;
        border: 1px solid #E7E8ED;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 60px;
        z-index: 2;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 27px;
        color: #626A83;
    }
</style>