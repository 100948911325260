<template>
    <div class="sales-data-box">
        <div class="rank-title"><span>IP营销分析</span></div>
        <SaleTab class="mt-16" :tabList="saleList" :currentType="saleType" @changeTab="changeSaleType"></SaleTab>
        <RankCom class="mt-28" :hideTitle="true" title="TOP5 品类" bgColor="#9893EB" :currentList="ipMarket" :showIdx="saleType" ></RankCom>
    </div>
</template>
<script>
import SaleTab from "./saleTab.vue";
import RankCom from "./rank.vue";
export default {
    props: ["ipMarket"],
    components: {
        SaleTab,
        RankCom
    },
    data() {
        return {
            saleList: [{
                name: "平台热度",
                type: 1
            },{
                name: "地狱热度",
                type: 2
            }],
            saleType: 1,
        }
    },
    methods: {
        changeSaleType(type) {
            this.saleType = type;
        }
    }
}
</script>
<style lang="less" scoped>
@import url("@/assets/css/rankTitle.less");
.sales-data-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border-radius: 20px;
    border: 1px solid #E7E8ED;
}
</style>