<template>
    <div class="ip-detail-info">
        <div class="info-title">{{currentName}}</div>
        <CategoryCom :ipInfo="ipInfo" :allScore="allScore"></CategoryCom>
        <!-- <SaleTab class="mt-16" :tabList="saleList" :currentType="saleType" @changeTab="changeSaleType"></SaleTab> -->
        <EvaluateCom class="mt-20" :showType="saleType" :ipInfo="ipInfo" ></EvaluateCom>
    </div>
</template>
<script>
import SaleTab from "./saleTab.vue";
import EvaluateCom from "./evaluate.vue";
import CategoryCom from "./category.vue";
export default {
    props: ["currentName","ipInfo","allScore"],
    components: {
        SaleTab,
        CategoryCom,
        EvaluateCom
    },
    data() {
        return {
            saleList: [{
                name: "影视",
                type: 1
            },{
                name: "游戏",
                type: 2
            }],
            saleType: 1,
            priceRank: [{
                name: "价格分布排行",
                subTitle: "（单位:元）",
                rankList: [{
                    name: "100-200",
                    percent: "15.5",
                },{
                    name: "50-100",
                    percent: "14.7",
                },{
                    name: "10-20",
                    percent: "12.1",
                },{
                    name: "200以上",
                    percent: "9.2",
                },{
                    name: "10以下",
                    percent: "7.3",
                }]
            }],
            topRank: [{
                name: "",
                rankList: [{
                    name: "电动玩具",
                    percent: "15.5",
                },{
                    name: "益智玩具",
                    percent: "14.7",
                },{
                    name: "绘画玩具",
                    percent: "12.1",
                },{
                    name: "积木拼装",
                    percent: "9.2",
                },{
                    name: "橡皮泥",
                    percent: "7.3",
                }]
            }],
        }
    },
    methods: {
        changeSaleType(type) {
            this.saleType = type;
        }
    }
}
</script>
<style lang="less" scoped>
@import url("@/assets/css/rankTitle.less");
.ip-detail-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border-radius: 20px;
    border: 1px solid #E7E8ED;
    .info-title {
        font-weight: bold;
        font-size: 32px;
        color: #1A232F;
        line-height: 46px;
        text-align: left;
        font-style: normal;
        padding: 28px 38px 0 38px;
    }
}
</style>