<template>
    <div class="market-pay-box px-38">
        <CommonHeader :title="title" :showOrder="showOrder"></CommonHeader>
        <div class="market-pay-content">
            <PayList></PayList>
        </div>
    </div>
</template>
<script>
import CommonHeader from '@/components/header/commonHeader.vue';
import PayList from "@/components/market/payList.vue";
export default {
    components: {
        CommonHeader,
        PayList
    },
    data() {
        return {
            title: "购买列表",
            showOrder: true
        }
    }
}
</script>
<style lang="less" scoped>
.market-pay-box {
    padding-top: 110px;
    .market-pay-content {
        margin-top: 20px;
    }
}
</style>