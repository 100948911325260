<template>
  <div class="content-detail-box">
    <div class="problem-avatar">
      <img
        src="https://files.sssrise.com/files/uploads/static/ai_avatar.png">
    </div>
    <div class="problem-info">
      <div class="problem-desc flex-column">
        <div class="strong"
          v-if="problemItem && problemItem.msg && problemItem.msg.text">
          <div style="white-space:pre-wrap" class="text-left" v-html="problemItem.msg.text"></div>
        </div>
        <div style="padding: 0 20px;"
          v-if="problemItem && problemItem.msg && problemItem.msg.image">
          <img style="max-width:460px;"
            :src="problemItem.msg.image"
            @click="previewImage(problemItem.msg.image)"
            show-menu-by-longpress="true">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast,ImagePreview } from "vant";
export default {
  name: "probleOne",
  props: ["problemItem", "problemIdx"],
  data() {
    return {};
  },
  methods: {
    previewImage(image) {
		ImagePreview([image]);
    },
    chooseProblem(item, idx) {
      console.log("chooseProblem", item);
      this.$emit("changeIdx", {
        problemIdx: this.problemIdx,
        idx: idx,
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import url(@/assets/css/contentDetail.less);
</style>