<template>
	<div class="menu-box">
		<div class="menu-layer" @click="closeMenu"></div>
		<div class="menu-content flex flex-column">
			<div class="flex" style="margin-top: 50px;">
				<img class="default-img" src="@/assets/images/home/menu.png" @click="closeMenu">
			</div>
			<div class="create" @click="goCreate"><van-icon class="pr-20" name="add-o" color="#626A83"
					size="20"></van-icon>新建聊天</div>
			<div class="text-default font-28 line-h-38 font-weight-bolder mt-30 text-left">历史对话</div>
			<div class="history-list flex flex-1 flex-column mt-20" @scroll="handleScroll">
				<!-- <van-pull-refresh style="height:100%;" v-model="refreshing" @refresh="onRefresh">
					<van-list v-model="loading" :finished="finished" finished-text="没有更多了"
						@load="onLoad">
						<div class="history-item mb-20 " :class="[currentIdx == idx ? 'text-primary' : 'text-normal']"
							v-for="(item,idx) in histroyList" :key="idx">
							<van-icon class="chat pl-20" name="chat-o"
								:color="currentIdx == idx ? 'text-primary' : 'text-normal'" size="20"
								@click="getChatMessage(item,idx)"></van-icon>
							<span class="flex-1 font-23 text-ellipsis pl-20 text-left"
								@click="getChatMessage(item,idx)">{{item.title || ""}}</span>
							<van-icon class="trash" name="delete-o"
								:color="currentIdx == idx ? 'text-primary' : 'text-normal'" size="20"
								@click.stop="delHistory(item,idx)"></van-icon>
						</div>
					</van-list>
				</van-pull-refresh> -->
				<div class="history-item mb-20 align-center" :class="[currentIdx == idx ? 'text-primary' : 'text-normal']"
					v-for="(item,idx) in histroyList" :key="idx">
					<van-icon class="chat pl-20" name="chat-o"
						:color="currentIdx == idx ? 'text-primary' : 'text-normal'" size="20"
						@click="getChatMessage(item,idx)"></van-icon>
					<span class="flex-1 font-23 text-ellipsis pl-20 text-left"
						@click="getChatMessage(item,idx)">{{item.title || ""}}</span>
					<div class="flex align-center">
						<van-icon class="trash" name="delete-o" :color="currentIdx == idx ? 'text-primary' : 'text-normal'"
						size="20" @click.stop="delHistory(item,idx)"></van-icon>
					</div>
					
				</div>

				<!-- <van-empty v-if="histroyList.length == 0" description="暂无数据" /> -->
			</div>
			<div class="menu-bottom flex flex-column">
				<div class="bottom-item flex text-normal mb-50 font-28 align-center" @click="goPath(1)"><van-icon
						name="contact-o" color="#626A83" size="24"></van-icon><span class="ml-20">个人中心</span></div>
				<div class="bottom-item flex text-normal mb-50 font-28 align-center" @click="goPath(2)"><img
						class="default-img" src="https://files.sssrise.com/files/uploads/static/jifen.png"><span
						class="ml-20">积分加油站</span></div>
				<div class="bottom-item flex text-normal mb-50 font-28 align-center" @click="showHelperFun"><img
						class="default-img" src="@/assets/images/icon_help.png"><span class="ml-20">帮助</span></div>
			</div>
		</div>
	</div>
</template>

<script>
	import { getChatList, deleteChat } from "@/api/chat";
	import { Empty, List, PullRefresh, Toast, Icon,Dialog } from "vant";
	export default {
		name: "menuCom",
		components: {
			VanIcon: Icon,
			VanEmpty: Empty,
			VanPullRefresh: PullRefresh,
			VanList: List
		},
		data() {
			return {
				histroyList: [],
				currentIdx: -1,
				show: false,
				content: "是否删除该条记录",
				title: "提示",
				params: {
					offset: 0,
					pageSize: 0,
					limit: 20
				},
				delItem: null,
				scrollTop: 0,
				old: {
					scrollTop: 0
				},
				loading: false,
				finished: false,
				refreshing: false,
				isFirst: true,
				isLoading: false
			};
		},
		created() {
			this.params.offset = 0;
			this.params.pageSize = 0;
			this.getChatListReq();
		},
		mounted() {
			// 确保滚动事件绑定
			this.$el.addEventListener('scroll', this.handleScroll);
		},
		methods: {
			handleScroll() {
				if (!this.isLoading && this.canLoadMore()) {
					this.isLoading = true;

					this.params.pageSize += 1;
					this.params.offset =
						this.params.pageSize * this.params.limit;
					this.getChatListReq();
				}
			},
			canLoadMore() {
				const { scrollTop, scrollHeight, clientHeight } = event.target;
				// console.log("scrollTop",scrollTop,"scrollHeight",scrollHeight,"clientHeight",clientHeight)
				const isBottom = scrollTop + clientHeight >= scrollHeight - 50;
				return isBottom && this.statusLoadmore == "loadmore";
			},
			onLoad() {
				console.log("onload");
				if (this.refreshing) {
					this.currentList = [];
					this.refreshing = false;
				}

				this.finished = true;
				if (!this.isFirst) {
					this.params.pageSize += 1;
					this.params.offset = this.params.pageSize * this.params.limit;
				} else {
					this.isFirst = false;
				}

				this.getChatListReq();
			},
			onRefresh() {
				console.log("ref");
				// 清空列表数据
				this.finished = false;

				// 重新加载数据
				// 将 loading 设置为 true，表示处于加载状态
				this.loading = true;
				this.params.pageSize = 0;
				this.params.offset = 0;
				this.getChatListReq();
			},
			getChatListReq() {
				getChatList({
					offset: this.params.offset, 
					limit: this.params.limit
				}).then((res) => {
					console.log("res", res)
					this.loading = false;
					this.refreshing = false;
					this.isLoading = false;
					if (res && res.data && res.data.list) {
						res.data.list.forEach(billItem => {
							const isExist = this.histroyList.find((item) => item.id === billItem.id);
							if (isExist) {
								return;
							} else {
								this.histroyList.push(billItem);
							}

						});
					}

					if (res && res.data && res.data.has_more) {
						this.statusLoadmore = "loadmore";
						this.finished = false;
					} else {
						this.statusLoadmore = "nomore";
						this.finished = true;
					}

				}).catch((err) => {
					this.loading = false;
					this.refreshing = false;
					this.finished = true;
					this.isLoading = false;
					Toast.fail(err && err.msg || "服务器异常，请稍后重试");
				})
			},
			getChatMessage(item, idx) {
				this.currentIdx = idx;
				let routeName = this.$route.name;
				console.log("routeName",routeName)
				this.closeMenu();
				if(routeName == "Ai") {
					
					this.$bus.$emit("getChatMessage", item);
				} else {
					if(item && item.id && item.model) {
						this.$router.push({
							path: "/Ai?currentChatMessageItem=" + JSON.stringify(item)
						})
					} else {
						this.$router.push({
							path: "/Ai"
						})
					}
				}
				
				
			},
			chooseHistory(item, idx) {
				this.currentIdx = idx;
			},
			delHistory(item, idx) {
				// this.histroyList.splice(idx,1);

				this.delItem = item;
				this.delIdx = idx;
				// this.show = true;
				Dialog.confirm({
					title: '提示',
					message: '是否删除该条记录',
				})
				.then(() => {
					this.confirm();
				})
				.catch(() => {
				});
			},
			showHelperFun() {
				this.$emit("showHelperFun")
			},
			confirm() {
				// this.histroyList.splice(this.delIdx,1);
				deleteChat({ id: this.delItem.id }).then((res) => {
					if(res && res.error == 0) {
						Toast.success("删除消息成功");
						this.histroyList.splice(this.delIdx, 1);
						this.currentIdx = -1;
						this.getChatListReq();
					} else {
						Toast.fail(res && res.msg || "服务器异常，请稍后重试");
					}
					
				}).catch((err) => {
					Toast.fail(err && err.msg || "服务器异常，请稍后重试");
				})
			},
			goRecord() {
				// uni.navigateTo({
				// 	url: "/pages/record/record"
				// })
			},
			goSetting() {
				// uni.navigateTo({
				// 	url: "/pages/setting/setting"
				// })
			},
			closeMenu() {
				this.$emit("closeMenu")
			},
			goShare() {
				// uni.navigateTo({
				// 	url: "/pages/reward/reward"
				// })
			},
			goCreate() {
				this.$emit("goCreate")
			},
			goPath(type) {
				switch (type) {
					case 1:
						this.$router.push({
							path: "/User"
						})
						break;
					case 2:
						this.$router.push({
							path: "/Station"
						})
						break;
				}
			},
			upper(e) {
				// console.log("upper",e)
			},
			lower(e) {
				console.log("lower", e)
				this.params.pageSize += 1;
				this.params.offset = this.params.pageSize * this.params.limit;
				this.getChatListReq();
			},
			scroll(e) {
				// console.log("scroll",e)
				this.old.scrollTop = e.detail.scrollTop
			},
		}
	}
</script>

<style lang="less" scoped>
	.menu-box {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		display: flex;
		flex-direction: column;
		z-index: 10001;

		.menu-layer {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			top: 0;
			background: rgba(26, 35, 47, 0.7);
		}

		.menu-content {
			background: #fff;
			width: 580px;
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			padding: 0 45px;

			.list {
				margin-top: 190px;
			}

			.history-list {
				overflow-y: auto;

				.history-item {
					width: 477px;

					background: #F8F8FA;
					border-radius: 19px;
					display: flex;
					justify-content: center;
					align-items: center;

					&.active {
						background: #D9E5FF;
						box-shadow: 0px 4px 8px 0px #D9E5FF;
						border: 1px solid #3672FD;
					}

					span {
						padding: 20px 10px;
					}

					.edit-pen {
						padding: 20px 10px 20px 20px;
						height: 100%;
					}

					.trash {
						padding: 0 20px 0 10px;
						height: 100%;
					}
				}
			}

			.invite-box {
				width: 477px;
				height: 172px;
				background: #3672FD;
				border-radius: 20px;

				image {
					width: 100%;
					height: 100%;
				}

				.rmb-circle {
					padding-left: 66px;
				}

				.invite-info {
					padding-left: 50px;
				}
			}
		}

		.create {
			width: 477px;
			height: 69px;
			background: #FFFFFF;
			box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
			border-radius: 19px;
			border: 1px solid #979DB1;
			margin-top: 78px;
			font-size: 27px;
			color: #626A83;
			display: flex;
			justify-content: center;
			align-items: center;
		}


	}
</style>