<template>
	<div class="problem-box">
		<div class="problem-avatar">
			<img src="https://files.sssrise.com/files/uploads/static/ai_avatar.png">
		</div>
		<div class="problem-info">
			<div class="problem-desc">
				<div class="dhk-arrow"><img style="width: 40px;height: 32px;" src="https://files.sssrise.com/files/uploads/static/dhk_arrow.png"></div>
				<div class="desc">{{currentTypeItem && currentTypeItem.tip_message}}</div>
				<div v-if="problemItem && problemItem.showGuide && chatItem && chatItem.type == 2" class="guide mt-10 flex align-center font-23 text-default font-weight-bolder line-h-32">
					不知道怎么输入关键词，<text class="text-primary text-underline" @click="goMore">点这里快速上手！</text>
				</div>
			</div>
			<div class="problem-list" v-if="problemItem && problemItem.children && chatItem && (chatItem.type == 0 || chatItem.type == 1)">
				<div class="problem-item" :class="[problemItem && problemItem.chooseIdx == idx && 'active']" v-for="(item,idx) in problemItem.children"
					:key="idx" @click="chooseProblem(item,idx)">{{item}}</div>
			</div>
			<div class="more-opt flex align-center justify-end mt-20" v-if="problemItem && problemItem.children && problemItem.children.length > 0 && chatItem && (chatItem.type == 0 || chatItem.type == 1)">
				<div class="pl-20 pr-20 font-23 line-h-32 text-primary flex align-center" @click="refresh">换一换<img class="refresh" src="https://files.sssrise.com/files/uploads/static/refresh.png"></div>
				<div class="view-border"></div>
				<div class="pl-20 font-23 line-h-32 text-primary flex align-center" @click="goMore">更多<van-icon name="arrow" color="#3672FD" size="18"></van-icon></div>
			</div>
		</div>
		<promptCom v-if="showPrompt" :chatItem="chatItem" :showPrompt="showPrompt" @sumbitTip="sumbitTip" @closePopup="closePopup"></promptCom>
	</div>
</template>

<script>
	import promptCom from "./prompt.vue";
	import { Icon } from "vant";
	export default {
		name: "tipItem",
		props: ["problemItem","problemIdx","chatItem","currentTypeItem"],
		components: {
			promptCom,
			VanIcon: Icon
		},
		data() {
			return {
				showPrompt: false
			};
		},
		created() {
			console.log("problemItem",this.problemItem,"chatItem",this.chatItem)
		},
		methods: {
			chooseProblem(item, idx) {
				console.log("chooseProblem",item)
				this.$emit("changeTipIdx",{
					problemIdx: this.problemIdx,
					idx: idx,
					msg: item
				})
			},
			goMore() {
				this.showPrompt = true;
			},
			refresh() {
				this.$emit("refresh")
			},
			sumbitTip(val) {
				this.$emit("sumbitTipFun",val)
			},
			closePopup() {
				this.showPrompt = false;
			}
		}
	}
</script>

<style lang="less" scoped>
	@import url(@/assets/css/problemDesc.less);
</style>