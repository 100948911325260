<template>
  <div class="flex flex-column pt-70">
    <CommonHeader title="提现"></CommonHeader>
    <div class="pl-76 pr-68 flex flex-column mt-60">
      <div class="font-23 text-default line-h-32 text-left">
        我的收入</div>
      <div class="flex justify-between align-center">
        <div
          class="font-53 text-primary font-weight-bolder line-h-76">
          ¥ {{amount}}</div>
        <div class="font-27 text-primary" @click="goBill">
          收入明细</div>
      </div>
      <div class="font-23 line-h-32 text-default text-left">
        锁定金额</div>
      <div
        class="font-38 line-h-53 text-normal mt-06 text-left">
        ¥
        {{this.userInfo.freeze ? parseFloat(this.userInfo.freeze / 100).toFixed(0) : 0}}
      </div>
    </div>
    <div class="withdrawal-box mt-56">
      <div
        class="flex line-h-32 align-center justify-between px-20 pt-20">
        <div
          class="font-24 text-default font-weight-bolder">姓名
        </div>
      </div>
      <div class="m-0-auto mt-20 flex position-relative">
        <input class="phone-input text-default"
          placeholder="请输入提款者姓名" v-model="moneyName"
          type="text" />
        <div
          class="font-24 text-white position-absolute top-0 bottom-0 right-0 flex align-center icon-clear"
          style="z-index:2;right: 20px;" v-if="moneyName"
          @click="clearName">
        </div>

      </div>
      <div
        class="flex line-h-32 mt-20 align-center justify-between">
        <div
          class="font-23 text-default font-weight-bolder">
          提现金额</div>
        <div class="font-23 text-gray">提现金额范围：¥10.00-¥500.00
        </div>
      </div>
      <div class="m-0-auto mt-20 flex position-relative">
        <input class="phone-input text-default"
          placeholder="请输入提现金额" v-model="money" type="digit"
          @input="onInput" @blur="blurInput" />
        <div
          class="font-24 text-white position-absolute top-0 bottom-0 right-0 flex align-center icon-clear"
          style="z-index:2" v-if="money" @click="clear">
          <!-- <u-icon name="close" color="#2979ff" size="28"></u-icon> -->
        </div>

      </div>
      <div class="text-border-bottom mt-20"></div>
      <div class="flex justify-between align-center mt-30">
        <div class="font-23 text-default">提现至</div>
        <div class="flex align-center">
          <!-- <u-icon name="rmb-circle-fill" color="#2979ff" size="28"></u-icon> -->
          <span
            class="font-27 text-normal ml-10">招商银行（6909）</span>
          <van-icon name="arrow" color="#CCCFDA"
            size="24" />
        </div>
      </div>
      <div class="normal-btn mt-100" @click="submit">申请提现
      </div>
      <div
        class="font-23 text-normal line-h-32 mt-38 text-center"
        @click="goDetail">查看提现记录</div>
    </div>
    <div class="flex flex-column px-38">
      <div
        class="font-27 text-default line-h-38 mt-40 flex align-center font-weight-bolder">
        <span class="text-border"></span>提现规则
      </div>
      <div class="flex flex-column rule-list ">
        <div class="rule-item flex mt-30"
          v-for="(item,idx) in ruleList" :key="idx">
          <div class="flex pr-20">
            <div
              class="rule-num font-23 text-normal font-weight-bolder flex align-center justify-center">
              {{idx + 1}}</div>
          </div>
          <div
            class="font-23 text-normal line-h-32 flex-1 text-left">
            {{item.desc}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import util from "@/utils/util";
import { withdrawCreate } from "@/api/user";
import { Toast, Icon } from "vant";
import CommonHeader from "@/components/header/commonHeader.vue";
export default {
  components: {
    CommonHeader,
    VanIcon: Icon,
  },
  data() {
    return {
      moneyName: "",
      money: "",
      amount: 0,
      ruleList: [
        {
          desc: "我的收入必须高于10元，才能提现",
        },
        {
          desc: "用户每次只能发起1笔提现",
        },
        {
          desc: "单笔提现金额不得超过500块，单日累计提现金额不得超过2万",
        },
      ],
      userInfo: {
        id: "",
        nickname: "",
        mobile: "",
        avatar: "",
        gender: "",
        desc: "",
        real_name: "",
        character: "",
        vip_level: 0,
        vip_expires_in: "",
        coin: 0,
        income: 0,
        balance: 0,
        freeze: 0,
        token: "",
      },
    };
  },
  created() {
    this.amount = 0;
    util.onLogin().then((res) => {
      if (res) {
        this.getUserInfo();
      }
    });
  },
  methods: {
    getUserInfo() {
      this.$store
        .dispatch("GetInfo", "")
        .then((res) => {
          this.userInfo = res;
          console.log("userInfo", this.userInfo);
          if (this.userInfo && this.userInfo.income) {
            this.amount = this.userInfo.income
              ? parseFloat(this.userInfo.income / 100).toFixed(2)
              : 0;
          }
        })
        .catch((err) => {
          Toast.fail((err && err.msg) || "服务器出错，请稍后重试");
        });
    },
    goBill() {
      this.$router.push({
        path: "/Bill?tabIdx=1",
      });
    },
    goDetail() {
      this.$router.push({
        path: "WithdrawalDetail",
      });
    },
    blurInput() {
      this.money = Number(this.money);
    },
    onInput(e) {
      this.$nextTick(() => {
        let val = e.target.value.toString();
        val = val.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
        val = val.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
        val = val.replace(/^0+\./g, "0.");
        val = val.match(/^0+[1-9]+/) ? (val = val.replace(/^0+/g, "")) : val;
        val = val.match(/^\d*(\.?\d{0,2})/g)[0] || "";

        this.money = val;
      });
    },
    clear() {
      this.money = "";
    },
    submit() {
      console.log("提现金额", Number(this.money));
      if (Number(this.amount) <= 0) {
        Toast.fail("暂无可提现金额");
        return;
      }
      if (Number(this.money) > Number(this.amount)) {
        Toast.fail("提现金额不可大于可提现金额");
        return;
      }
      if (Number(this.money) < 10) {
        Toast.fail("提现金额请输入10-500元之间");
        return;
      }
      if (Number(this.money) > 500) {
        Toast.fail("提现金额请输入10-500元之间");
        return;
      }
      withdrawCreate({
        amount: Number(this.money) * 100,
      })
        .then((res) => {})
        .catch((err) => {
          Toast.fail((err && err.msg) || "申请提现失败，请稍后重试");
        });
    },
  },
};
</script>

<style lang="less" scoped>
.withdrawal-box {
  width: 687px;
  padding: 40px 38px 36px 38px;
  background: #f8f8fa;
  border-radius: 38px;
  margin: 0 auto;

  .phone-input {
    height: 76px;
    line-height: 76px;
    font-size: 40px;
    font-weight: 500;
    width: 100%;
    border: none;
    background: transparent;
    border-bottom: 1px solid #cccfda;
    width: 100%;
    outline: none;
  }

  input::placeholder {
    color: #979db1;
    font-size: 40px !important;
    font-weight: 400 !important;
  }
}
.rule-list {
  .rule-item {
    .rule-num {
      width: 31px;
      height: 31px;
      background: #f3f3f8;
      border-radius: 100%;
    }
  }
}
</style>