<template>
  <div class="nav-tab-box">
    <div class="nav-tab-list">
      <div class="nav-tab-item"
        :class="[currentType == item.type ? 'active': '']"
        v-for="(item,idx) in navTabList" :key="idx"
        @click="goPath(item.type,item.pathName)">
        {{item.name}}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["currentType"],
  data() {
    return {
      navTabList: [
        {
          name: "市场调研",
          type: 1,
          pathName: "Market"
        },
        {
          name: "创意市场",
          type: 2,
          pathName: "Creative"
        },
        {
          name: "设计助手",
          type: 3,
          pathName: "Ai"
        },
      ],
    };
  },
  methods: {
    goPath(type,pathName) {
      if(this.currentType == type) {
        return false;
      }
      this.$router.push({
        path: pathName
      })
    },
  },
};
</script>
<style lang="less" scoped>
.nav-tab-box {
  position: fixed;
  left: 0;
  right: 0;
  top: 110px;
  z-index: 99;
  background: #fff;
  padding: 40px 38px 0 38px;
  .nav-tab-list {
    display: flex;
    .nav-tab-item {
      font-weight: bold;
      font-size: 32px;
      color: #626a83;
      line-height: 46px;
      margin-right: 38px;
      &:last-child {
        margin-right: 0;
      }
      &.active {
        font-weight: bold;
        color: #3672fd;
      }
    }
  }
}
</style>