import request from "@/utils/request";


/**
 * 品类列表
 */
 export function getStatisticsCategoryList() {
    return request({
        url: '/statistics/category_list',
        method: 'get'
    })
}

/**
 * Ip详情统计数据
 * ip_id: ip_id
 * ym: 年月 202406
 */
 export function getStatisticsIp(params) {
    return request({
        url: '/statistics/ip',
        method: 'get',
        params: params
    })
}

/**
 * 市场综合分析统计数据
 * ym: 年月 202406
 */
 export function getStatisticsMarket(params) {
    return request({
        url: '/statistics/market',
        method: 'get',
        params: params
    })
}

/**
 * 产品策略统计数据
 * category_id : 品类id
 * ip_id: ip_id
 * ym: 年月 202406
 */
 export function getStatisticsProduct(params) {
    return request({
        url: '/statistics/product',
        method: 'get',
        params: params
    })
}

/**
 * 查找ip，模糊查询
 * q : 查询参数
 */
 export function getStatisticsSearchIp(params) {
    return request({
        url: '/statistics/search_ip',
        method: 'get',
        params: params
    })
}