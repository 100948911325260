<template>
    <div class="ip-index-box px-38">
        <CommonHeader :title="title" :showOrder="showOrder"></CommonHeader>
        <div class="market-pay-content">
            <div class="search-input">
                <van-search v-model="ipName" shape="round" placeholder="请输入IP名称" />
                <van-icon class="icon-search" name="search" />
            </div>
            <div class="ip-list">
                <div class="ip-item" v-for="(item,idx) in ipList" :key="idx" @click="goDetail">
                    <img class="bg-img" :src="item.ipImg">
                    <div class="ip-left">
                        <div class="title">{{item.name}}</div>
                        <div class="ip-rate">
                            <van-rate v-model="item.score" :size="20" void-color="#D9E5FF" color="#88ACFF" readonly  />
                            <span class="score">{{item.score}}</span>
                        </div>
                        
                        <div class="label-box">
                            <div class="label label1 mr-10">{{item.label1}}</div>
                            <div class="label label2">{{item.label2}}</div>
                        </div>
                    </div>
                    <div class="ip-right">
                        <img class="fenxi" src="@/assets/images/market/fenxi.png">
                        <div class="desc">分析<img class="right" src="@/assets/images/market/icon_right.png"></div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
    import CommonHeader from '@/components/header/commonHeader.vue';
    import { Search,Icon,Rate } from 'vant';
    export default {
        components: {
            CommonHeader,
            vanSearch: Search,
            vanIcon: Icon,
            vanRate: Rate
        },
        data() {
            return {
                title: "IP分析",
                showOrder: false,
                ipName: "",
                ipList: [{
                    name: "魔兽",
                    score: 8.8,
                    label1: "游戏",
                    label2: "影视",
                    ipImg: require("@/assets/images/market/icon_moshou.png")
                },{
                    name: "魔兽",
                    score: 8.8,
                    label1: "游戏",
                    label2: "影视",
                    ipImg: require("@/assets/images/market/icon_moshou.png")
                },{
                    name: "魔兽",
                    score: 8.8,
                    label1: "游戏",
                    label2: "影视",
                    ipImg: require("@/assets/images/market/icon_moshou.png")
                },{
                    name: "魔兽",
                    score: 8.8,
                    label1: "游戏",
                    label2: "影视",
                    ipImg: require("@/assets/images/market/icon_moshou.png")
                }]
            }
        },
        methods: {
            goDetail() {
                this.$router.push({
                    path: "/IpDetail"
                })
            }
        }
    }
</script>
<style lang="less" scoped>
    .ip-index-box {
        padding-top: 110px;

        .market-pay-content {
            margin-top: 30px;

            .search-input {
              
                height: 69px;
                background: #FBFBFC;
                box-shadow: 0 4px 8px 0 #E7E8ED;
                border-radius: 69px;
                border: 1px solid #E7E8ED;
                display: flex;
                position: relative;
                align-items: center;
                ::v-deep .van-search {
                    border-radius: 69px;
                    flex: 1;
                    width: 100%;
                    padding: 0;
                    background: transparent;
                    .van-search__content {
                        background: transparent;
                        width: 100%;
                        padding-left: 32px;
                        font-weight: 400;
                        font-size: 27px;
                        color: #979DB1;
                        text-align: left;
                        font-style: normal;
                        

                        .van-field__left-icon {
                            display: none;
                        }
                    }
                }

                .icon-search {
                    padding: 0 30px;
                    font-size: 40px;
                    color: #979DB1;
                    display: flex;
                    align-items: center;
                }

            }

            .ip-list {
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-top: 20px;
                .ip-item {
                    width: 100%;
                    height: 210px;
                    background: #F3F3F8;
                    border-radius: 20px;
                    border: 1px solid #E7E8ED;
                    position: relative;
                    margin-bottom: 20px;
                    display: flex;
                    .bg-img {
                        width: 496px;
                        height: 100%;
                        position: absolute;
                        left: 0;
                    }

                    .ip-left {
                        position: relative;
                        flex: 1;

                    }

                    .ip-right {
                        padding-right: 52px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }

                    .fenxi {
                        width: 46px;
                        height: 46px;
                    }

                    .desc {
                        display: flex;
                        font-weight: 400;
                        font-size: 27px;
                        color: #3672FD;
                        line-height: 38px;
                        text-align: left;
                        font-style: normal;
                        align-items: center;
                        margin-top: 20px;
                        .right {
                            width: 16px;
                            height: 24px;
                            margin-left: 8px;
                        }
                    }

                    .title {
                        font-weight: bold;
                        font-size: 32px;
                        color: #1A232F;
                        line-height: 46px;
                        text-align: left;
                        font-style: normal;
                        padding-left: 38px;
                        padding-top: 38px;
                    }

                    .ip-rate {
                        display: flex;
                        padding-left: 38px;
                        margin-top: 10px;
                        align-items: center;
                        .score {
                            font-weight: bold;
                            font-size: 27px;
                            color: #626A83;
                            line-height: 38px;
                            text-align: left;
                            margin-left: 16px;
                        }
                        ::v-deep .van-rate__item {
                            i {
                                font-size: 24px !important;
                            }
                        }
                    }

                    .label-box {
                        padding-left: 38px;
                        margin-top: 16px;
                        display: flex;
                    }

                    .label {
                        padding: 0 14px;
                        height: 31px;
                        border-radius: 6px;
                        font-weight: 400;
                        font-size: 19px;
                        color: #1A232F;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .label1 {
                        background: #D9E5FF;
                        border: 1px solid #3672FD;
                    }

                    .label2 {
                        background: #F8E5FF;
                        border: 1px solid #D664FF;
                        
                    }
                }
            }
        }
    }
</style>