<template>
    <div class="container">
        <div class="logo">
            <img src="@/assets/images/logo.png">
        </div>
        <div class="name">KIN AI</div>
        <div class="video-box">
            <img src="@/assets/images/common/shipin.png">
            <img class="play-img" src="@/assets/images/common/play.png">
        </div>
        <div class="to-product">
            <div class="default-btn2" @click="toProduct">进入产品</div>
        </div>
        <DescList></DescList>
        <LoginCom v-if="showLogin" @closeLogin="closeLogin"></LoginCom>
    </div>
</template>
<script>
    import DescList from "@/components/common/descList.vue";
    import LoginCom from "@/components/login/index.vue";
    import { getToken } from "@/utils/auth";
    export default {
        components: {
            DescList,
            LoginCom
        },
        data() {
            return {
                showLogin: false
            }
        },
        created() {
            
        },
        methods: {
            toProduct() {
                if(getToken()) {
                    this.$router.push({
                        path: "Market"
                    })
                } else {
                    this.showLogin = true;
                }
                
            },
            closeLogin() {
                this.showLogin = false;
                if(getToken()) {
                    this.$router.push({
                        path: "Market"
                    })
                }
            }
        }
    }
</script>
<style lang="less" scoped>
    .container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        background: url("@/assets/images/common/bg.png") center no-repeat;
        background-size: 100% 100%;
        .logo {
            margin-top: 86px;
            display: flex;
            justify-content: center;
            img {
                width: 264px;
                height: 200px;
            }
        }
        .name {
            font-weight: bold;
            font-size: 38px;
            color: #1A232F;
            line-height: 53px;
            margin-top: 26px;
        }
        .video-box {
            width: 640px;
            height: 360px;
            margin: 0 auto;
            margin-top: 58px;
            position: relative;
            img {
                width: 100%;
                height: 100%;
            }
            .play-img {
                width: 92px;
                height: 92px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                z-index: 1;
            }
        }
        .to-product {
            display: flex;
            justify-content: center;
            margin-top: 38px;
        }
    }
</style>