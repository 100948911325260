<template>
  <div>
    <van-popup class="helper-popup" v-model="showHelperVal" mode="left" :safe-area-inset-bottom="true"
      @close="closeHelper">
      <div class="pl-48 flex flex-column">
        <div class="font-27 text-default line-h-38 font-weight-bolder mb-34 pt-38 text-left">
          帮助</div>
        <div class="font-27 text-normal line-h-38 mb-50 flex align-center" @click.stop="goDetail(1)">
         <img class="default-img mr-20" src="@/assets/images/guanyu.png">
          关于我们
        </div>
        <div class="font-27 text-normal line-h-38 mb-50 flex align-center" @click.stop="goDetail(2)">
          <img class="default-img mr-20" src="@/assets/images/xieyi.png">
          用户协议
        </div>
        <div class="font-27 text-normal line-h-38 mb-50 flex align-center" @click.stop="goDetail(3)">
          <img class="default-img mr-20" src="@/assets/images/gerenxinxi.png">
          个人信息保护规则
        </div>
        <!-- <div class="font-27 text-normal line-h-38 mb-50 flex align-center" @click.stop="goDetail(4)">
          帮助与反馈
        </div> -->
      </div>
    </van-popup>
    <van-popup v-model="showAbout" closeable position="bottom" :style="{ height: '100%' }">
      <div class="flex flex-column px-38 about-box">
        <div class="pt-50 text-default font-27 line-h-38 font-weight-bolder text-center">关于我们</div>
        <div class="logo flex justify-center mt-40">
          <img class="rounded-20" src="https://files.sssrise.com/files/uploads/static/icon_app.png">
        </div>
        <div class="pt-50 text-primary font-27 line-h-38 font-weight-bolder text-center">当前版本：V1.0</div>
        <div class="text-normal font-23 line-h-32 mt-10 text-center">当前为最新版</div>
        <div class="text-normal font-23 line-h-32 mb-50 mt-70 px-70 text-left">
          人工智能（AI）是致力于解决通常与人类智能相关联的认知性问题的计算机科学领域，这些问题包括学习、创造和图像识别等。人工智能有多种定义，但其中一个比较通用的定义是：</div>
        <div class="text-normal font-23 line-h-32 mb-50 px-70 text-left">
          人工智能是使计算机能够像人类一样思考和行动的科学。人工智能的核心技术包括：机器学习：机器学习是使计算机能够从数据中学习而不进行显式编程的技术。
          自然语言处理：自然语言处理是使计算机能够理解和处理人类语言的技术。计算机视觉：计算机视觉是使计算机能够理解和识别图像和视频的技术。</div>
        <div class="text-normal font-23 line-h-32 px-70 text-left">
          人工智能已经被广泛应用于各个领域，包括：医疗：人工智能可以用于诊断疾病、开发新药和提供个性化治疗。金融：人工智能可以用于检测欺诈、进行风险评估和管理投资组合。制造：人工智能可以用于预测机器故障、优化生产流程和提高产品质量。零售：人工智能可以用于推荐产品、个性化购物体验和优化供应链。
        </div>
      </div>
    </van-popup>
    <van-popup v-model="showPrivacy" closeable position="bottom" :style="{ height: '100%' }">
      <div class="flex flex-column privacy-wrap">
        <div class="default-title pt-30 pb-30">用户协议</div>
        <div class="default-content">
    
          <div
            class="text-normal font-24 line-h-30 mb-20 mt-20 px-30">
            本协议（以下简称“协议”）是由您（以下简称“用户”或“您”）与我们（时升科技，以下简称“本公司”或“我们”）之间就使用线上应用产品（以下简称“本产品”或“本服务”）所订立的法律文件。在您使用本产品之前，请务必认真阅读本协议的全部内容（若您未满18周岁，请在法定监护人的陪同下阅读）。
          </div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            在您开始使用本产品之前，您必须同意本协议的所有条款。若您不同意本协议中的任何条款，请不要使用本产品。您的注册、登录、使用或继续使用本产品的行为将被视为对本协议所有条款的接受和遵守。
          </div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            特别提示
          </div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            内容来源与准确性：本产品提供的所有内容均由人工智能模型生成，可能存在错误或遗漏。我们不对答复内容的准确性、完整性或功能性作任何保证。此外，本产品输出的内容不代表本公司或任何相关方的立场或观点。
          </div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            专业咨询建议：若您通过本产品咨询育儿医疗、育儿食谱、育儿心理健康等其他育儿相关的专业问题，请理解本产品提供的建议仅供参考，并非专业意见。对于需要专业医疗、营养或心理健康指导的情况，您应咨询具备相应资质的专业人士，并在其指导下做出决策。
          </div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            免责声明：您应明确，本产品提供的内容仅供参考，不构成任何形式的建议、承诺或担保。对于因使用或依赖本产品内容而产生的任何直接或间接损害，本公司不承担任何责任。
          </div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            第1条：用户注册协议
          </div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            1.1 注册要求</div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            1.1.1
            用户在使用本产品之前，必须进行注册。注册时需要提供有效的手机号码以接收验证码。用户应确保提供的手机号码真实、准确，且验证码保密，不得泄露给任何第三方。
          </div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            1.1.2
            本产品主要面向成人用户。若您未满18周岁，请在征得法定监护人同意后注册和使用本产品。我们深知保护未成年人的重要性，并将依法采取相应保护措施。同时，我们也建议监护人对未成年人用户的使用进行合理引导和约束，共同营造健康的网络环境。
          </div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            1.2 实名认证与个人信息</div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            1.2.1
            为遵守国家法律法规关于实名认证的要求，以及保障您的账号安全，您在注册时需要向我们提供真实的手机号码。此外，为向您提供更优质的服务和功能，我们可能会依法收集其他必要的个人信息。
          </div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            1.2.2
            您在注册时设置的账号及个人信息（如账号名称、头像、简介等）应符合国家法律法规规定，不得含有违法或不良信息。您不得以他人名义注册账号，也不得进行恶意注册行为（如频繁注册、批量注册等）。我们有权对您的注册信息进行审核和管理。
          </div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            1.3 账号安全与验证</div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            3.3 为履行法定合规要求，我们有权采取技术手段或人工手段对用户使用本服务的行为及信息进行审查，包括但不限于对输入和输出进行审查、建立风险过滤机制、建立违法内容特征库等。对于违规用户，我们有权暂停您使用本产品。
          </div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            3.4 我们提示您，作为本服务的使用者，您在使用上述服务时应积极承担信息安全义务。您承诺不对本服务采取以下行为：
          </div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            3.4.1 通过输入，诱导生成违反相关法律法规的输出，包括但不限于：</div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            (1) 反对宪法所确定的基本原则。</div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            (2) 危害国家安全，泄露国家秘密，颠覆国家政权，推翻社会主义制度，破坏国家统一。</div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            (3) 损害国家荣誉和利益。</div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            (4) 煽动民族仇恨、民族歧视，破坏民族团结。</div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            (5) 侮辱、滥用英烈形象，否定英烈事迹，美化粉饰侵略战争行为的。</div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            (6) 破坏国家宗教政策，宣扬邪教和封建迷信。</div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            (7) 散布谣言、虚假有害信息，扰乱社会秩序，破坏社会稳定。</div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            (8) 宣扬淫秽、色情、赌博、暴力、凶杀、恐怖主义、极端主义或者教唆犯罪。</div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            (9) 煽动非法集会、结社、游行、示威、聚众扰乱社会秩序。</div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            (10) 诽谤他人，泄露他人隐私，侵害他人名誉权、肖像权、隐私权、知识产权和其他合法权益。</div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            (11) 破坏国际关系以及国际和平稳定。</div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            (12) 含有法律、行政法规禁止的其他内容的信息。</div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            3.4.2 通过输入，诱导生成不友善对话的输出，包括但不限于：</div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            (1) 人身攻击及辱骂他人。</div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            (2)
            针对以下群体发表诅咒、歧视、漠视生命尊严等性质的言论，群体包括：不同特定国籍、地域、性别、性别认同、性倾向、种族、民族、健康状况、职业、年龄、信仰、残障群体等。
          </div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            (3) 对他人进行诅咒、恐吓或威胁。</div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            (4) 对其他用户创作的内容直接进行贬低性的评论。</div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            (5) 对其他用户使用粗俗用语，并产生了冒犯。</div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            (6)
            针对以下群体发表偏见性质的言论，群体包括：特定国籍、地域、性别、性别认同、性倾向、种族、民族、健康状况、职业、年龄、信仰、残障群体等。
          </div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            3.4.3 通过输入，恶意对抗本服务的过滤机制，包括但不限于：</div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            (1) 输入难以辨识涵义影响阅读体验的字符、数字等无意义乱码。</div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            (2)
            恶意对抗行为，包括但不限于使用变体、谐音等方式规避服务检测来输入违反上述3.4.1和3.4.2的言论。
          </div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            3.4.4 干扰本服务正常运行以及损害百度合法权益，包括但不限于：</div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            (1) 利用本服务从事窃取商业秘密、窃取个人信息等违法犯罪活动。</div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            (2) 对本服务进行反向编译、反向工程或者以其他任何途径获得与本服务算法相关的任何信息。</div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            (3) 利用本服务开发与本服务存在竞争关系其他产品和服务（除非该限制违法相关法律规范）。</div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            (4)
            以任何方式（包括但不限于通过任何机器人、蜘蛛、其他自动设置、设置镜像）来抓取、复制本服务及其所包含的任何内容。
          </div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            (5)
            未经我们同意或未有合法正当依据，擅自去除、篡改本服务涉及的人工智能生成标识或深度合成内容显著标识。
          </div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            (6)
            将有关干扰、破坏或限制任何计算机软件、硬件或通讯设备功能的软件病毒或其他计算机代码、档案和程序之资料，加以上载、发布、发送电子邮件或以其他方式传送。
          </div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            (7) 非法入侵本服务服务器、篡改本服务之相关代码或其他损害本服务的行为。</div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            (8)
            企图规避安全设置或网络系统，包括获取用户不应获取的数据，登录未经明确授权的服务器或账号，或采用运行端口扫描等方式窥探其他网络的安全措施。
          </div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            (9) 以导致时升的计算机系统或设施承受不合理负载的方式获取输出，或进行可能导致此类情况出现的行为。
          </div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            (10) 未经时升授权，非法转售或对外提供相关的AI服务。</div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            3.4.5 其他违反法律法规规定、侵犯他人以及时升合法权益的行为，例如实施垄断或不正当竞争行为等。
          </div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            第4条 知识产权和其他权利</div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            4.1
            时升为本服务的开发、运营主体，并对本服务的开发和运营等过程中产生的所有数据、信息、输出等享有法律法规允许范围内的全部权利，但相关权利人依照法律规定应享有权利的除外。
          </div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            4.2
            时升在本服务中提供的内容（包括但不限于软件、技术、程序、代码、用户界面、网页、文字、图片、图像、音频、视频、图表、版面设计、商标、电子文档等）的知识产权（包括但不限于著作权、商标权、专利权和其他知识产权）属于时升所有，但相关权利人依照法律规定应享有权利的除外。
          </div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            4.3 您理解并承诺，您在使用本服务时的输入不侵犯任何人的知识产权、肖像权、名誉权、荣誉权、姓名权、隐私权、个人信息权益等合法权益，不涉及任何国家秘密、商业秘密、重要数据或其他可能会对国家安全或者公共利益造成不利影响的数据，否则由此产生的侵权风险和责任由您承担。时升由此遭受的全部直接和间接损失（包括但不限于经济、商誉、维权支出、律师费等损失）也同样由您承担。
          </div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            4.4 您知悉、理解并同意，如果您按照法律规定对您的输入（包括您使用本服务过程中自行上传、发布的全部内容）和/或输出享有权利的（包括但不限于知识产权、肖像权等），您使用本服务均不会造成前述合法权利的转移或减损，除非我们与您另有约定。与此同时，您理解并同意百度将在法律允许的范围内为实现本服务目的对您上传、发布的内容进行存储及使用（包括但不限于复制、分发、传送、公开展示、编辑等）。
          </div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            第5条 个人信息保护</div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            5.1
            时升非常重视对用户个人信息的保护。时升将按照《个人信息保护规则》的规定收集、使用、储存和分享您的个人信息。《个人信息保护规则》为本协议不可分割的组成部分。
          </div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            第6条 服务费用</div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            6.1
            我们服务分为积分制服务，您可通过购买积分来享受我们提供的服务，积分可用于人工智能问答服务和本产品的其他相关服务。我们将在相关服务页面展示积分的收费标准与积分的消耗标准等相关信息。
          </div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            6.2
            除前述积分服务外，我们可能在提供本服务过程中向您收取其他费用，具体以届时我们就本服务对外公布的信息为准。如用户使用本服务中的其他收费项目，应按照我们对应公布的计费标准和方式支付相关费用。
          </div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            6.3
            前述修改、变更或开始收费前，我们将在相应服务页面进行通知或公告。如用户拒绝付费，那么用户将不能继续使用本服务中的收费功能。
          </div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            第7条 违约责任和处罚措施</div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            7.1 因用户违反相关法律法规或本协议约定导致的任何后果，均由用户独立承担责任、赔偿损失，与时升无关。若因用户使用本服务的行为给时升或与时升公司合作的其他单位面临任何投诉、举报、质询、索赔、诉讼，或者使时升公司或者与时升公司合作的其他单位因此遭受任何名誉、声誉或者财产上的损失，您应积极地采取一切可能采取的措施，以保证时升公司及与时升公司合作的其他单位免受上述索赔、诉讼的影响。同时您对时升公司及与时升公司合作的其他单位因此遭受的直接及间接经济损失负有全部的损害赔偿责任。
          </div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            7.2 如果用户以任何手段干扰本服务的运行或干扰其他用户对本服务的使用的，时升有权要求其赔偿损失（包括但不限于经济、商誉、维权支出、律师费等损失）。
          </div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            7.3 如果用户有上述违反行为时，时升有权采取警示、限制功能、暂停或终止提供服务、删除违法违规信息、锁定账号等我们认为合理的处理措施，并保存有关记录。对处理结果存在疑议的，用户可向我们进行申诉，我们将及时受理并进行处理和反馈。
          </div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            7.4 如果我们依第7.3条采取限制功能、暂停或终止提供服务等处理措施的，根据处理措施的不同，用户可能短期或长期无法使用我们的服务，我们有权不予退还用户剩余积分等增值服务对应的费用。
          </div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            第8条 免责声明</div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            8.1 时升致力于提供安全、稳定、持续的服务，保障用户正常使用，但时升仅以“现状”、“当前功能”的状态提供本服务。时升不承担除法律有明确规定外的其他责任，如：不保证本服务在操作上不会中断或没有错误，不保证在现有技术水平下有能力纠正本服务的所有缺陷，亦不保证本服务能满足用户的所有要求。时升将在现有技术的基础上尽最大努力提供相应的安全措施以保障本服务的安全和正常运行，包括但不限于通过限制用户单次可发送的服务器请求数量。在特定时间段内用户的请求数量达到风险预警上限后，基于服务运营安全的考虑，您的账号可能会被暂停。
          </div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            8.2 由于可能存在的计算机病毒、网络通讯故障、系统维护等方面的因素以及可能发生的不可抗力事件或意外事件，时升在此明确声明对本服务不作明示或暗示的保证，包括对本服务的可适用性、没有错误或疏漏、持续性、准确性、可靠性、适用于某一特定用途或满足用户的需求、使用效果等。
          </div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            8.3 人工智能和机器学习是快速发展的研究领域，时升也在不断努力改进本服务，使其更加准确、可靠、安全、可信。但限于技术特性，时升在尽到合理努力的前提下，仍无法完全保证您通过本服务获得的输出的合法性、真实性、准确性以及完整性。您应对本服务中的输出自行加以判断，并承担因使用内容引起的所有风险。我们提示您，除法律有明确规定外，时升无法也不会对前述风险而导致的任何损失或损害承担责任。
          </div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            第9条 法律管辖和适用</div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            9.1 本协议的订立、执行和解释及争议的解决均应适用中华人民共和国大陆地区法律。</div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            9.2 本协议的签订、履行或解释发生争议，双方应努力友好协商解决。如协商不成，任何一方均有权向上海时升数据科技有限公司住所地有管辖权的法院起诉。
          </div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            第10条 本协议的更新与终止</div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            10.1
            您对本协议的接受包括接受我们对本协议任何条款随时所做的任何修改。我们有权随时修改本协议，更新后的协议将通过官方网站公布等适当的方式进行公布。一旦公布即代替原协议条款，请您自行在官方网站查阅最新版协议条款。如您不接受修改后的协议，请您立即停止使用本服务。您继续使用本服务将被视为您已接受了修改后的协议。
          </div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            上海时升数据科技有限公司</div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            更新日期：2024年3月1日</div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            生效日期：2024年3月1日</div>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="showRule" closeable position="bottom" :style="{ height: '100%' }">
      <div class="flex flex-column privacy-wrap">
        <div class="default-title pt-30 pb-30">个人信息保护规则</div>
        <div class="default-content">
    
          <div
            class="text-normal font-24 line-h-30 mb-20 mt-20 px-30">
            本协议（以下简称“隐私协议”）规定了您（以下简称“用户”）在使用我们提供的服务时，我们如何收集、使用、存储、共享和保护您的个人信息。请您仔细阅读本隐私协议，并确保您充分理解其内容。如果您不同意本隐私协议中的任何条款，请不要使用我们的服务。
          </div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            一、个人信息的收集</div>
          <div
            class="text-normal font-24 line-h-30 mb-20 px-30">
            1.1 我们可能收集的个人信息包括但不限于<span
              class="text-btn-submit">您的姓名、电子邮件地址、电话号码、地理位置、设备信息、浏览记录、使用习惯</span>等。这些信息将帮助我们更好地了解您的需求，以便提供更优质的服务。
          </div>
          <div
            class="text-normal font-24 line-h-30 mb-20  px-30">
            二、个人信息的使用</div>
          <div
            class="text-normal font-24 line-h-30 mb-20  px-30">
            2.1 我们将使用您的个人信息来提供和改进我们的服务，包括但不限于<span
              class="text-btn-submit">个性化推荐、AI服务、市场调研</span>等。
          </div>
          <div
            class="text-normal font-24 line-h-30 mb-20  px-30">
            2.2 我们将严格遵守法律法规，不会将您的个人信息用于任何非法或未经您授权的目的。</div>
          <div
            class="text-normal font-24 line-h-30 mb-20  px-30">
            三、个人信息的存储和保护</div>
          <div
            class="text-normal font-24 line-h-30 mb-20  px-30">
            3.1 我们将采取合理的技术和管理措施，确保您的个人信息在传输和存储过程中的安全。</div>
          <div
            class="text-normal font-24 line-h-30 mb-20  px-30">
            3.2 我们将根据您的个人信息的重要性和敏感程度，采取适当的加密、脱敏等安全措施。</div>
          <div
            class="text-normal font-24 line-h-30 mb-20  px-30">
            3.3 我们将定期备份您的个人信息，以防止数据丢失或损坏。</div>
          <div
            class="text-normal font-24 line-h-30 mb-20  px-30">
            四、个人信息的共享</div>
          <div
            class="text-normal font-24 line-h-30 mb-20  px-30">
            4.1 我们不会将您的个人信息共享给任何第三方，除非事先获得您的明确同意或法律法规另有规定。
          </div>
          <div
            class="text-normal font-24 line-h-30 mb-20  px-30">
            4.2 在以下情况下，我们可能需要共享您的个人信息：</div>
          <div
            class="text-normal font-24 line-h-30 mb-20  px-30">
            （1）与我们的关联公司共享，以便提供更优质的服务；</div>
          <div
            class="text-normal font-24 line-h-30 mb-20  px-30">
            （2）与我们的合作伙伴共享，以便提供更丰富的服务内容；</div>
          <div
            class="text-normal font-24 line-h-30 mb-20  px-30">
            （3）在法律法规要求或政府主管部门要求的情况下共享。</div>
          <div
            class="text-normal font-24 line-h-30 mb-20  px-30">
            五、您的权利</div>
          <div
            class="text-normal font-24 line-h-30 mb-20  px-30">
            5.1 如果您认为我们的个人信息处理行为违反了法律法规或侵犯了您的合法权益，您可以向有关部门投诉或举报。
          </div>
          <div
            class="text-normal font-24 line-h-30 mb-20  px-30">
            六、隐私政策的变更</div>
          <div
            class="text-normal font-24 line-h-30 mb-20  px-30">
            6.1
            我们可能会根据法律法规的变化、业务发展的需要或其他原因对本隐私政策进行不定期更新。更新后的隐私政策将通过适当方式向您告知。请您定期查阅本隐私政策，以了解最新的个人信息保护措施。
          </div>
          <div
            class="text-normal font-24 line-h-30 mb-20  px-30">
            七、法律适用和争议解决</div>
          <div
            class="text-normal font-24 line-h-30 mb-20  px-30">
            7.1
            本隐私协议适用中华人民共和国法律。如双方因本隐私协议产生任何争议，应首先通过友好协商解决；协商不成的，任何一方均有权向有管辖权的人民法院提起诉讼。
          </div>
          <div
            class="text-normal font-24 line-h-30 mb-20  px-30">
            7.2 本隐私协议的解释权归我们所有。</div>
          <div
            class="text-normal font-24 line-h-30 mb-20  px-30">
            请您仔细阅读本隐私协议，并确保充分理解其内容和含义。如果您有任何问题或疑虑，请随时联系我们。我们将竭诚为您提供服务。
          </div>
        </div>
      </div>
    </van-popup>
  </div>

</template>

<script>
  import { Popup } from "vant";
  export default {
    components: {
      VanPopup: Popup
    },
    props: ["showHelperCom"],
    data() {
      return {
        showHelperVal: false,
        showAbout: false,
        showPrivacy: false,
        showRule: false
      };
    },
    created() {
      this.showHelperVal = this.showHelperCom;
    },
    methods: {
      goDetail(type) {
        switch (type) {
          case 1:
            this.showAbout = true;
            break;
          case 2:
            this.showPrivacy = true;
            break;
          case 3:
            this.showRule = true;
            break;
          case 4:
            // uni.navigateTo({
            //   url: "/pages/feedback/feedback",
            // });
            break;
        }
      },
      closeHelper() {
        this.$emit("closeHelper");
      },
    },
  };
</script>

<style lang="less" scoped>
  .helper-popup {
    background: #fff;
    width: 580px;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    padding: 0 45px;
    transform: translate3d(0, 0, 0);
    display: flex;
    align-items: flex-end;

    
  }
  .logo {
      img {
        width: 244px;
        height: 244px;
      }
    }
</style>