<template>
    <div class="compare-rank-box">
        <div class="flex align-center pl-20 mb-20" v-if="!hideTitle">
            <div class="text-border"></div>
            <div class="default-title mt-0">{{title}}<span class="sub-title" v-if="subTitle">({{subTitle}})</span></div>

        </div>
        <div class="rank-list">
            <div class="rank-item">
                <div class="rank-info-list pb-20">
                    <div class="rank-info">
                        <div>
                        </div>
                        <div class="name-box" style="padding-left: 32px">
                            <div class="name title-name one-text">品类
                            </div>
                            <div class="name title-name one-text">同类IP
                            </div>
                        </div>

                        <div class="percent-count">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="rank-list">

            <div class="rank-item" v-for="(item,idx) in currentList" :key="idx" v-show="idx == (showIdx -1 )">

                <div class="rank-info-list" v-for="(subItem,secIdx) in item.rankList" :key="secIdx">

                    <div class="rank-info">
                        <div class="num"><span>{{secIdx + 1}}</span>
                        </div>
                        <div class="name-box">
                            <div class="name one-text">{{subItem.category_name}}
                            </div>
                            <div class="name one-text">{{subItem.ip_name}}
                            </div>
                        </div>

                        <div class="percent-count">{{subItem.rate}}<span v-if="!hiddenLabel">%</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: ["title", "subTitle", "currentList", "bgColor", "hiddenLabel", "hideTitle","showIdx"],
        methods: {
            calPerWidth(rankList, percent) {
                let sum = 0;
                for (let i = 0, j = rankList.length; i < j; i++) {
                    sum += Number(rankList[i].percent);
                }
                if (percent == 0) {
                    return 0;
                }
                if (sum == 0) {
                    return 0;
                } else {
                    return parseFloat(Number(percent) / sum * 100).toFixed(2);
                }

            }
        }
    };
</script>
<style lang="less" scoped>
    @import url("@/assets/css/rankTitle.less");

    .compare-rank-box {
        display: flex;
        width: 100%;
        background: #ffffff;
        margin-bottom: 20px;
        flex-direction: column;


        .rank-list {
            display: flex;
            width: 100%;
            padding: 0 20px;
            .rank-item {
                flex: 1;
                display: flex;
                flex-direction: column;
                position: relative;

                .title {
                    font-weight: 400;
                    font-size: 14px;
                    color: #626a83;
                    line-height: 20px;
                    text-align: center;
                    padding-top: 8px;
                    margin-bottom: 30px;
                }

                .zhanbi {
                    font-weight: 400;
                    font-size: 10px;
                    color: #979db1;
                    line-height: 14px;
                    position: absolute;
                    right: 10px;
                    top: 30px;
                }

            }

        }

        .rank-info-list {
            display: flex;
            flex-direction: column;
            position: relative;
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .rank-info {
            display: flex;
            padding: 0 6px 0 10px;
            align-items: center;

            .num {
                width: 32px;
                height: 32px;
                background: #fbfbfc;
                box-shadow: 0px 4px 8px 0px #e7e8ed;
                border: 1px solid #e7e8ed;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100%;

                span {
                    font-weight: bold;
                    font-size: 19px;
                    color: #1a232f;
                }
            }

            .name-box {
                display: flex;
                flex: 1;

                .name {
                    width: 50%;
                    font-weight: 400;
                    font-size: 24px;
                    color: #1a232f;
                    text-align: left;
                    margin-left: 16px;
                }
                .title-name {
                    color: #979DB1;
                    margin-left: 0;
                }
            }

            .percent-count {
                width: 72px;
                font-weight: 400;
                font-size: 24px;
                color: #626a83;
                margin-left: 12px;
            }
        }
    }
</style>