<template>
  <div class="login-register-box">
    <div class="login-register-content">
      <van-icon class="icon-defalut-close" name="close"
        color="#CCCFDA" size="20" @click="closeLogin" />
      <div class="default-title">
        {{isRegister ? "注册" : "登录"}}</div>
      <img src="@/assets/images/logo.png">
      <div class="form-box">
        <van-form class="form-list" ref="form">
          <div class="form-item" prop="phoneNumber">
            <div class="form-desc">手机号码</div>
            <van-field class="form-input"
              v-model="form.phoneNumber"
              placeholder="请输入手机号" maxlength="11"
              :rules="rules.phoneNumber"></van-field>
            <!-- :rules="[{pattern, required: true, message: '请输入正确的手机号' }]" -->
          </div>
          <div class="form-item" prop="code">
            <div class="form-desc">验证码</div>
            <van-field class="form-input" type="number"
              v-model="form.code" placeholder="请输入验证码"
              maxlength="4"
              :rules="[{required: codeRequire, message: '请输入验证码' }]">
            </van-field>
            <!-- <div class="code-box"
              :class="[countdown == 0 ? 'cursor' : '']"
              @click="getCode">
              {{ countdown > 0 ? `${countdown}秒后重新获取` : '获取验证码' }}
              
            </div> -->
            <van-button class="code-box" round block
              type="info" native-type="getCode"
              @click="getCode">{{ countdown > 0 ? `${countdown}秒后重新获取` : '获取验证码' }}</van-button>
          </div>
          <!-- <div class="login-btn cursor" @click="login">
            {{isRegister ? "注册" : "登录"}}</div> -->
          <van-button class="login-btn" round block
            type="info" native-type="login"
            @click="login">{{isRegister ? "注册" : "登录"}}</van-button>
        </van-form>

        <div class="register-btn cursor" v-if="!isRegister"
          @click="register">注册</div>
      </div>
      <div class="bottom-box">
        <!-- <div class="third-box">
            <span class="border"></span>
            <div class="third-title">第三方登录</div>
            <span class="border"></span>
          </div>
          <div class="third-list">
            <div class="third-item"
              v-for="(item,idx) in thirdList" :key="idx">
              <img class="cursor" :src="item.imgSrc">
            </div>
          </div> -->
        <div class="privacy-box cursor"
          @click="choosePrivacy">
          <van-icon v-if="isPrivacyChoose" name="passed"
            color="#3672FD" size="20" />
          <van-icon v-else name="circle" color="#CCCFDA"
            size="20" />
          阅读并接受<span @click.stop="showPrivacyFun">《服务协议》</span>
          <span @click.stop="showRuleFun">《隐私政策》</span>
          <span @click.stop="showRuleFun">《知识产权保护协议》</span>
        </div>
      </div>
    </div>
    <PrivacyCom @closeHelp="closeHelp" v-if="showPrivacy">
    </PrivacyCom>
    <RuleCom @closeHelp="closeHelp" v-if="showRule">
    </RuleCom>
  </div>
</template>
  <script>
import { userVerifyCode } from "@/api/login";
import { Form, Field, Icon, Button, Toast } from "vant";
import PrivacyCom from "@/components/privacy/index.vue";
import RuleCom from "@/components/rule/index.vue";
const TIME_COUNT = 60;
export default {
  components: {
    vanForm: Form,
    vanField: Field,
    vanIcon: Icon,
    vanButton: Button,
    PrivacyCom,
    RuleCom,
  },
  data() {
    return {
      hintShow: false, // 提示语显示
      hint: "", // 提示语
      phone: "",
      code: "",
      form: {
        phoneNumber: "",
        code: "",
      },
      countdown: 0,
      pattern: /^1[3-9]\d{9}$/,
      codePattern: /^\d{4}$/,
      rules: {
        phoneNumber: [
          { required: true, message: "手机号不能为空" },
          {
            pattern: /^1[3-9]\d{9}$/,
            message: "请输入正确的手机号",
          },
        ],
        code: [
          { required: true, message: "验证码不能为空" },
          {
            pattern: /^\d{4}$/,
            message: "请输入正确的验证码",
          },
        ],
      },
      thirdList: [
        // {
        //   type: 1,
        //   imgSrc: require("@/assets/images/wechat.png"),
        // },
        // {
        //   type: 2,
        //   imgSrc: require("@/assets/images/zfb.png"),
        // },
        // {
        //   type: 3,
        //   imgSrc: require("@/assets/images/zfb.png"),
        // },
      ],
      isPrivacyChoose: true,
      isRegister: false,
      timer: null,
      showPrivacy: false,
      showRule: false,
      codeRequire: false,
    };
  },
  created() {
    this.isPrivacyChoose = true;
    this.isRegister = false;
  },
  methods: {
    onSubmit(values) {
      console.log("submit", values);
    },
    showPrivacyFun() {
      this.showPrivacy = true;
    },
    showRuleFun() {
      this.showRule = true;
    },
    closeHelp() {
      this.showPrivacy = false;
      this.showRule = false;
    },
    validator(val) {
      return /^1[3-9]\d{9}$/.test(val);
    },
    codeValidator(val) {
      return /^\d{4}$/.test(val);
    },
    getCode() {
      if (this.countdown > 0) {
        return;
      }
      userVerifyCode({
        mobile: this.form.phoneNumber,
      })
        .then((res) => {
          console.log("res", res);
          if (res.error == 0) {
            Toast.success("获取验证码成功");
            if (!this.timer) {
              this.countdown = TIME_COUNT;
              this.timer = setInterval(() => {
                if (this.countdown > 0 && this.countdown <= TIME_COUNT) {
                  this.countdown--;
                } else {
                  clearInterval(this.timer);
                  this.timer = null;
                }
              }, 1000);
            }
          } else {
            Toast.fail((res && res.msg) || "获取验证码失败，请稍后重试");
          }
        })
        .catch((err) => {
          Toast.fail((err && err.msg) || "获取验证码失败，请稍后重试");
        });
    },
    choosePrivacy() {
      this.isPrivacyChoose = !this.isPrivacyChoose;
    },
    register() {
      this.isRegister = !this.isRegister;
    },
    login() {
      if (!this.isPrivacyChoose) {
        Toast.fail("请先同意协议");
        return;
      }
      let codeValidator = this.codeValidator(this.form.code);
      console.log("codeValidator",codeValidator)
      if (!codeValidator) {
        this.codeRequire = true;
        return;
      }
      
      this.$store
        .dispatch("Login", this.form)
        .then(() => {
          Toast.success("登录成功");
          this.$emit("closeLogin");
          this.$emit("initData");
        })
        .catch((err) => {
          Toast.fail((err && err.msg) || "登录失败，请稍后重试");
        });
    },
    closeLogin() {
      this.$emit("closeLogin");
    },
  },
};
</script>
  <style lang="less" scoped>
.login-register-box {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: transparent;
  z-index: 10002;
  .login-register-content {
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    background: #fff;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    .default-title {
      font-weight: bold;
      font-size: 32px;
      color: #1a232f;
      line-height: 46px;
      text-align: center;
      font-style: normal;
      padding-top: 20px;
    }

    img {
      width: 264px;
      height: 200px;
      margin: 0 auto;
      margin-top: 100px;
    }
    .form-box {
      flex: 1;
    }
    .form-list {
      margin-top: 80px;
    }
    .form-item {
      position: relative;
      width: 573px;
      margin: 0 auto;
      margin-bottom: 32px;
      &:last-child {
        margin-bottom: 0;
      }
      .form-desc {
        font-weight: 400;
        font-size: 28px;
        color: #626a83;
        line-height: 20px;
        text-align: left;
        margin-bottom: 15px;
      }
      .form-input {
        width: 573px;
        display: flex;
        align-items: center;
        position: relative;
        padding: 0 !important;

        ::v-deep .van-field__control {
          width: 100%;
          height: 76px;
          background: #fbfbfc;
          box-shadow: 0 4px 8px 0 #e7e8ed;
          border-radius: 46px;
          border: 1px solid #e7e8ed;
          // background: #FBFBFC;
          font-weight: 400;
          font-size: 28px;
          color: #979db1;
          padding-left: 40px;
        }
        input {
          width: 100%;
          height: 76px;
          background: #fbfbfc;
          box-shadow: 0 4px 8px 0 #e7e8ed;
          border-radius: 46px;
          border: 1px solid #e7e8ed;
          // background: #FBFBFC;
          font-weight: 400;
          font-size: 28px;
          color: #979db1;
          padding-left: 40px;
        }
        input:focus {
          outline: none;
        }
      }
      .code-box {
        padding-right: 40px;
        text-align: right;
        position: absolute;
        right: 0;
        top: 40px;
        z-index: 1;
        font-weight: 400;
        font-size: 24px;
        color: #3672fd;
        height: 76px;
        line-height: 76px;
        width: auto;
        background: transparent;
        border: none;
      }
    }
    .login-btn {
      width: 573px;
      height: 76px;
      background: #3672fd;
      box-shadow: 0 4px 8px 0 #d9e5ff;
      border-radius: 52px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      margin-top: 30px;
      font-weight: 400;
      font-size: 28px;
      color: #ffffff;
    }
    .register-btn {
      width: 573px;
      height: 76px;
      background: #ffffff;
      box-shadow: 0 4px 8px 0 #e7e8ed;
      border-radius: 52px;
      border: 1px solid #e7e8ed;
      margin: 0 auto;
      margin-top: 20px;
      font-weight: 400;
      font-size: 28px;
      color: #626a83;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .bottom-box {
      padding-bottom: 22px;
      padding-left: 20px;
      .third-box {
        display: flex;
        align-items: center;
        justify-content: center;
        .border {
          width: 106px;
          height: 1px;
          background: rgba(204, 207, 218, 0.6);
        }
        .third-title {
          font-weight: 400;
          font-size: 12px;
          color: #979db1;
          line-height: 17px;
          padding: 0 14px;
        }
      }
      .third-list {
        margin-top: 18px;
        display: flex;
        justify-content: center;
        .third-item {
          margin-right: 36px;
          display: flex;
          &:last-child {
            margin-right: 0;
          }
          img {
            width: 48px;
            height: 48px;
            border-radius: 100%;
            margin-top: 0;
          }
        }
      }
      .privacy-box {
        margin-top: 57px;
        font-weight: 400;
        font-size: 22px;
        color: #979db1;
        line-height: 31px;
        display: flex;
        align-items: center;
        i {
          font-size: 20px;
          color: #3672fd;
          margin-right: 10px;
        }
        .icon-privacy {
          width: 20px;
          height: 20px;
          background: #ffffff;
          border: 1px solid #cccfda;
          display: inline-block;
          border-radius: 100%;
        }
        span {
          color: #3672fd;
        }
        a {
          text-decoration: none;
          color: #3672fd;
        }
      }
    }
  }
}
</style>