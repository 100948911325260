<template>
  <div class="problem-box justify-end pl-20">
    <div class="flex mr-30"
      v-if="problemItem && problemItem.showZhuanFa">
      <img v-if="problemItem && problemItem.zhuanFaChoose"
        class="icon-select mt-30"
        src="https://files.sssrise.com/files/uploads/static/icon_select_check.png"
        @click="chooseZhuanfa(1)">
      <img v-else class="icon-select mt-30"
        src="https://files.sssrise.com/files/uploads/static/icon_select_default.png"
        @click="chooseZhuanfa(2)">
    </div>
    <div class="flex">
      <div class="problem-info ml-0 mr-30">

        <div
          class="problem-right-desc position-relative align-center flex"
          style="flex-direction: column;">
          <div class="dhk-arrow"><img
              style="width: 40px;height: 32px;"
              src="https://files.sssrise.com/files/uploads/static/dhk_right_arrow.png">
          </div>
          <div class="strong-right"
            v-if="problemItem && problemItem.desc">
            {{problemItem && problemItem.desc}}</div>
          <div style="padding: 0 20px;"
            v-if="problemItem && problemItem.imageSrc">
            <img style="max-width:460px;"
              :src="problemItem.imageSrc"
              @click="previewImage(problemItem.imageSrc)">
          </div>
        </div>
        <div
          class="date-time font-20 text-gray line-h-28 mt-10 flex justify-end">
          {{formatTimeVal(problemItem.dataTime)}}</div>
      </div>
      <div class="problem-avatar ml-20">
        <img v-if="userInfo && userInfo.avatar"
          :src="userInfo.avatar">
        <img v-else
          src="https://files.sssrise.com/files/uploads/static/default_avatar.png">
      </div>
    </div>

  </div>
</template>

<script>
import time from "@/utils/time";
import { ImagePreview } from "vant";
export default {
  props: ["problemItem", "problemIdx", "userInfo"],
  data() {
    return {};
  },
  methods: {
    previewImage(image) {
      ImagePreview([image]);
    },
    formatTimeVal(val) {
      return time.formatTime(val);
    },
    showEditInput() {},
    chooseZhuanfa(type) {
      this.$emit("chooseZhuanfa", {
        idx: this.problemIdx,
        type: type,
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import url(@/assets/css/problemDesc.less);
</style>
