<template>

  <div class="bill-wrap flex flex-column">
    <CommonHeader title="账单明细"></CommonHeader>
    <div class="mt-50 flex tab-list justify-center ">
      <div class="tab-item"
        :class="[tabIdx == idx && 'active']"
        v-for="(item,idx) in tabList" :key="idx"
        @click="tabChange(item,idx)">{{item.name}}</div>
    </div>
    <div class="px-38 position-relative">
      <div class="bill-filter mt-30 flex align-center  "
        @click="selectType" v-if="tabIdx == 0">
        <div class="font-23 text-default flex-1 pl-24">
          {{ currentTypeDetail && currentTypeDetail.name || ""}}
        </div>
        <div class="icon-img">
          <img src="@/assets/images/common/icon_down.png">
        </div>

      </div>
      <div class="bill-popup" v-if="showFilter">
        <div class="px-38">
          <div
            class="font-27 text-default line-h-38 font-weight-bolder mt-30">
            选择筛选项目</div>
          <div class="popup-list flex flex-wrap">
            <div
              class="popup-item flex justify-center align-center"
              :class="[typeIdx == idx && 'active']"
              v-for="(item,idx) in typeList" :key="idx"
              @click="chooseType(item,idx)">{{item.name}}
            </div>
          </div>
          <div class="flex justify-between mt-20">
            <div
              class="reset-btn font-27 text-normal flex justify-center align-center"
              @click="reset">重置</div>
            <div
              class="submit-btn font-27 text-white flex justify-center align-center"
              @click="submit">确定</div>
          </div>
        </div>
      </div>
    </div>
    <div class="split-border mt-20"></div>
    <div class="bill-content">
      <div class="bill-list">
        <van-pull-refresh v-model="refreshing"
          @refresh="onRefresh">
          <van-list v-model="loading" :offset="1500"
            :finished="finished" finished-text="没有更多了"
            @load="onLoad">
            <div class="bill-item px-38 mt-38 flex"
              v-for="(item,idx) in billList" :key="idx">
              <div class="pr-36">
                <div
                  class="bill-type flex align-center justify-center font-27 text-default"
                  :class="[item.type == 1 && 'type1']">
                  {{item.type == 1 ? '收入' : '支出'}}</div>
              </div>
              <div
                class="text-border-bottom flex-1 flex pb-38">
                <div class="flex-1">
                  <div
                    class="font-27 text-default font-weight-bolder line-h-38 mt-10 text-ellipsis text-left">
                    {{item.type_cn}}</div>
                  <div
                    class="font-23 text-gray line-h-32 mt-04 text-left">
                    {{item.created_at}}</div>
                </div>

                <div
                  class="font-32 text-default font-weight-bolder flex align-center">
                  {{item.amount}}</div>
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>
      <van-empty v-if="billList.length == 0"
        description="暂无数据" />
    </div>
  </div>
</template>

<script>
import { getUserCoinLogs } from "@/api/user";
import util from "@/utils/util";
import { Empty, CountDown, List, PullRefresh, Toast, Icon } from "vant";
import CommonHeader from "@/components/header/commonHeader.vue";
export default {
  components: {
    VanEmpty: Empty,
    VanCountDown: CountDown,
    CommonHeader,
    VanPullRefresh: PullRefresh,
    VanList: List,
    VanIcon: Icon,
  },
  data() {
    return {
      tabList: [
        {
          name: "积分账单",
        },
        {
          name: "收入账单",
        },
      ],
      tabIdx: 0,
      typeList: [
        {
          id: 0,
          name: "全部账单",
        },
        {
          id: 1,
          name: "支出",
        },
        {
          id: 2,
          name: "收入",
        },
      ],
      params: {
        type: 0,
        pageSize: 0,
        offset: 0,
        limit: 20,
      },
      currentTypeDetail: null,
      typeIdx: 0,
      showFilter: false,
      billList: [],
      statusLoadmore: "loadmore",
      current: 1,
      isLoading: true,
      show: false,
      mode: "date",
      userInfo: null,
      loading: false,
      finished: false,
      refreshing: false,
      isFirst: true,
    };
  },
  created() {
    let options = this.$route.query;
    this.tabIdx = (options && options.tabIdx) || 0;
    this.currentTypeDetail = this.typeList[this.typeIdx];
    util.onLogin().then((res) => {
      if (res) {
        // this.getUserCoinLogsReq();
      }
    });
  },
  
  methods: {
    onLoad() {
      console.log("onload");
      if (this.refreshing) {
        this.currentList = [];
        this.refreshing = false;
      }

      this.finished = true;
      if (!this.isFirst) {
        this.params.pageSize += 1;
        this.params.offset = this.params.pageSize * this.params.limit;
      } else {
        this.isFirst = false;
      }

      this.getUserCoinLogsReq();
    },
    onRefresh() {
      console.log("ref");
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.params.pageSize = 0;
      this.params.offset = 0;
      this.getUserCoinLogsReq();
    },
    clear() {
      this.params.pageSize = 0;
      this.params.offset = 0;
      this.billList = [];
    },
    selectType() {
      this.showFilter = true;
    },
    chooseType(item, idx) {
      if (this.typeIdx == idx) {
        return;
      }
      this.typeIdx = idx;
      if (this.typeIdx == 0) {
        this.params.type = 0;
      } else {
        this.params.type = 2;
      }
      this.clear();
      this.getUserCoinLogsReq();
    },
    reset() {
      let self = this;
      this.typeIdx = 0;
      this.showFilter = false;
      this.params.type = 0;
      this.clear();
      this.getUserCoinLogsReq();
    },
    submit() {
      this.currentTypeDetail = this.typeList[this.typeIdx];
      this.showFilter = false;
      this.params.type = this.currentTypeDetail.id;
      this.clear();
      this.getUserCoinLogsReq();
    },
    selectTime() {
      this.show = true;
    },
    dateChange(val) {
      console.log("dateChange", val);
    },
    tabChange(item, idx) {
      this.tabIdx = idx;
      if (this.tabIdx == 0) {
        this.params.type = 0;
      } else {
        this.params.type = 2;
      }
      this.clear();
      this.getUserCoinLogsReq();
    },
    getUserCoinLogsReq() {
      getUserCoinLogs({
        type: this.params.type,
        offset: this.params.offset,
        limit: this.params.limit,
      })
        .then((res) => {
          console.log("res", res);
          this.loading = false;
          this.refreshing = false;
          if (res && res.data && res.data.list) {
            res.data.list.forEach((billItem) => {
              const isExist = this.billList.find(
                (item) => item.id === billItem.id
              );
              const tempItem = {
                id: billItem.id,
                created_at: billItem.created_at,
                amount: billItem.amount,
                type: billItem.type,
                type_cn: billItem.type_cn,
              };
              if (isExist) {
                return;
              } else {
                this.billList.push(tempItem);
              }
            });
          }

          if (res && res.data && res.data.has_more) {
            this.statusLoadmore = "loadmore";
            this.finished = false;
          } else {
            this.statusLoadmore = "nomore";
            this.finished = true;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.refreshing = false;
          this.finished = true;
          Toast.fail((err && err.msg) || "服务器异常，请稍后重试");
        });
    },
  },
};
</script>

<style lang="less" scoped>
@import url(@/assets/css/tabList.less);
@import url(@/assets/css/popup.less);
.bill-wrap {
  // background: #F8F8FA;
  padding-top: 70px;

  .bill-filter {
    width: 179px;
    height: 46px;
    background: #f3f3f8;
    border-radius: 31px;
    position: relative;
    .icon-img {
      position: absolute;
      right: 10px;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
    }
    img {
      width: 24px;
    }
  }

  .bill-content {
    .bill-info {
    }
    .bill-list {
      .bill-item {
        .bill-type {
          width: 92px;
          height: 92px;
          background: #e0deff;
          border-radius: 100%;
          &.type1 {
            background: #d9e5ff;
          }
        }
      }
    }
  }
}
</style>
