<template>
  <van-popup class="prompt-popup" mode="bottom" :style="{ height: '100%' }"
    v-model="showPopup" :closeable="true"
    @close="closePopup">
    <div class="flex flex-column px-38">
      <div
        class="font-27 text-default font-weight-bolder line-h-38 mt-50">
        工具列表</div>
      <div class="model-list mt-30">
        <div class="model-item mb-20 flex position-relative"
          v-for="(item,idx) in modelsList" :key="idx"
          @click="changeModel(item,idx)">
          <div
            class="model-image flex justify-center align-center">
            <img v-if="typeIdx != idx"
              :src="item.default_image">
            <img v-else :src="item.check_image">
          </div>
          <div
            class="model-info flex flex-column pl-20 flex-1">
            <div
              class="font-27 text-default font-weight-bolder line-h-38 text-left">
              {{item.name}}</div>
            <div
              class="font-23 text-default line-h-32 mt-10 text-left">
              {{item.model_name}}</div>
            <div
              class="mt-20 font-23 text-normal font-weight-bolder line-h-32 text-left">
              模型描述</div>
            <div
              class="mt-10 font-23 text-normal font-weight-bolder line-h-32 text-left">
              {{item.desc}}</div>
          </div>
          <div
            class="model-btn font-23 flex justify-center align-center"
            :class="[typeIdx == idx ? 'text-normal' : 'text-primary active']">
            {{typeIdx == idx ? "当前使用" : "切换"}}</div>
        </div>
      </div>
    </div>
  </van-popup>

</template>

<script>
import { Popup } from "vant";
export default {
  components: {
    VanPopup: Popup,
  },
  props: ["showModalPopup", "typeIdx", "modelsList"],
  data() {
    return {
      showPopup: false,
    };
  },
  created() {
    this.showPopup = this.showModalPopup;
    console.log("modelsList", this.modelsList);
  },
  methods: {
    closePopup() {
      this.$emit("closeModalPopup");
    },
    changeModel(item, idx) {
      this.$emit("changeModal", {
        idx: idx,
        problemIdx: 1,
        item: item,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.model-list {
  .model-item {
    width: 674px;
    background: #f8f8fa;
    border-radius: 19px;
    padding: 24px 38px 30px 38px;
    .model-image {
      width: 92px;
      height: 92px;
      background: #d9e5ff;
      border-radius: 100%;
      img {
        width: 60px;
        height: 60px;
      }
    }

    .model-btn {
      width: 122px;
      height: 53px;
      background: #e7e8ed;
      border-radius: 34px;
      position: absolute;
      top: 42px;
      right: 38px;
      &.active {
        background: #d9e5ff;
        box-shadow: 0px 4px 8px 0px #d9e5ff;
        border: 1px solid #3672fd;
      }
    }
  }
}
</style>

