<template>
  <div class="flex flex-column safe-area-inset-bottom">
	<CommonHeader title="提现记录"></CommonHeader>
    <div class="detail-list">
      <van-pull-refresh v-model="refreshing"
        @refresh="onRefresh">
        <van-list v-model="loading" :offset="1500"
          :finished="finished" finished-text="没有更多了"
          @load="onLoad">
          <div class="detail-item px-38 mt-38 flex"
            v-for="(item,idx) in detailList" :key="idx">
            <div class="pr-36">
              <div
                class="detail-type flex align-center justify-center font-27 text-default">
                提现</div>
            </div>
            <div
              class="text-border-bottom flex-1 flex pb-38">
              <div class="flex-1">
                <div
                  class="font-27 text-default font-weight-bolder line-h-38 mt-10 text-ellipsis">
                  提现金额：¥ {{item.amount / 100}}</div>
                <div
                  class="font-23 text-gray line-h-32 mt-04">
                  {{item.created_at}}</div>
              </div>

              <div class="font-27 flex align-center"
                :style="{color: calColor(item.status)}">
                <!-- <div class="position-relative mr-24" v-if="item.status == 3">
							<u-icon name="warning" color="#FF5959" size="42" @click="showTip = !showTip"></u-icon>
							<div v-if="showTip" class="tips position-absolute font-23 text-red">系统判断为风险账号
							</div>
						</div> -->
                <!-- {{calStatusTip(item.status)}} -->
                {{item.status_cn}}
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <van-empty class="mt-100" v-if="detailList.length == 0"
      text="暂无提现记录"></van-empty>
  </div>
</template>

<script>
import { getWithdrawList } from "@/api/user";
import util from "@/utils/util";
import { Empty, Toast, PullRefresh, List } from "vant";
import CommonHeader from '@/components/header/commonHeader.vue';
export default {
  components: {
    VanEmpty: Empty,
    VanPullRefresh: PullRefresh,
    VanList: List,
	CommonHeader
  },
  data() {
    return {
      detailList: [],
      statusLoadmore: "loadmore",
      current: 1,
      isLoading: true,
      show: false,
      showTip: false,
      params: {
        status: 99,
        pageSize: 0,
        offset: 0,
        limit: 20,
      },
      loading: false,
      finished: false,
      refreshing: false,
      isFirst: true,
    };
  },
  created() {
    util.onLogin().then((res) => {
      if (res) {
        // this.getWithdrawListReq();
      }
    });
  },
  methods: {
    onLoad() {
      console.log("onload");
      if (this.refreshing) {
        this.currentList = [];
        this.refreshing = false;
      }

      this.finished = true;
      if (!this.isFirst) {
        this.params.pageSize += 1;
        this.params.offset = this.params.pageSize * this.params.limit;
      } else {
        this.isFirst = false;
      }

      this.getWithdrawListReq();
    },
    onRefresh() {
      console.log("ref");
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.params.pageSize = 0;
      this.params.offset = 0;
      this.getWithdrawListReq();
    },
    clear() {
      this.params.pageSize = 0;
      this.params.offset = 0;
      this.detailList = [];
    },
    calColor(status) {
      switch (status) {
        case 1:
          return "#1A232F";
          break;
        case 2:
          return "#3672FD";
          break;
        case 3:
          return "#FF5959";
          break;
      }
    },
    calStatusTip(status) {
      switch (status) {
        case 1:
          return "提现成功";
          break;
        case 2:
          return "审核中";
          break;
        case 3:
          return "提现失败";
          break;
      }
    },
    getWithdrawListReq() {
      getWithdrawList({
        status: this.params.status,
        offset: this.params.offset,
        limit: this.params.limit,
      })
        .then((res) => {
          console.log("res", res);
          this.loading = false;
          this.refreshing = false;
          if (res && res.data && res.data.list) {
            res.data.list.forEach((billItem) => {
              const isExist = this.detailList.find(
                (item) => item.id === billItem.id
              );
              const tempItem = {
                id: billItem.id,
                created_at: billItem.created_at,
                amount: billItem.amount,
                status: billItem.status,
                status_cn: billItem.status_cn,
                transfer_at: billItem.transfer_at,
              };
              if (isExist) {
                return;
              } else {
                this.detailList.push(tempItem);
              }
            });
          }

          if (res && res.data && res.data.has_more) {
            this.finished = false;
            this.statusLoadmore = "loadmore";
          } else {
            this.finished = true;
            this.statusLoadmore = "nomore";
          }
        })
        .catch((err) => {
          this.loading = false;
          this.refreshing = false;
          this.finished = true;
          Toast.fail((err && err.msg) || "服务器异常，请稍后重试");
        });
    },
  },
};
</script>

<style lang="less" scoped>
.detail-list {
  .detail-item {
    .detail-type {
      width: 92px;
      height: 92px;
      background: #e0deff;
      border-radius: 100%;

      &.type1 {
        background: #d9e5ff;
      }
    }
  }
}
</style>