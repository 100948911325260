<template>
	<div class="detail-bottom flex justify-between align-center">
		<div class="flex align-center">
			<div class="flex align-center" @click="submitLike(1)">
				<img class="bottom-img" v-if="contentDetail && contentDetail.liked" src="@/assets/images/fav_check.png">
				<img class="bottom-img" v-else src="@/assets/images/fav_default.png">
				<span class="ml-20 font-24 text-normal">{{contentDetail && contentDetail.like || 0}}</span>
			</div>
			<div class="flex align-center ml-40" @click="submitLike(-1)">
				<img class="bottom-img" v-if="contentDetail && contentDetail.unliked" src="@/assets/images/unlike_check.png">
				<img class="bottom-img" v-else src="@/assets/images/unlike_default.png">
				<span class="ml-20 font-24 text-normal">{{contentDetail && contentDetail.unlike || 0}}</span>
			</div>
			<div class="flex align-center ml-40" @click="submitFav()">
				<img class="bottom-img" v-if="contentDetail && contentDetail.faved" src="@/assets/images/star_check.png">
				<img class="bottom-img" v-else src="@/assets/images/star_default.png">
			</div>
		</div>
		<div class="submit-normal-btn" @click="goIndex">我也要问</div>
	</div>
</template>

<script>
	export default {
		props: ["contentDetail"],
		data() {
			return {
				hasFav: false
			}
		},
		methods: {
			submitLike(type) {
				let cancel = false;
				if(this.contentDetail && this.contentDetail.liked && type == 1) {
					cancel = true;
				}
				if(this.contentDetail && this.contentDetail.unliked && type == -1) {
					cancel = true;
				}
				this.$emit("submitLike",{
					type: type,
					cancel: cancel
				});
			},
			submitFav() {
				let fav = 1;
				if(this.contentDetail && this.contentDetail.faved) {
					fav = -1;
				}
				this.$emit("submitFav",fav)
			},
			goIndex() {
				this.$router.push({
					path: "Ai"
				})
			}
		}
	}
</script>

<style lang="less" scoped>
.detail-bottom {
	position: fixed;	
	left: 0;
	right: 0;
	bottom: 0;
	padding: 25px 38px 45px 38px;
	background: rgba(243,243,248,0.8);
	box-shadow: inset 0px 1px 0px 0px #E7E8ED;
	backdrop-filter: blur(10px);
	img {
		width: 36px;
		height: 36px;
	}
}
</style>
