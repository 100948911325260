<template>
  <div class="ip-detail-box ">
    <CommonHeader :title="title" :showOrder="showOrder">
    </CommonHeader>
    <div class="ip-select-layer" v-if="showSelect"
      @click="showSelect = false;"></div>
    <div class="search-warp">
      <div class="search-input">
        <van-search v-model="ipVal" shape="round"
          placeholder="请输入IP名称" @focus="triggerInput"
          @input="triggerInput" />
        <van-icon class="icon-search" name="search"
          @click="beginAnalysis" />
      </div>
      <div class="ip-select" v-if="showSelect">
        <div class="ip-list">
          <div class="ip-item"
            :class="[ipId == item.value ? 'active' : '']"
            v-for="(item,idx) in dropdownOption" :key="idx"
            @click="selectIp(item)">{{item.name}}</div>
        </div>
      </div>
    </div>

    <!-- <div class="bg-img" v-if="ipInfo && ipInfo.cover_img">
            <img :src="ipInfo && ipInfo.cover_img">
        </div> -->
    <div class="market-pay-content px-38">
      <DetailInfo class="mt-20"
        :currentName="ipInfo && ipInfo.name"
        :ipInfo="ipInfo" :allScore="allScore"></DetailInfo>
      <SuggestionCom class="mt-20" :suggestion="ipInfo">
      </SuggestionCom>
      <IpFlow class="mt-20" :flowIndex="flowIndex"
        :ipInfo="ipInfo" :distributionAge="distributionAge"
        :distributionAgeDefault="distributionAgeDefault">
      </IpFlow>
      <IpMarket class="mt-20" :ipMarket="ipMarket">
      </IpMarket>
      <!-- <SalesData class="mt-20"></SalesData> -->
      <CategoryRankList class="mt-20"
        :categoryRankList="categoryRank"></CategoryRankList>
      <IpPrice class="mt-20"
        :distributionRank="distributionRank"></IpPrice>
      <!-- <BrandRank class="mt-20"></BrandRank> -->
      <IpCompare class="mt-20" :compareIp="compareIp">
      </IpCompare>
    </div>

  </div>
</template>
<script>
import CommonHeader from "@/components/header/commonHeader.vue";
import DetailInfo from "./components/detailInfo.vue";
import SuggestionCom from "./components/suggestion.vue";
import SalesData from "./components/salesData.vue";
import BrandRank from "./components/brandRank.vue";
import IpCompare from "./components/ipCompare.vue";
import IpFlow from "./components/ipFlow.vue";
import IpMarket from "./components/ipMarket.vue";
import CategoryRankList from "./components/categoryRank.vue";
import IpPrice from "./components/ipPrice.vue";
import { Search, Icon, Rate, Toast } from "vant";
import { getStatisticsSearchIp, getStatisticsIp } from "@/api/statistics";
export default {
  components: {
    CommonHeader,
    vanSearch: Search,
    vanIcon: Icon,
    vanRate: Rate,
    SalesData,
    BrandRank,
    IpCompare,
    SuggestionCom,
    DetailInfo,
    IpFlow,
    IpMarket,
    CategoryRankList,
    IpPrice,
  },
  data() {
    return {
      title: "IP分析",
      showOrder: false,
      backgroudBg: "transparent",
      ipVal: "",
      ipId: "",
      currentName: "",
      currentDate: new Date(),
      showSelect: false,
      dropdownOption: [],
      total: null,
      distributionRank: [
        {
          name: "销量",
          rankList: [],
        },
        {
          name: "销售额",
          rankList: [],
        },
      ],
      categoryRank: [
        {
          name: "销量",
          rankList: [],
        },
        {
          name: "销售额",
          rankList: [],
        },
      ],
      compareIp: [
        {
          name: "销量",
          rankList: [],
        },
        {
          name: "销售额",
          rankList: [],
        },
      ],
      ipMarket: [
        {
          name: "平台热度",
          rankList: [
            {
              region_name: "头条",
              typeIdx: "point_toutiao_index",
              region_index: 0,
              region_index_trend: 0,
            },
            {
              region_name: "抖音",
              typeIdx: "point_douyin_index",
              region_index: 0,
              region_index_trend: 0,
            },
            {
              region_name: "快手",
              typeIdx: "point_kuaishou_index",
              region_index: 0,
              region_index_trend: 0,
            },
            {
              region_name: "微博",
              typeIdx: "point_weibo_index",
              region_index: 0,
              region_index_trend: 0,
            },
            {
              region_name: "百度",
              typeIdx: "point_search_index",
              region_index: 0,
              region_index_trend: 0,
            },
            {
              region_name: "小红书",
              typeIdx: "point_xiaohongshu_index",
              region_index: 0,
              region_index_trend: 0,
            },
          ],
        },
        {
          name: "地域热度",
          rankList: [],
        },
      ],
      ipInfo: null,
      flowIndex: {
        search_index: 0,
        search_index_trend: 0,
        media_index: 0,
        media_index_trend: 0,
      },
      distributionAge: [],
      distributionAgeDefault: [],
      allScore: [
        {
          name: "覆盖人群",
          type: "point_watched",
          value: 0,
        },
        {
          name: "受众认可",
          type: "point_rate",
          value: 0,
        },
        {
          name: "潜在客户",
          type: "point_star5_people",
          value: 0,
        },
        {
          name: "搜索指数",
          type: "point_search_index",
          value: 0,
        },
        {
          name: "媒体资讯",
          type: "point_media_index",
          value: 0,
        },
      ],
    };
  },
  methods: {
    beginAnalysis() {
      console.log("ipVal", this.ipVal);
      if (!this.ipVal) {
        Toast.fail("请输入ip");
        return;
      }
      if (this.ipVal) {
        let isExist = this.dropdownOption.filter((res) => {
          return res.name == this.ipVal;
        });
        if (isExist && isExist.length > 0) {
          this.ipId = isExist[0].id;
        } else {
          Toast.fail("请选择匹配的ip");
          return;
        }
      }
      this.currentName = this.ipVal;
      this.getStatisticsIpReq();
    },
    getStatisticsIpReq() {
      let year = this.currentDate.getFullYear();
      let month = this.parseNumber(this.currentDate.getMonth() + 1);
      let param = {
        // ym: Number(year + "" + month),
        ip_id: this.ipId,
      };
      getStatisticsIp(param)
        .then((res) => {
          if (res && res.error == 0 && res.data) {
            this.total = res.data.total || null;
            if (res.data && res.data.distribution_price) {
              this.distributionRank[1]["rankList"] =
                res.data.distribution_price || [];
            }
            if (res.data && res.data.distribution_sales) {
              this.distributionRank[0]["rankList"] =
                res.data.distribution_sales || [];
            }
            if (res.data && res.data.category_price) {
              this.categoryRank[1]["rankList"] = res.data.category_price || [];
            }
            if (res.data && res.data.category_sales) {
              this.categoryRank[0]["rankList"] = res.data.category_sales || [];
            }

            if (res.data && res.data.compete_ip_price) {
              this.compareIp[1]["rankList"] = res.data.compete_ip_price || [];
            }
            if (res.data && res.data.compete_ip_sales) {
              this.compareIp[0]["rankList"] = res.data.compete_ip_sales || [];
            }

            this.ipInfo = res.data.ip_info || null;
            if (res.data && res.data.region_index) {
              this.ipMarket[1]["rankList"] = res.data.region_index || [];
            }
            if (res.data && res.data.ip_info) {
              let tempList = this.ipMarket[0]["rankList"];
              for (let i = 0, j = tempList.length; i < j; i++) {
                let item = tempList[i];
                if (this.ipInfo && this.ipInfo[item.typeIdx]) {
                  item["region_index"] = this.ipInfo[item.typeIdx];
                }
                if (this.ipInfo && this.ipInfo[item.typeIdx + "_trend"]) {
                  item["region_index_trend"] =
                    this.ipInfo[item.typeIdx + "_trend"];
                }
              }

              for (let m = 0, n = this.allScore.length; m < n; m++) {
                let scoreItem = this.allScore[m];
                scoreItem["value"] = this.ipInfo[scoreItem.type] || 0;
                this.$set(this.allScore, m, scoreItem);
              }
            }
            this.flowIndex["search_index"] =
              (this.ipInfo && this.ipInfo.search_index) || 0;
            this.flowIndex["search_index_trend"] =
              (this.ipInfo && this.ipInfo.search_index_trend) || 0;
            this.flowIndex["media_index"] =
              (this.ipInfo && this.ipInfo.media_index) || 0;
            this.flowIndex["media_index_trend"] =
              (this.ipInfo && this.ipInfo.media_index_trend) || 0;

            if (res.data && res.data.distribution_age_default) {
              this.distributionAgeDefault =
                res.data.distribution_age_default || [];
            }
            if (res.data && res.data.distribution_age) {
              this.distributionAge = res.data.distribution_age || [];
            }
            this.toTop();
          } else {
            Toast.fail("获取数据失败，请稍后重试");
            this.initDefalutData();
            this.toTop();
          }
        })
        .catch((err) => {
          if (err.error == 20912) {
            this.$router.push("Market");
          } else {
            this.initDefaultData();
            
          }
          this.toTop();
        });
    },
    triggerInput() {
      console.log("this.ipVal", this.ipVal);
      getStatisticsSearchIp({
        q: this.ipVal,
      })
        .then((res) => {
          if (res && res.error == 0) {
            let restaurants = (res && res.data && res.data.list) || [];
            this.dropdownOption = restaurants;
            console.log("this.dropdownOption", this.dropdownOption);
            if (this.dropdownOption && this.dropdownOption.length > 0) {
              this.showSelect = true;
            }
          }
        })
        .catch(() => {
          this.dropdownOption = [];
        });

      if (this.dropdownOption && this.dropdownOption.length > 0) {
        this.showSelect = true;
      }
    },
    selectIp(val) {
      this.ipVal = val.name;
      this.ipId = val.id;
      this.showSelect = false;
    },
    parseNumber(num) {
      return num < 10 ? "0" + num : num;
    },
    initDefaultData() {
      this.total = null;
      this.distributionRank = [
        {
          name: "销量",
          rankList: [],
        },
        {
          name: "销售额",
          rankList: [],
        },
      ];
      this.categoryRank = [
        {
          name: "销量",
          rankList: [],
        },
        {
          name: "销售额",
          rankList: [],
        },
      ];
      this.compareIp = [
        {
          name: "销量",
          rankList: [],
        },
        {
          name: "销售额",
          rankList: [],
        },
      ];
      this.ipMarket = [
        {
          name: "平台热度",
          rankList: [
            {
              region_name: "头条",
              typeIdx: "point_toutiao_index",
              region_index: 0,
              region_index_trend: 0,
            },
            {
              region_name: "抖音",
              typeIdx: "point_douyin_index",
              region_index: 0,
              region_index_trend: 0,
            },
            {
              region_name: "快手",
              typeIdx: "point_kuaishou_index",
              region_index: 0,
              region_index_trend: 0,
            },
            {
              region_name: "微博",
              typeIdx: "point_weibo_index",
              region_index: 0,
              region_index_trend: 0,
            },
            {
              region_name: "百度",
              typeIdx: "point_search_index",
              region_index: 0,
              region_index_trend: 0,
            },
            {
              region_name: "小红书",
              typeIdx: "point_xiaohongshu_index",
              region_index: 0,
              region_index_trend: 0,
            },
          ],
        },
        {
          name: "地域热度",
          rankList: [],
        },
      ];
      this.ipInfo = null;
      this.flowIndex = {
        search_index: 0,
        search_index_trend: 0,
        media_index: 0,
        media_index_trend: 0,
      };
      this.distributionAge = [];
      this.distributionAgeDefault = [];
      this.allScore = [
        {
          name: "覆盖人群",
          type: "point_watched",
          value: 0,
        },
        {
          name: "受众认可",
          type: "point_rate",
          value: 0,
        },
        {
          name: "潜在客户",
          type: "point_star5_people",
          value: 0,
        },
        {
          name: "搜索指数",
          type: "point_search_index",
          value: 0,
        },
        {
          name: "媒体资讯",
          type: "point_media_index",
          value: 0,
        },
      ];
    },
    toTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>
<style lang="less" scoped>
.ip-detail-box {
  padding-top: 110px;
  .ip-select-layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 99;
  }
  .search-warp {
    z-index: 999;
    position: fixed;
    background: transparent;
    left: 0;
    right: 0;
    padding: 0 38px;
    background: #fff;
  }

  .search-input {
    height: 69px;
    background: #fbfbfc;
    box-shadow: 0 4px 8px 0 #e7e8ed;
    border-radius: 69px;
    border: 1px solid #e7e8ed;
    display: flex;

    align-items: center;

    ::v-deep .van-search {
      border-radius: 69px;
      flex: 1;
      width: 100%;
      padding: 0;
      background: transparent;

      .van-search__content {
        background: transparent;
        width: 100%;
        padding-left: 32px;
        font-weight: 400;
        font-size: 27px;
        color: #979db1;
        text-align: left;
        font-style: normal;

        .van-field__left-icon {
          display: none;
        }
      }
    }

    .icon-search {
      padding: 0 30px;
      font-size: 40px;
      color: #979db1;
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  .ip-select {
    position: absolute;
    left: 0;
    right: 0;
    background: #f8f8fa;

    .ip-list {
      width: 100%;
      display: flex;
      flex-direction: column;
      max-height: 600px;
      overflow-y: auto;

      .ip-item {
        width: 100%;
        padding: 20px;
        font-weight: 400;
        font-size: 27px;
        color: #1a232f;
        text-align: center;
        font-style: normal;
        position: relative;

        &.active {
          color: #3672fd;
          font-weight: bold;
        }

        &::after {
          position: absolute;
          box-sizing: border-box;
          content: " ";
          pointer-events: none;
          right: 20px;
          bottom: 0;
          left: 20px;
          border-bottom: 1px solid #ebedf0;
          -webkit-transform: scaleY(0.5);
          transform: scaleY(0.5);
        }
      }
    }
  }

  .bg-img {
    width: 100%;
    position: absolute;
    top: 180px;
    left: 0;
    right: 0;
    z-index: -1;

    img {
      width: 100%;
    }
  }

  .market-pay-content {
    margin-top: 100px;
    position: relative;
  }
}
</style>