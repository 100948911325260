<template>
    <div id="app">
      <router-view />
      <LoginCom v-if="showLogin" @closeLogin="closeLogin">
        </LoginCom>
    </div>
  </template>
  <script>
  import LoginCom from "@/components/login/index.vue";
  import { getToken } from "@/utils/auth";
  export default {
    components: {
        LoginCom
    },
    data() {
      return {
        showLogin: false,
        isLogin: false
      };
    },
    created() {
        this.$bus.$off("showCommonLogin");
        this.$bus.$on("showCommonLogin",()=> {
            this.showLogin = true;
        })
    },
    methods: {
        closeLogin() {
            this.showLogin = false;
            this.isLogin = getToken() ? true : false;
            if (this.isLogin) {
                this.$bus.$emit("initData", true);
            }
        }
    },
  };
  </script>
  <style lang="less" scoped>
  </style>