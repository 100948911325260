<template>
	<div class="problem-box">
		<div class="problem-avatar">
			<img src="https://files.sssrise.com/files/uploads/static/ai_avatar.png">
		</div>
		<div class="problem-info">
			<div class="problem-desc">
				<div class="dhk-arrow"><img style="width: 40px;height: 32px;" src="https://files.sssrise.com/files/uploads/static/dhk_arrow.png"></div>
				<div class="desc" v-if="problemItem && problemItem.name">{{problemItem && problemItem.name}}</div>
				<div v-if="problemItem && problemItem.desc" class="strong">{{problemItem && problemItem.desc}}</div>
				<div v-if="problemItem && problemItem.imageSrc" class=""><img style="max-width:496px;" :src="problemItem.imageSrc"></div>
			</div>
			<div class="problem-list" v-if="problemItem && problemItem.children">
				<div class="problem-item" :class="[problemItem && problemItem.chooseIdx == idx && 'active',problemItem && problemItem.showZhuanFa ? 'problem-item1': '']" v-for="(item,idx) in problemItem.children"
					:key="idx" @click="chooseProblem(item,idx)">{{item.name}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import time from "@/utils/time";
	import { Icon } from "vant";
	export default {
		components: {
			VanIcon: Icon
		},
		name: "probleOne",
		props: ["problemItem","problemIdx","streamStatus"],
		data() {
			return {
			};
		},
		methods: {
			formatTimeVal(val) {
				if(val) {
					return time.formatTime(val);
				} else {
					return ""
				}
				
			},
			chooseProblem(item, idx) {
				console.log("chooseProblem",item)
				this.$emit("changeIdx",{
					problemIdx: this.problemIdx,
					idx: idx
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	@import url(@/assets/css/problemDesc.less);
</style>