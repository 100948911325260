import request from '@/utils/request';

/**
 * 系统配置
 */
export function getDefaultConfig() {
    return request({
        url: '/default/config',
        method: 'get'
    })
}

/**
 * 用户反馈
 * content : 内容
 * image_hashes : array[string]
 * title: 标题
 */
export function feedback(params) {
    return request({
        url: '/default/feedback',
        method: 'post',
        data: params
    })
}

/**
 * 上传文件
 * file : 上传文件
 * hash  : 文件hash值
 * scene : 使用场景 用户/聊天/其他
 */
export function uploadFiles(params){
    return request({
        url: '/default/upload',
        method: 'post',
        data: params
    })
}

/**
 * host控制
 */
export function getDefaultWebview(id) {
    return request({
        url: '/default/webview',
        method: 'get'
    })
}

/**
 * 消息删除
 * id : 消息id
 */
export function deleteMessage(params) {
    return request({
        url: '/message/delete',
        method: 'post',
        data: params
    })
}

/**
 * 根据ids获取多个消息
 * ids  :message ids
 */
export function getMessageByIds(params) {
    return request({
        url: '/message/get?' + params,
        method: 'get',
    })
}

/**
 * 消息点赞/踩
 * cancel： 是否取消赞/踩 默认为false
 * id  : 消息id
 * like   : 喜欢:1 不喜欢:-1
 * reason：
 * type ：post, message
 */
export function messageLike(params) {
    return request({
        url: '/message/like',
        method: 'post',
        data: params
    })
}

/**
 * 消息点赞/踩
 * cancel： 是否取消赞/踩 默认为false
 * id  : 消息id
 * like   : 喜欢:1 不喜欢:-1
 * reason：
 * type ：post, message
 */
export function userLike(params) {
    return request({
        url: '/user/like',
        method: 'post',
        data: params
    })
}

/**
 * 收藏内容
 * fav ： 收藏1 取消收藏-1
 * id  : 消息id
 * type ：post, message
 */
export function userFav(params) {
    return request({
        url: '/user/fav',
        method: 'post',
        data: params
    })
}

/**
 * 微信登录绑定手机号
 * code   : 短信验证码或微信小程序授权码
 * mobile   : 手机号
 * type :类型 0短信 1小程序
 */
export function userBindMobile(params) {
    return request({
        url: '/user/bind_mobile',
        method: 'post',
        data: params
    })
}

/**
 * 获取用户积分日志账单列表
 * type :类型 全部0 支出1 收入2
 * offset
 * limit
 */
export function getUserCoinLogs(params) {
    return request({
        url: '/user/coin_logs',
        method: 'get',
        params: params
    })
}

/**
 * 用户每日签到获取积分
 */
export function userDialyCheck() {
    return request({
        url: '/user/daily_check',
        method: 'post'
    })
}

/**
 * 我收藏的内容列表
 * offset
 * limit
 * type :post, message
 */
export function getUserFav(params){
    return request({
        url: '/user/favs',
        method: 'get',
        params: params
    })
}

/**
 * 当前登录用户 或 其他用户，需要传id
 * id
 */
export function getUserInfo(id) {
	let url = "/user/info";
	if(id) {
		url = "/user/info?id=" + id;
	}
    return request({
        url: url,
        method: 'get'
    })
}

/**
 * 我点赞的内容列表
 * offset
 * limit
 */
export function getUserLikes(params) {
    return request({
        url: "/user/likes",
        method: 'get',
        params: params
    })
}

/**
 * 我发布的内容列表
 * offset
 * limit
 */
export function getUserPosts(params) {
    return request({
        url: "/user/posts",
        method: 'get',
        params: params
    })
}

/**
 * 用户更新个人信息资料
 * avatar_hash:头像上传后hash 
 * desc:介绍
 * gender:性别 1男2女
 * nickname: 昵称
 * real_name: 真实姓名 
 */
export function userUpdateProfile(params) {
    return request({
        url: "/user/update_profile",
        method: 'post',
        data: params
    })
}

/**
 * 用户申请提现
 * amount  :amount
 */
export function withdrawCreate(params){
    return request({
        url: "/withdraw/create",
        method: 'post',
        data: params
    })
}


/**
 * 获取提现详情信息
 * id : 提现id
 */
export function getWithdrawInfo(id) {
    return request({
        url: "/withdraw/info?id=" + id,
        method: 'get'
    })
}

/**
 * 获取提现列表
 * status: 提现状态 全部传99
 * offset
 * limit
 */
export function getWithdrawList(params) {
    return request({
        url: "/withdraw/list",
        method: 'get',
        params: params
    })
}

/**
 * 用户购买数据分类信息
 * offset
 * limit
 */
export function getUserStatisticsCats(params) {
    return request({
        url: "/user/statistics_cats",
        method: 'get',
        params: params
    })
}
