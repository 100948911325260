<template>
  <div class="uploadava">
    <van-popup duration="0" v-model="dialogVisible" closeable 
      position="top" :style="{ height: '100%',background: '#fff' }">
      <div class="cropper-container">
        <vue-cropper ref="cropper" :img="cropperImg"
          :output-size="option.size"
          :output-type="option.outputType" :info="true"
          :full="option.full" :can-move="option.canMove"
          :can-move-box="option.canMoveBox"
          :fixed-box="option.fixedBox"
          :original="option.original"
          :auto-crop="option.autoCrop"
          :auto-crop-width="option.autoCropWidth"
          :auto-crop-height="option.autoCropHeight"
          :center-box="option.centerBox" :high="option.high"
          :info-true="option.infoTrue" @realTime="realTime"
          :enlarge="option.enlarge" :fixed="option.fixed"
          :fixed-number="option.fixedNumber" />
      </div>
      <div class="bottom-opt">
        <van-button class="submit-cropper-btn" type="info" @click="saveImg">确定</van-button>
      </div>
      
    </van-popup>
  </div>

</template>
  
  <script>
import { VueCropper } from "vue-cropper";
import { Popup,Button  } from "vant";
export default {
  name: "Cropper",
  components: {
    VueCropper,
    VanPopup: Popup,
    VanButton: Button
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    imgType: {
      type: String,
      default: "blob",
    },
    cropperImg: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dialogTitle: "裁剪图片",
      previews: {},
      option: {
        img: "", // 裁剪图片的地址
        size: 1, // 裁剪生成图片的质量
        full: false, // 是否输出原图比例的截图 默认false
        outputType: "png", // 裁剪生成图片的格式 默认jpg
        canMove: false, // 上传图片是否可以移动
        fixedBox: false, // 固定截图框大小 不允许改变
        original: false, // 上传图片按照原始比例渲染
        canMoveBox: true, // 截图框能否拖动
        autoCrop: true, // 是否默认生成截图框
        // 只有自动截图开启 宽度高度才生效
        autoCropWidth: 200, // 默认生成截图框宽度
        autoCropHeight: 200, // 默认生成截图框高度
        centerBox: true, // 截图框是否被限制在图片里面
        high: false, // 是否按照设备的dpr 输出等比例图片
        enlarge: 1, // 图片根据截图框输出比例倍数
        mode: "contain", // 图片默认渲染方式
        maxImgSize: 300, // 限制图片最大宽度和高度
        limitMinSize: [100, 120], // 更新裁剪框最小属性
        infoTrue: false, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
        fixed: true, // 是否开启截图框宽高固定比例  (默认:true)
        fixedNumber: [1, 1], // 截图框的宽高比例
      },
    };
  },
  methods: {
    // 裁剪时触发的方法，用于实时预览
    realTime(data) {
      this.previews = data;
    },
    // 重新上传
    uploadBth() {
      this.$emit("update-cropper");
    },
    // 取消关闭弹框
    handleClose() {
      this.$emit("colse-dialog", false);
    },
    confirm() {
      this.saveImg();
    },
    // 获取裁剪之后的图片，默认blob，也可以获取base64的图片
    saveImg() {
      if (this.imgType === "blob") {
        this.$refs.cropper.getCropBlob((data) => {
          this.$emit("upload-img", data);
        });
      } else {
        this.$refs.cropper.getCropData((data) => {
          this.uploadFile = data;
          this.$emit("upload-img", data);
        });
      }
    },
  },
};
</script>
  
  <style lang="less" scoped>
.uploadava {
  position: relative;
  .cropper-container {
    height: 100vh;
    .van-nav-bar {
      background-color: rgba(0, 0, 0, 0.87);
      :global(.van-nav-bar__text) {
        color: #fff;
      }
      :global(.van-nav-bar__text:nth-child(2)) {
        color: #000;
        font-weight: 500;
      }
    }
  }
  .vue-cropper {
    background: #000;
    height: 100%;
    padding-top: 46px;
    box-sizing: border-box;
  }
  .bottom-opt {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
  }
  .submit-cropper-btn {
    
  }
}
</style>