<template>
	<van-popup class="prompt-popup" mode="bottom" v-model="showPopup"  :style="{ height: '100%' }" :closeable="true" @close="closePopup">
		<div class="content">
			<div class="flex flex-column safe-area-inset-bottom">
			<!-- 	<div class="mt-50 flex tab-list justify-center ">
					<div class="tab-item" :class="[tabIdx == idx && 'active']" v-for="(item,idx) in tabList" :key="idx" @click="tabChange(item,idx)">{{item.name}}</div>
				</div> -->
				<div class="content-list flex flex-column px-38 mt-80">
					<div class="content-item flex" v-for="(item,idx) in contentList" :key="idx">
						<div class="flex flex-column flex-1">
							<div class="title-ellipsis-two font-27 text-default line-h-38 flex-1">{{item}}</div>
							<!-- <div v-if="item.desc" class="desc font-23 text-normal line-h-32">{{item.desc}}</div> -->
						</div>
						<div class="flex align-center pl-50">
							<div class="submit-use-btn" @click="submit(item)">使用</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</van-popup>
</template>

<script>
	import { Popup,Toast } from 'vant';
	import {
		getChatTipMessages
	} from "@/api/chat";
	export default {
		props: ["chatItem","showPrompt"],
		components: {
			VanPopup: Popup
		},
		data() {
			return {
				tabList:[{
					name: "知识询问"
				},{
					name: "创意绘画"
				},{
					name: "文学创作"
				}],
				tabIdx: 0,
				statusLoadmore: "loadmore",
				current: 1,
				isLoading: true,
				contentList: [],
				showPopup: false
			}
		},
		created() {
			this.showPopup = this.showPrompt;
			this.getChatTipMessagesReq();
		},
		methods: {
			tabChange(item,idx) {
				this.tabIdx = idx;
			},
			getChatTipMessagesReq() {
				if(this.chatItem && this.chatItem.id) {
					this.contentList = [];
					getChatTipMessages(this.chatItem.id).then((res)=> {
						console.log("res",res)
						this.contentList = res && res.data && res.data.messages || [];
					}).catch(()=> {
						Toast.fail(err && err.msg || "获取推荐数据失败，请稍后重试");
					})
				}
				
			},
			submit(tip) {
				this.$emit("sumbitTip",tip)
			},
			closePopup() {
				this.$emit("closePopup")
			}
		}
	}
</script>

<style lang="less" scoped>
@import url(@/assets/css/tabList.less);
.prompt-popup {
	background: #fff;
}
.content-list {
	.content-item {
		width: 674px;
		background: #F8F8FA;
		border-radius: 19px;
		padding: 20px;
		margin-bottom: 20px;
		&:last-child {
			margin-bottom: 0;
		}
		.content-img {
			img {
				width: 137px;
				height: 137px;
				border-radius: 20px;
			}
		}
		.desc {
			margin: 13px 0 22px 0;
		}
	}
}
</style>
