<template>
    <div class="sales-data-box">
        <div class="rank-title"><span>品牌排行</span></div>
        <SaleTab class="mt-16" :tabList="saleList" :currentType="saleType" @changeTab="changeSaleType"></SaleTab>
        <RankStar class="mt-28" :hideTitle="true" :currentList="topRankStar"></RankStar>
    </div>
</template>
<script>
import AnalysisData from "./analysisData.vue";
import SaleTab from "./saleTab.vue";
import RankStar from "./rankStar.vue";
export default {
    components: {
        AnalysisData,
        RankStar,
        SaleTab
    },
    data() {
        return {
            saleList: [{
                name: "销量",
                type: 1
            },{
                name: "销售额",
                type: 2
            }],
            saleType: 1,
            topRankStar: [{
                name: "销量",
                rankList: [{
                    name: "电动玩具",
                    star: "9.5",
                },{
                    name: "益智玩具",
                    star: "8.1",
                },{
                    name: "绘画玩具",
                    star: "6.1",
                },{
                    name: "积木拼装",
                    star: "3.2",
                },{
                    name: "橡皮泥",
                    star: "1.3",
                }]
            }]
        }
    },
    methods: {
        changeSaleType(type) {
            this.saleType = type;
        }
    }
}
</script>
<style lang="less" scoped>
@import url("@/assets/css/rankTitle.less");
.sales-data-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border-radius: 20px;
    border: 1px solid #E7E8ED;
}
</style>