import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
import Layout from '@/layout/index.vue';
import CommonLayout from '@/layout/commonIndex.vue';
import Market from "@/pages/market/index.vue";
import Creative from "@/pages/creative/index.vue";
import MarkeyPay from "@/pages/market/marketPay.vue";
import MarketAnalysis from "@/pages/market/marketAnalysis.vue";
import IpIndex from "@/pages/market/ipIndex.vue";
import IpDetail from "@/pages/market/ipDetail.vue";
import MarketIdea from "@/pages/market/marketIdea.vue";
import Home from "@/pages/Home.vue";
import Favorite from "@/pages/market/favorite.vue";
import ProductReport from "@/pages/market/productReport.vue";
import Collect from "@/pages/creative/collect.vue";
import User from "@/pages/user/index.vue";
import Content from "@/pages/content/index.vue";
import ContentDetail from "@/pages/content/detail.vue";
import Vip from "@/pages/vip/index.vue";
import Order from "@/pages/order/index.vue";
import Info from "@/pages/user/info.vue";
import Station from "@/pages/station/index.vue";
import Pay from "@/pages/pay/index.vue";
import Bill from "@/pages/bill/index.vue";
import Withdrawal from "@/pages/withdrawal/withdrawal.vue";
import WithdrawalDetail from "@/pages/withdrawal/detail.vue";
import Ai from "@/pages/ai/index.vue";
import Zhuanfa from "@/pages/zhuanfa/index.vue";

let routes = [
  {
    path: '/',
    component: Home,
    name: 'Home',
    meta: { title: '首页' }
  },

  {
    path: '/Home',
    component: Home,
    name: 'Home',
    meta: { title: '首页' }
  },

  {
    path: '/User',
    component: CommonLayout,
    redirect: "",
    hidden: true,
    children: [
      {
        path: '/User',
        component: User,
        name: 'User',
        meta: { title: '个人中心',
        keepAlive: false }
      },
      {
        path: '/Info',
        component: Info,
        name: 'Info',
        meta: { title: '用户信息' }
      },
      {
        path: '/Content',
        component: Content,
        name: 'Content',
        meta: { title: '我的内容' }
      },
      {
        path: '/ContentDetail',
        component: ContentDetail,
        name: 'ContentDetail',
        meta: { title: '内容详情' }
      },
      {
        path: '/Vip',
        component: Vip,
        name: 'Vip',
        meta: { title: '会员中心' }
      },
      {
        path: '/Order',
        component: Order,
        name: 'Order',
        meta: { title: '我的订单' }
      },
      {
        path: '/Station',
        component: Station,
        name: 'Station',
        meta: { title: '积分加油站' }
      },
      {
        path: '/Pay',
        component: Pay,
        name: 'Pay',
        meta: { title: '支付' }
      },
      {
        path: '/Bill',
        component: Bill,
        name: 'Bill',
        meta: {
          title: '我的收入',
          keepAlive: false
        }
      },
      {
        path: '/Withdrawal',
        component: Withdrawal,
        name: 'Withdrawal',
        meta: { title: '提现' }
      },
      {
        path: '/WithdrawalDetail',
        component: WithdrawalDetail,
        name: 'WithdrawalDetail',
        meta: { title: '提现记录' }
      },
      {
        path: '/MarkeyPay',
        component: MarkeyPay,
        name: 'MarkeyPay',
        meta: { title: '购买列表' }
      },
      {
        path: '/MarketAnalysis',
        component: MarketAnalysis,
        name: 'MarketAnalysis',
        meta: { title: '市场综合分析' }
      },
      {
        path: '/IpIndex',
        component: IpIndex,
        name: 'IpIndex',
        meta: { title: 'IP分析' }
      },
      {
        path: '/IpDetail',
        component: IpDetail,
        name: 'IpDetail',
        meta: { title: 'IP详情' }
      },
      {
        path: '/MarketIdea',
        component: MarketIdea,
        name: 'MarketIdea',
        meta: { title: 'IP详情' }
      },
      {
        path: '/Favorite',
        component: Favorite,
        name: 'Favorite',
        meta: { title: '收藏夹' }
      },
      {
        path: '/ProductReport',
        component: ProductReport,
        name: 'ProductReport',
        meta: { title: '产品策略报告' }
      },
      {
        path: '/Collect',
        component: Collect,
        name: 'Collect',
        meta: { title: '征集创意' }
      },
      {
        path: "/Zhuanfa",
        component: Zhuanfa,
        name: 'Zhuanfa',
        meta: { title: '转发',
        keepAlive: false }
      }
    ]
  },
  {
    path: '/Market',
    component: Layout,
    redirect: "",
    hidden: true,
    children: [
      {
        path: '/Market',
        component: Market,
        name: 'Market',
        meta: { title: '市场调研' }
      },
      {
        path: '/Creative',
        component: Creative,
        name: 'Creative',
        meta: { title: '创意市场' }
      },
      {
        path: '/Ai',
        component: Ai,
        name: 'Ai',
        meta: { title: 'AI助手',keepAlive: false }
      },
    ]
  }
]

let routerPush = Router.prototype.push;
let routerReplace = Router.prototype.replace;
// push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}
// replace
Router.prototype.replace = function push(location) {
  return routerReplace.call(this, location).catch(err => err)
}

export default routes;