<template>
  <div class="flex flex-column px-38 my-info">
    <CommonHeader title="用户信息"></CommonHeader>
    <div class="flex justify-center">
      <div class="flex avatar position-relative m-0-auto mt-60">

        <img class="avatar-img" v-if="userInfo && userInfo.avatar" :src="userInfo.avatar">
        <img class="avatar-img" v-else src="https://files.sssrise.com/files/uploads/static/default_avatar.png">
        <div class="icon-phone flex align-center justify-center">
          <img class="camara" src="@/assets/images/camera.png">
        </div>
        <van-uploader class="upload-box" :after-read="afterRead" :max-count="1" :max-size="1 * 1000 * 1024"
          @oversize="onOversize" accept=".png,.jpg" />
      </div>
    </div>

    <div class="flex flex-column info-list mt-60">
      <div class="info-item flex justify-between align-center" @click="modifyName(1)">
        <div class="flex font-27 text-normal  align-center  info-desc">
          <img src="https://files.sssrise.com/files/uploads/static/nicheng@2x.png">昵称
        </div>
        <div class="flex font-27 text-default  align-center">
          {{userInfo && userInfo.nickname || ""}}<van-icon class="ml-20" name="arrow" /></div>
      </div>
      <div class="info-item flex justify-between position-relative align-center">
        <div class="flex font-27 text-normal align-center  info-desc">
          <img src="https://files.sssrise.com/files/uploads/static/phone.png">手机号
        </div>
        <div class="flex font-27 text-default  align-center">
          {{userInfo && userInfo.mobile || ""}}<van-icon class="ml-20" name="arrow" /></div>
      </div>
      <!-- <div class="info-item flex justify-between align-center">
        <div class="flex font-27 text-normal align-center info-desc">
          <img src="https://files.sssrise.com/files/uploads/static/wx_num.png">微信号
        </div>
        <div class="flex font-27 text-default  align-center">
          {{userInfo && userInfo.realname || ""}}<van-icon class="ml-20" name="arrow" /></div>
      </div> -->
      <div class="info-item flex justify-between align-center" @click="modifyName(2)">
        <div class="flex font-27 text-normal  align-center info-desc">
          <img src="https://files.sssrise.com/files/uploads/static/nicheng@2x.png">公司名称
        </div>
        <div class="flex font-27 text-default  align-center info-detail content-end ">
          <div class="one-text">{{userInfo && userInfo.company_name || ""}}</div>
          <van-icon class="ml-20" name="arrow" /></div>
      </div>
      <div class="info-item flex justify-between align-center" @click="modifyName(3)">
        <div class="flex font-27 text-normal  align-center info-desc">
          <img src="https://files.sssrise.com/files/uploads/static/nicheng@2x.png">公司地址
        </div>
        <div class="flex font-27 text-default  align-center info-detail content-end ">
          <div class="one-text">{{userInfo && userInfo.company_address || ""}}</div>
          <van-icon class="ml-20" name="arrow" /></div>
      </div>
      <div class="info-item flex justify-between align-center" @click="modifyName(4)">
        <div class="flex font-27 text-normal  align-center info-desc">
          <img src="https://files.sssrise.com/files/uploads/static/nicheng@2x.png">公司简介
        </div>
        <div class="flex font-27 text-default  align-center info-detail content-end ">
          <div class="three-text company-desc">
            {{userInfo && userInfo.company_desc || ""}}
          </div>
          <van-icon class="ml-20" name="arrow" /></div>
      </div>
    </div>
    <van-dialog v-model:show="show" :title="dialogTitle" show-cancel-button @confirm="confirm">
      <div class="slot-content">
        <div class="modify-username" style="margin: 10px 20px;">
          <van-field v-model="currentName" :label="label" :placeholder="placeholder" :type="type" />
        </div>

      </div>
    </van-dialog>
  </div>
</template>

<script>
  import { uploadFiles, userUpdateProfile } from "@/api/user";
  import util from "@/utils/util";
  import CryptoJS from "crypto-js";
  import env from "@/utils/env";
  const baseURL = process.env.VUE_APP_BASE_API;
  const maxSize = 1 * 1024 * 1024;
  import CommonHeader from "@/components/header/commonHeader.vue";
  import { Icon, Toast, Dialog, Field, Uploader } from "vant";
  import { getToken } from "@/utils/auth";
  export default {
    components: {
      CommonHeader,
      VanIcon: Icon,
      [Dialog.Component.name]: Dialog.Component,
      VanField: Field,
      VanUploader: Uploader,
    },
    data() {
      return {
        imgPaths: null,
        imageUrl: null,
        userInfo: null,
        action: baseURL + "/default/upload",
        headers: {
          "X-SCENE": "",
          "X-APP": env.defaultConfig.App,
          "X-P": env.defaultConfig.Os,
          "X-UUID": env.defaultConfig.Uuid,
          "X-V": env.defaultConfig.AppVersion,
          "X-NV": env.defaultConfig.NativeVersion,
          "X-TS": new Date().getTime(),
          Authorization: `Bearer ${getToken()}`,
          "X-S": "",
        },
        show: false,
        currentName: "",
        fileList: [],
        placeholder: "请输入昵称",
        label: "昵称",
        dialogTitle: "修改昵称",
        currentType: 0,
        type: 'text'
      };
    },
    created() {
      util.onLogin().then((res) => {
        if (!res) {
          this.$bus.$emit("openLogin");
        } else {
          this.getUserInfo();
        }
      });
    },
    methods: {
      getUserInfo() {
        this.$store
          .dispatch("GetInfo", "")
          .then((res) => {
            this.userInfo = res;
            console.log("userInfo", this.userInfo);
          })
          .catch((err) => {
            Toast.fail((err && err.msg) || "服务器出错，请稍后重试");
          });
      },
      onOversize(file) {
        console.log(file);
        Toast('文件大小不能超过 1M');
      },
      afterRead(file) {
        file.status = 'uploading';
        file.message = '上传中...';
        console.log("file", file)
        this.handleFileUpload(file)
      },
      handleFileUpload(file) {
        let self = this;
        console.log("file", file)
        let reader = new FileReader();
        reader.onload = function (e) {
          let binaryString = e.target.result;
          let md5 = CryptoJS.MD5(binaryString).toString();
          console.log('MD5 Hash:', md5);
          // let wordArray = CryptoJS.lib.WordArray.create(file.file);
          // let hash = CryptoJS.MD5(wordArray).toString();
          // console.log("wordArray", wordArray, 'File MD5 Hash:', hash);//上传文件和哈希值
          let param = {
            file: file.file,
            hash: md5,
            scene: "user"
          }
          uploadFiles(param).then((res) => {
            self.fileList = [];
            if (res && res.error == 0) {
              userUpdateProfile({
                avatar_hash: res.data.hash
              }).then((resUpdate) => {
                console.log("userUpdateProfile", resUpdate)
                if (resUpdate && resUpdate.error == 0) {
                  Toast.success("上传头像成功");
                  self.getUserInfo();
                } else {
                  Toast.fail(resUpdate && resUpdate.msg || "上传头像失败，请稍后重试");
                }

              }).catch((errUpdate) => {
                Toast.fail(errUpdate && errUpdate.msg || "上传头像失败，请稍后重试");
              })
            } else {
              Toast.fail(err && err.msg || "上传头像失败，请稍后重试");
            }
          }).catch((err) => {
            self.fileList = [];
            Toast.fail(err && err.msg || "上传头像失败，请稍后重试");
          })
        };
        reader.readAsBinaryString(file.file);

      },
      modifyName(type) {
        this.currentType = type;
        switch (type) {
          case 1:
            this.placeholder = "请输入昵称";
            this.label = "昵称";
            this.dialogTitle = "修改昵称";
            this.currentName = (this.userInfo && this.userInfo.nickname) || "";
            this.type = "text";
            break;
          case 2:
            this.placeholder = "请输入公司名称";
            this.label = "公司名称";
            this.dialogTitle = "修改公司名称";
            this.currentName = (this.userInfo && this.userInfo.company_name) || "";
            this.type = "text";
            break;
          case 3:
            this.placeholder = "请输入公司地址";
            this.label = "公司地址";
            this.dialogTitle = "修改公司地址";
            this.currentName = (this.userInfo && this.userInfo.company_address) || "";
            this.type = "text";
            break;
          case 4:
            this.placeholder = "请输入公司简介";
            this.label = "公司简介";
            this.dialogTitle = "修改公司简介";
            this.currentName = (this.userInfo && this.userInfo.company_desc) || "";
            this.type = "textarea";
            break;
        }

        this.show = true;
      },
      confirm() {
        console.log("confirm");
        if (!this.currentName) {
          Toast.fail(this.label);
          return;
        }
        let param = {

        }
        switch (this.currentType) {
          case 1:
            param = {
              nickname: this.currentName
            }
            break;
          case 2:
            param = {
              company_name: this.currentName
            }
            break;
          case 3:
            param = {
              company_address: this.currentName
            }
            break;
          case 4:
            param = {
              company_desc: this.currentName
            }
            break;
        }
        userUpdateProfile(param)
          .then((res) => {
            console.log("userUpdateProfile", res);
            Toast.success(this.dialogTitle + "成功");
            this.show = false;
            localStorage.setItem("isReload", 1);
            this.getUserInfo();
          })
          .catch((err) => {
            this.show = false;
            Toast.fail((err && err.msg) || this.dialogTitle + "失败，请稍后重试");
          });
      },
      cancel() {
        this.show = false;
      },
    },
  };
</script>

<style lang="less" scoped>
  .my-info {
    padding-top: 110px;

    .company-desc {
        word-break: break-all;
        line-height: 32px;
        text-align: left;
      }
    .avatar {
      width: 168px;
      height: 168px;
      background: #d8d8d8;
      border-radius: 100%;
      box-shadow: 0px 4px 8px 0px #d9e5ff;
      position: relative;

      .avatar-img {
        width: 168px;
        height: 168px;
        border-radius: 100%;
        margin: 0 auto;
      }


      .icon-phone {
        width: 46px;
        height: 46px;
        background: #d9e5ff;
        box-shadow: 0px 4px 8px 0px #d9e5ff;
        border: 1px solid #3672fd;
        border-radius: 100%;
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 1;
      }

      .camara {
        width: 24px;
        height: 24px;
      }

      .upload-box {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;

        ::v-deep .van-uploader__wrapper {
          height: 100%;

          .van-uploader__upload {
            background: transparent;
            width: 100%;
            height: 100%;
            margin: 0;

            i {
              display: none;
            }
          }
        }
      }
    }

    .info-list {
      width: 674px;
      padding: 50px 0;
      background: #f8f8fa;
      border-radius: 19px;

      .info-item {
        padding: 0 40px;
        height: 60px;
        line-height: 60px;
        margin-bottom: 40px;
        .info-desc {
          width: 200px;
        }

        .info-detail {
          width: 400px;
        }

        img {
          width: 46px;
          height: 46px;
          margin-right: 36px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .phone-btn {
      background: transparent;

      &::after {
        display: none;
      }
    }

    .modify-username {
      color: #333;

      ::v-deep .u-input__input {
        color: #333;
      }
    }
  }
</style>