<template>
    <div class="analysis-info">
      <div class="analysis-item">
        <div class="item-left">
          <div class="name">需求指数</div>
          <div class="count">
            <!-- {{total && total.total_sales || 0}} -->
            <CountTo :startVal='0' :endVal='flowIndex && flowIndex.search_index || 0' />
            <!-- <div class="unit">万<span
                class="unit-puls">+</span></div> -->
          </div>
        </div>
        <div class="item-right">
          <div class="right-info">
            <div class="percent"
              :class="[flowIndex && flowIndex.search_index_trend > 0 ? 'up' : 'down']">
              {{flowIndex && flowIndex.search_index_trend || 0}}%</div>
            <div class="per-img">
              <img v-if="flowIndex && flowIndex.search_index_trend > 0"
                src="@/assets/images/market/icon_up.png">
              <img v-else
                src="@/assets/images/market/icon_down.png">
            </div>
          </div>
  
        </div>
      </div>
      <div class="analysis-item">
        <div class="item-left">
          <div class="name">媒体关注指数</div>
          <div class="count">
            <!-- {{total && total.total_price || 0}} -->
            <CountTo :startVal='0' :endVal='flowIndex && flowIndex.media_index || 0' />
            <!-- <div class="unit">万<span
                class="unit-puls">+</span></div> -->
          </div>
        </div>
        <div class="item-right">
          <div class="right-info">
            <div class="percent"
              :class="[flowIndex && flowIndex.media_index_trend > 0 ? 'up' : 'down']">
              {{flowIndex && flowIndex.media_index_trend || 0}}%</div>
            <div class="per-img">
              <img v-if="flowIndex && flowIndex.media_index_trend > 0"
                src="@/assets/images/market/icon_up.png">
              <img v-else
                src="@/assets/images/market/icon_down.png">
            </div>
          </div>
  
        </div>
      </div>
    </div>
  </template>
  <script>
  import CountTo from "vue-count-to";
  export default {
    props: ["flowIndex"],
    components: {
      CountTo,
    },
    data() {
      return {
        analysisList: [
          {
            name: "销量",
            count: 4,
            percent: "30",
          },
          {
            name: "销售额",
            count: 400,
            percent: "-20",
          },
        ],
      };
    },
    created() {
      if(this.currentAnalysisList) {
        this.analysisList = this.currentAnalysisList;
      }
    }
  };
  </script>
  <style lang="less" scoped>
  .analysis-info {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    .analysis-item {
      width: 48%;
      display: flex;
      background: #e0deff;
      border-radius: 20px;
      padding: 14px 30px;
      .item-left {
        flex: 1;
        display: flex;
        flex-direction: column;
        .name {
          font-weight: 400;
          font-size: 23px;
          color: #626a83;
          line-height: 32px;
          text-align: left;
        }
        .count {
          font-weight: bold;
          font-size: 30px;
          color: #1a232f;
          text-align: left;
          display: flex;
          margin-top: 8px;
          align-items: center;
          .unit {
            font-weight: bold;
            font-size: 32px;
            color: #1a232f;
            text-align: left;
            display: flex;
            align-items: flex-end;
          }
          .unit-puls {
            font-weight: bold;
            font-size: 26px;
            color: #1a232f;
            text-align: left;
          }
        }
      }
      .item-right {
        display: flex;
        align-items: flex-end;
        .right-info {
          display: flex;
          align-items: flex-end;
        }
        .up {
          font-weight: 400;
          font-size: 24px;
          color: #e6182e;
          line-height: 32px;
          text-align: left;
        }
        .down {
          font-weight: 400;
          font-size: 24px;
          color: #13b918;
          line-height: 32px;
          text-align: left;
        }
        .per-img {
          display: flex;
          align-items: flex-end;
          padding-bottom: 6px;
        }
        img {
          width: 16px;
          height: 20px;
          margin-left: 12px;
        }
      }
    }
  }
  </style>