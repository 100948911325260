<template>
  <div class="market-pay-list">
    <div class="market-pay-item"
      v-for="(item,idx) in productList" :key="idx"
      @click="showPay = !showPay">
      <div class="pay-left">
        <div class="name">{{item.name}}</div>
        <div class="desc">{{item.desc}}</div>
        <div class="delay">剩余时间：{{item.remainTime}}天
        </div>
        <div class="pay-info">
          <div class="discount"><span
              class="unit">¥</span>{{item.discount}}</div>
          <div class="count"><span
              class="unit">¥</span>{{item.count}}</div>
          <div class="label">
            {{calDiscount(item.discount,item.count)}}
          </div>
        </div>
      </div>
      <div class="market-opt">
        <div class="pay-btn">购买</div>
      </div>
      
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      productList: [
        {
          name: "季度版",
          desc: "品类市场大势分析",
          remainTime: "60",
          discount: 40,
          count: 50,
        },
        {
          name: "半年版",
          desc: "品类市场大势分析",
          remainTime: "60",
          discount: 40,
          count: 50,
        },
        {
          name: "年度版",
          desc: "品类市场大势分析",
          remainTime: "60",
          discount: 40,
          count: 50,
        },
      ],
      showPay: false,
    };
  },
  methods: {
    calDiscount(discount, count) {
      return (
        parseFloat((Number(discount) / Number(count)) * 10).toFixed(0) + "折"
      );
    },
    closePay() {
      this.showPay = false;
    },
    closeMarketPay() {
      this.$emit("closeMarketPay");
    },
  },
};
</script>
<style lang="less" scoped>
.market-pay-list {
  display: flex;
  flex-direction: column;
  .market-pay-item {
    display: flex;
    padding: 34px 38px 38px 38px;
    margin-bottom: 20px;
    width: 100%;
    background: #f8f8fa;
    border-radius: 20px;
    cursor: pointer;
    .pay-left {
      flex: 1;
    }
    .name {
      font-weight: bold;
      font-size: 32px;
      color: #1a232f;
      line-height: 46px;
      text-align: left;
    }
    .desc {
      font-weight: 400;
      font-size: 27px;
      color: #626a83;
      line-height: 38px;
      text-align: left;
      margin-top: 6px;
    }
    .delay {
      font-weight: 400;
      font-size: 27px;
      color: #979db1;
      line-height: 38px;
      text-align: left;
      margin-top: 12px;
    }
    .pay-info {
      display: flex;
      margin-top: 12px;
      align-items: center;
      .unit {
        font-size: 26px;
      }
      .discount {
        font-weight: bold;
        font-size: 38px;
        color: #3672fd;
        line-height: 53px;
        text-align: left;
      }
      .count {
        font-weight: 400;
        font-size: 26px;
        color: #979db1;
        line-height: 20px;
        text-align: left;
        text-decoration-line: line-through;
        margin-left: 12px;
      }

      .label {
        padding: 0 16px;
        height: 32px;
        background: #ffd8d8;
        border-radius: 16px 16px 16px 0px;
        border: 1px solid #ff5959;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 6px;
        font-size: 24px;
        color: #ff5959;
      }
    }
    .market-opt {
      display: flex;
      align-items: flex-end;
      .pay-btn {
        width: 122px;
        height: 53px;
        background: #3672fd;
        box-shadow: 0 4px 8px 0 #d9e5ff;
        border-radius: 34px;
        font-weight: 400;
        font-size: 24px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
      }
    }
  }
}
</style>