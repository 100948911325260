<template>
  <div class="edit-content-box">
    <div class="edit-content">
      <div class="flex justify-between">
        <!-- <div class="content-type-list flex"
          v-if="currentTypeItem && currentTypeItem.name">
          <div
            class="content-type-item flex justify-center align-center active font-24">
            {{currentTypeItem && currentTypeItem.name || ""}}
          </div>
        </div> -->
        <div class="flex content-end flex-1">
          <div class="current-img-item position-relative"
            v-if="defaultImg">
            <img class="current-img" mode="heightFix"
              :src="defaultImg">
            <img class="icon-del"
              src="https://files.sssrise.com/files/uploads/static/icon_del.png"
              @click="del">
          </div>
        </div>
      </div>
      <div class="ask-box ask-box1 mt-20">
        <div class="ask-input">
          <input v-model="value" placeholder="在这里输入你的问题"
            type="text" />
        </div>
        <div class="ask-opt">
          <img
            src="https://files.sssrise.com/files/uploads/static/pic.png"
            @click="showImgBox = !showImgBox"
            v-if="chatItem && (chatItem.type == 3 || chatItem.type == 4 || chatItem.type == 5)">
          <img
            src="https://files.sssrise.com/files/uploads/static/fasong.png"
            @click="showContentEditFun">
        </div>
      </div>

      <div class="img-list flex flex-wrap"
        v-if="showImgBox">
        <div
          class="img-item first-item flex align-center justify-center position-relative">
          <img
            src="https://files.sssrise.com/files/uploads/static/icon_paizhao.png">
          <van-uploader
            class="position-absolute top-0 right-0 left-0 bottom-0"
            :max-count="1"
            :before-read="beforeRead"
            :max-size="1 * 1000 * 1024"
            @oversize="onOversize" accept=".png,.jpg">
          </van-uploader>
        </div>
        <!-- <van-uploader
          class="img-item first-item flex align-center justify-center"
          :after-read="afterRead" :max-count="1"
          :before-read="beforeRead"
          :max-size="1 * 1000 * 1024" @oversize="onOversize"
          accept=".png,.jpg">
          <img
            src="https://files.sssrise.com/files/uploads/static/icon_paizhao.png">
        </van-uploader> -->
        <div class="img-item position-relative"
          :class="[currentIdx == idx ? 'active' : '']"
          v-for="(item,idx) in imgList" :key="idx"
          @click="chooseImg(item,idx)">
          <img class="img-src" :src="item.url">
          <img class="img-check position-absolute"
            v-if="currentIdx == idx"
            src="https://files.sssrise.com/files/uploads/static/img_check.png">
        </div>
      </div>
    </div>

    <cropper-com v-if="showCropper"
      :dialog-visible="showCropper"
      :cropper-img="cropperImg" @colse-dialog="closeDialog"
      @upload-img="uploadImg"></cropper-com>
  </div>
</template>

<script>
import CropperCom from "./cropper.vue";
import { uploadFiles } from "@/api/user";
import CryptoJS from "crypto-js";
// 图片最大1M
const maxSize = 1 * 1024 * 1024;
import { Toast, Uploader } from "vant";
export default {
  name: "editContent",
  props: ["chatItem", "currentTypeItem"],
  components: {
    CropperCom,
    VanUploader: Uploader,
  },
  data() {
    return {
      typeList: [
        {
          id: 1,
          name: "文生图",
        },
        {
          id: 2,
          name: "图生图",
        },
      ],
      value: "",
      typeIdx: 0,
      imgList: [],
      defaultImg: "",
      currentIdx: -1,
      isRecording: false,
      formData: {},
      imageValue: [],
      file: "",
      currentHash: "",
      showImgBox: false,
      imageUrl: "",
      cropperImg: "", // 需要裁剪的图片
      showCropper: false, // 是否显示裁剪框
      uploadFile: "", // 裁剪后的文件
      resultImg: "", // 上传成功，后台返回的路径
      fileList: [],
      imageFileName: "",
      imgType: "",
    };
  },
  created() {
    this.$bus.$off("clearText");
    this.$bus.$on("clearText", (data) => {
      this.value = "";
      this.defaultImg = "";
      this.currentHash = "";
    });
    this.$bus.$off("initDefault");
    this.$bus.$on("initDefault", (data) => {
      console.log("initDefault", data);
      this.value = data.desc || "";

      if (data && data.imageSrc && data.hash) {
        this.defaultImg = data.imageSrc || "";
        this.currentHash = data.hash || "";
        this.imgList.push({
          hash: data.hash,
          url: data.imageSrc,
        });
      }
    });
  },
  methods: {
    beforeRead(file, detail) {
      console.log("file",file,"detail",detail)
      // this.imageToBase64(file);
      this.imageFileName = file.name;
      this.imgType = this.imageFileName.substring(
        this.imageFileName.lastIndexOf(".") + 1,
        this.imageFileName.length
      );
      // this.showCropper = true;
      this.fileList = [];
      var files = file;
      let isLt5M = files.size > (1 << 20);
      if (isLt5M) {
          Toast.fail('请上传1M内的图片');
          return false;
      }
      var reader = new FileReader();
      reader.onload = e => {
          let data;
          if (typeof e.target.result === 'object') {
              // 把Array Buffer转化为blob 如果是base64不需要
              data = window.URL.createObjectURL(new Blob([e.target.result]));
          } else {
              data = e.target.result;
          }
          this.cropperImg = data;
          console.log("cropperImg",this.cropperImg)
      };
      // 转化为base64
      // reader.readAsDataURL(file)
      // 转化为blob
      reader.readAsArrayBuffer(files);
      this.showCropper = true;
    },
    imageToBase64(file) {
      let reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = () => {
        this.cropperImg = reader.result;
        console.log("cropperImg",this.cropperImg)
      };
    },
    onOversize(file) {
      console.log(file);
      Toast("文件大小不能超过 1M");
    },
    afterRead(file) {
      // file.status = "uploading";
      // file.message = "上传中...";
      // console.log("file", file);
      // this.handleFileUpload(file);
    },
    handleFileUpload(file) {
      let self = this;
      console.log("file", file);
      let reader = new FileReader();
      reader.onload = function (e) {
        let binaryString = e.target.result;
        let md5 = CryptoJS.MD5(binaryString).toString();
        console.log("MD5 Hash:", md5);
        let param = {
          file: file,
          hash: md5,
          scene: "chat",
        };
        uploadFiles(param)
          .then((res) => {
            self.fileList = [];
            if (res && res.error == 0) {
              self.imgList.push(res.data);
              self.defaultImg = res.data.url;
              self.currentHash = res.data.hash + "." + self.imgType;
              self.currentIdx = self.imgList.length - 1;
              self.showCropper = false;
            } else {
              Toast.fail((err && err.msg) || "上传图片失败，请稍后重试");
            }
          })
          .catch((err) => {
            self.fileList = [];
            Toast.fail((err && err.msg) || "上传图片失败，请稍后重试");
          });
      };
      reader.readAsBinaryString(file);
    },
    closeDialog() {
      this.showCropper = false;
    },
    uploadImg(blob) {
      this.uploadFile = blob;
      let file = new File([blob], this.imageFileName, { type: blob.type })
      this.handleFileUpload(file);
    },
    chooseType(item, idx) {
      this.typeIdx = idx;
    },
    del() {
      this.defaultImg = "";
      this.currentHash = "";
      this.currentIdx = -1;
    },
    chooseImg(item, idx) {
      this.currentIdx = idx;
      this.currentHash = item.hash;
      this.defaultImg = item.url;
    },
    showContentEditFun() {
      if (!this.value) {
        Toast.fail(`请输入问题`);
        return;
      }
      if (
        !this.currentHash &&
        this.chatItem &&
        (this.chatItem.type == 3 ||
          this.chatItem.type == 4 ||
          this.chatItem.type == 5)
      ) {
        Toast.fail(`请上传图片`);
        return;
      }
      let param = {};
      param["text"] = this.value;
      param["image"] = this.currentHash;
      param["imageSrc"] = this.defaultImg;
      console.log("param", param);

      // this.value = "";
      // this.currentHash = "";
      // this.defaultImg = "";
      // this.currentIdx = -1;
      this.$emit("hideContentEditFun", param);
    },
    closePopup() {
      this.$emit("closePopup");
    },
    onSuccess(val) {
      console.log("onSuccess", val);
    },
  },
};
</script>

<style lang="less" scoped>
@import url(@/assets/css/ask.less);

.edit-content-box {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 99;
  // top: 0;
  background: transparent;

  .edit-content {
    background: rgba(243, 243, 248, 0.8);
    box-shadow: inset 0px 1px 0px 0px #e7e8ed;
    backdrop-filter: blur(10px);
    padding: 20px 30px 30px 30px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .content-type-list {
    .content-type-item {
      // width: 137rpx;
      padding: 0 10px;
      height: 46px;
      background: #ffffff;
      box-shadow: 0px 4px 8px 0px #e7e8ed;
      border-radius: 10px;
      border: 1px solid #e7e8ed;
      font-weight: 400;
      font-size: 23px;
      color: #626a83;
      margin-right: 20rpx;

      &.active {
        background: #d9e5ff;
        box-shadow: 0px 4px 8px 0px #d9e5ff;
        border: 1px solid #3672fd;
        color: #3672fd;
        font-weight: bolder;
      }
    }
  }

  .current-img-item {
    .current-img {
      // width: 60rpx;
      height: 60px;
    }

    .icon-del {
      width: 46px;
      height: 46px;
      position: absolute;
      top: -20px;
      right: -20px;
    }
  }

  .img-list {
    .img-item {
      margin-top: 30px;
      width: 122px;
      height: 122px;
      margin-right: 30px;
      border-radius: 20px;

      ::v-deep .van-uploader {
        position: absolute;
        .van-uploader__wrapper {
          height: 100%;
          .van-uploader__upload-icon {
            display: none;
          }
        }
        .van-uploader__upload {
          width: 100%;
          height: 100%;
          margin: 0;
          background: transparent;
        }
      }

      &:nth-child(4n) {
        margin-right: 0;
      }

      &.first-item {
        background: #e7e8ed;
        border: 1px solid #cccfda;

        img {
          width: 46px;
          height: 46px;
          border-radius: inherit;
        }
      }

      &.active {
        border: 4px solid #3672fd;
      }

      .img-src {
        width: 100%;
        height: 100%;
      }

      .img-check {
        width: 35px;
        height: 35px;
        top: -4px;
        right: -4px;
      }
    }
  }
}
</style>