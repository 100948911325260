<template>
	<div class="flex flex-column pb-200 zhuanfa-detail-box px-56 bg-white" >
		<CommonHeader title="转发"></CommonHeader>
		<div class="mt-20 font-32 line-h-46 text-default font-weight-bolder text-left px-38">【{{name}}】{{problemList && problemList.length > 0 && problemList[0].msg && problemList[0].msg.text ? problemList[0].msg.text.substring(0,9) : ""}}</div>
		<div class="title-border mt-20"></div>
		<div class="problem-wrap mt-10 px-38" v-for="(item,idx) in problemList" :key="idx">
			<DetailLeft v-if="item.cat == 1" :problemItem="item" :problemIdx="idx"></DetailLeft>
			<DetailRight v-if="item.cat == 0" :problemItem="item" :problemIdx="idx"></DetailRight>
		</div>
		<div class="zhuanfa-detail-popup">
			<!-- <div class="font-27 text-default line-h-38 font-weight-bolder px-38">选择发布频道</div>
			<div class="flex flex-wrap px-38 ">
				<div class="type-item flex align-center justify-center mt-20" :class="[typeIdx == idx ? 'active' : '']" 
				v-for="(item,idx) in typeList" :key="idx" @click="chooseIdx(item,idx)">{{item.name}}</div>
			</div>
			<div class="mt-40 px-38 pt-20 pb-20 position-relative">
				<div class="flex justify-between">
					<div class="font-27 text-default font-weight-bolder"><text>设定查看权限</text><text class="font-22 font-weight-normal">（可选）</text></div>
				</div>
				<div class="flex justify-between mt-20">
					<div class="flex align-center">
						<span class="font-27 text-default line-h-38 mr-20">积分</sp>
						<div><input placeholder="可填写范围0-100" v-model="point" :type="type"  /></div>
					</div>
					<div class="flex align-center">
						<span class="font-27 text-default line-h-38 mr-20">¥</span>
						<div><input placeholder="可填写范围0-100" v-model="money" :type="type"  /></div>
					</div>
				</div>
				<div class="lock-box flex flex-column align-center">
					<img class="suo" src="https://files.sssrise.com/files/uploads/static/suo.png">
					<div class="font-23 line-h-32 text-white mt-20">达到100个赞即可开启查看权限模式</div>
				</div>
			</div> -->
			<div class="flex justify-end px-38">
				<div class="flex align-center">
					<span class="font-23 text-default mr-20">需消耗20积分</span>
					<div class="submit-normal-btn" @click="submitContent">发布</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import DetailLeft from "@/components/market/detailLeft.vue";
	import DetailRight from "@/components/market/detailRight.vue";
	import { getMessageByIds } from "@/api/user";
	import { postCreate } from "@/api/content";
	import util from "@/utils/util";
	import qs from "qs";
    import { Toast } from "vant";
	import CommonHeader from "@/components/header/commonHeader.vue";
	export default {
		components: {
			DetailLeft,
			DetailRight,
			CommonHeader
		},
		data() {
			return {
				problemList: [],
				showPay: true,
				showFavPopup: false,
				typeList: [{
					id: 1,
					name: "创意市场"
				},{
					id: 2,
					name: "文学巨匠"
				},{
					id: 3,
					name: "绘画天才"
				}],
				typeIdx: 0,
				point: "",
				money: "",
				type: "number",
				chatId: "",
				messageIds: "",
				name: "",
				userInfo: null
			}
		},
		created() {
			util.onLogin().then((res) => {
				if (!res) {
					this.$bus.$emit("openLogin");
				} else {
					let options = this.$route.query;
					this.chatId = options && options.chatId || "";
					console.log("options.messageIds",options.messageIds)
					this.messageIds = options && options.messageIds ? decodeURI(options.messageIds): "";
					this.name = options && options.name ? decodeURI(options.name) : "";
					this.getUserInfo();
					this.getMessageByIdsReq(this.messageIds)
				}
			});
			
		},
		methods: {
			submitPay() {
				this.showPay = false;
			},
			submitFav() {
				this.showFavPopup = true;
			},
			chooseReason() {
				this.showFavPopup = false;
			},
			chooseIdx(item,idx) {
				this.typeIdx = idx;
			},
			getMessageByIdsReq(ids) {
				this.problemList = [];
				let params = "";
				if(ids && ids.length > 0) {
					ids = ids.split(",");
					console.log("ids",ids)
					for(let i = 0,j=ids.length;i<j;i++) {
						params +="&ids=" + ids[i];
					}
					params = params.substring(1,params.length)
				}
				
				getMessageByIds(params).then((res)=> {
					let tempList = res && res.data && res.data.list || [];
					if(tempList && tempList.length >0) {
						console.log("tempList",tempList)
						this.problemList = tempList;
					}
				}).catch((err)=> {
					Toast.fail(err && err.msg || "服务器异常，请稍后重试");
				})
			},
			submitContent() {
					
				if(this.userInfo.coin < 20) {
					this.$router.push({
						path: "/Station?from=zhuanfa&chatId=" + this.chatId + "&messageIds="+ encodeURI(this.messageIds) +"&name=" + encodeURI(this.name),
					});
					return;
				}
				
				let param = {
					message_ids: this.messageIds.split(","),
					channel: "market",
					title: "【"+this.name+"】"  +(this.problemList && this.problemList[0].msg && this.problemList[0].msg.text && this.problemList[0].msg.text.substring(0,9) || ""),
					coin: 20
				}
		
				let paramStr = qs.stringify(param,{ indices: false });
				postCreate(paramStr).then((res)=> {
					console.log("res",res);
					if(res && res.error == 0) {
						localStorage.setItem("isReload",1);
						this.$router.replace({
							path: "/Creative"
						})
						
					} else {
						Toast.fail(res && res.msg || "服务器异常，请稍后重试");
					}
				}).catch((err)=> {
					Toast.fail(err && err.msg || "服务器异常，请稍后重试");
				})
			},
			getUserInfo() {
				this.$store
                    .dispatch("GetInfo", "")
                    .then((res) => {
                        this.userInfo = res;
                        console.log("userInfo", this.userInfo)
                    })
                    .catch((err) => {
                        Toast.fail((err && err.msg) || "服务器出错，请稍后重试");
                    });
			},
		}
	}
</script>

<style lang="less" scoped>
.zhuanfa-detail-box {
	padding-top: 110px;
	padding-bottom: 100px;
	.title-border {
		height: 1px;
		background: #e7e8ed;
	}
	.zhuanfa-detail-popup {
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(243,243,248,0.8);
		box-shadow: inset 0px 1px 0px 0px #E7E8ED;
		backdrop-filter: blur(10px);
		z-index: 100;
		padding: 20px 0 20px 0;
		.type-item {
			width: 210px;
			height: 69px;
			background: #FFFFFF;
			box-shadow: 0px 4px 8px 0px #E7E8ED;
			border-radius: 10px;
			border: 1px solid #E7E8ED;
			font-size: 23px;
			color: #626A83;
			margin-right: 22px;
			&:nth-child(3n) {
				margin-right: 0;
			}
			&.active {
				color: #3672FD;
				font-weight: bolder;
				background: #D9E5FF;
				box-shadow: 0px 4px 8px 0px #D9E5FF;
				border: 1px solid #3672FD;
			}
		}
		
		::v-deep .u-input {
			input {
				width: 230px;
				height: 61px;
				background: #FFFFFF;
				border-radius: 10px;
				border: 1px solid #CCCFDA;
			
				font-size: 23px;
				color: #979DB1;
				line-height: 32px;
				padding-left: 15px;
			}
		}
		
		.lock-box {
			position: absolute;
			left: 38px;
			right: 38px;
			bottom: 0;
			top: 0;
			background: rgba(26,35,47,0.7);
			border-radius: 19px;
			z-index: 99;
			.suo {
				width: 46px;
				height: 46px;
				margin-top: 36px;
			}
		}
	}
}
</style>
