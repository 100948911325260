<template>
  <div class="flex flex-column">
    <CommonHeader title="积分加油站"></CommonHeader>
    <div class="mt-46 flex justify-end px-38">
      <div class="font-27 line-h-38 text-gray"
        @click="goOrder">订单</div>
    </div>
    <div class="px-38 station-list flex flex-column mt-28">
      <div class="station-item flex flex-column"
        v-for="(item,idx) in stationList" :key="idx"
        @click="goPay(item)">
        <div
          class="font-27 text-default line-h-38 font-weight-bolder text-left">
          {{item.name}}</div>
        <div class="font-23 text-gray line-h-32 mt-10 text-left">
          {{item.desc}}</div>
        <div class="flex align-center mt-10">
          <div class="flex flex-1">
            <span
              class="font-27 text-primary font-weight-bolder">¥
              {{item.user_price && item.user_price/100}}</span>
            <span
              class="font-27 text-gray text-through ml-24">¥
              {{item.price && item.price/100}}</span>
            <div
              class="label text-red font-23 ml-20 flex align-center justify-center">
              {{item.user_discount}}折
            </div>
          </div>
          <div
            class="buy-btn font-23 text-primary flex align-center justify-center">
            购买</div>
        </div>
      </div>
      <van-empty v-if="stationList.length == 0"
        description="暂无积分产品" />
    </div>
  </div>
</template>

<script>
import util from "@/utils/util";
import { getCoinOrderProducts } from "@/api/coinOrder";
import CommonHeader from "@/components/header/commonHeader.vue";
import {
  Empty,
  Toast,
} from "vant";
export default {
  components: {
    VanEmpty: Empty,
    CommonHeader
  },
  data() {
    return {
      stationList: [],
      userInfo: null,
      chatId: "",
      messageIds: "",
      name: "",
      from: "",
      currentChatMessageItem: null
    };
  },
  created() {
    let options = this.$route.query;
    this.chatId = (options && options.chatId) || "";
    this.messageIds =
      options && options.messageIds ? decodeURI(options.messageIds) : "";
    this.name = options && options.name ? decodeURI(options.name) : "";
    this.from = options && options.from ? options.from : "";
    this.currentChatMessageItem= options && options.currentChatMessageItem ? options.currentChatMessageItem : null

    util.onLogin().then((res) => {
        if (!res) {
            this.$bus.$emit("openLogin");
        } else {
            this.getCoinOrderProductsReq();
        }
    });

    this.$bus.$off("initData");
    this.$bus.$on("initData", () => {
        this.getCoinOrderProductsReq();
    });
  },
  methods: {
    getCoinOrderProductsReq() {
      getCoinOrderProducts()
        .then((res) => {
          if (res && res.data && res.data.list) {
            res.data.list.forEach((billItem) => {
              const isExist = this.stationList.find(
                (item) => item.id === billItem.id
              );
              const tempItem = {
                id: billItem.id,
                coin: billItem.coin,
                desc: billItem.desc,
                name: billItem.name,
                price: billItem.price,
                user_discount: billItem.user_discount,
                user_price: billItem.user_price,
              };
              if (isExist) {
                return;
              } else {
                this.stationList.push(tempItem);
              }
            });
          }

          if (res && res.data && res.data.has_more) {
            this.statusLoadmore = "loadmore";
          } else {
            this.statusLoadmore = "nomore";
          }
        })
        .catch((err) => {
          Toast.fail( (err && err.msg) || "服务器异常，请稍后重试");
        });
    },
    calDiscount(discount, amount) {
      let num = parseFloat((discount / amount) * 10).toFixed(0);
      console.log("num", num);
      return num + "折";
    },
    goOrder() {
      this.$router.push({
        path: "Order"
      })
    },
    goPay(item) {
      this.$router.push({
        path: "/Pay?id=" +
          item.id +
          "&userPrice=" +
          item.user_price +
          "&chatId=" +
          this.chatId +
          "&messageIds=" +
          encodeURI(this.messageIds) +
          "&name=" +
          encodeURI(this.name) +
          "&from=" +
          this.from + "&currentChatMessageItem=" + this.currentChatMessageItem
      })
    },
  },
};
</script>

<style lang="less" scoped>
.station-list {
    padding-top: 60px;
  .station-item {
    width: 674px;
    background: #f8f8fa;
    border-radius: 19px;
    padding: 32px 38px 38px 32px;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    .label {
      padding: 0 10px;
      height: 31px;
      background: #ffd8d8;
      border-radius: 15px 15px 15px 0px;
      border: 2px solid #ff5959;
    }
    .buy-btn {
      width: 122px;
      height: 53px;
      background: #d9e5ff;
      box-shadow: 0px 4px 8px 0px #d9e5ff;
      border-radius: 34px;
      border: 1px solid #3672fd;
    }
  }
}
</style>
