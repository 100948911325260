<template>
    <div class="common-header-box" :style="{background: backgroudBg || '#fff'}">
        <div class="header-left" @click="goBack()">
            <van-icon name="arrow-left" />
        </div>
        <div class="header-center">
            {{title}}
        </div>
        <div class="header-right">
            <div v-if="showOrder" class="order-title">订单</div>
            <div v-if="showFav" class="order-title" @click="goFavorite">收藏夹</div>
            <div v-if="showAddFav" class="order-title">
                <img v-if="isFav" src="@/assets/images/market/star_check.png">
                <img v-else src="@/assets/images/market/star_default.png">
                <span class="ml-4">收藏</span>
            </div>
        </div>
    </div>
</template>
<script>
import { Icon } from "vant";
export default {
    props: ["title","showOrder","backgroudBg","showFav","showAddFav","isFav"],
    components: {
        VanIcon: Icon
    },
    methods: {
        goBack() {
            this.$router.go(-1); 
        },
        goFavorite() {
            this.$emit("goFavorite")
        }
    }
}
</script>
<style lang="less" scoped>
.common-header-box {
    height: 110px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    padding: 0 38px;
    background: #fff;
    .header-left {
        display: flex;
        width: 60px;
        ::v-deep .van-icon{
            color: #626A83;
            font-size: 50px;
        }
    }
    .header-center {
        flex: 1;

        font-weight: bold;
        font-size: 32px;
        color: #1A232F;
        text-align: center;
    }
    .header-right {
        width: 100px;
        display: flex;
        align-items: center;
        height: 100%;
        .order-title {
            font-weight: 400;
            font-size: 27px;
            color: #3672FD;
            height: 100%;
            display: flex;
            align-items: center;
            img {
                width: 32px;
                height: 32px;
                margin-top: -4px;
            }
        }
    }
}
</style>