<template>
    <div class="market-box px-38">
        <CommonHeader :title="title"></CommonHeader>
        <div class="market-content">
            <van-form class="collect-form" @submit="onSubmit">
                <div class="collect-title">创意主题</div>
                <van-field v-model="collectForm.collectName" name="collectName" label="" placeholder="请输入创意主题"
                    :rules="[{ required: true }]" />
                <div class="collect-title">创意要求</div>
                <van-field v-model="collectForm.desc" type="textarea" rows="5" name="desc" label=""
                    placeholder="请输入创意要求" :rules="[{ required: true }]" />
                <div class="collect-title">时间限制</div>
                <div class="collect-desc">时间到期后，活动会自动下架，如果是永久的话，您需要手动下架活动</div>
                <van-radio-group v-model="collectForm.limitTime" direction="horizontal">
                    <van-radio v-for="(item,idx) in limitList" :key="idx" :name="item.value"
                        checked-color="#3672FD">{{item.label}}</van-radio>
                </van-radio-group>
                <div class="creative-bottom">
                    <van-button class="create-btn" round block type="info" native-type="submit">发布</van-button>
                </div>
            </van-form>
        </div>
    </div>
</template>
<script>
    import CommonHeader from '@/components/header/commonHeader.vue';
    import { Form, Field, Button, RadioGroup, Radio, Toast } from 'vant';
    import { postCreate } from "@/api/content";
    import time from "@/utils/time";
    export default {
        components: {
            CommonHeader,
            VanForm: Form,
            VanField: Field,
            VanButton: Button,
            VanRadioGroup: RadioGroup,
            VanRadio: Radio,
        },
        data() {
            return {
                title: "征集创意",
                collectForm: {
                    collectName: "",
                    desc: "",
                    limitTime: "1",
                },
                limitList: [
                    {
                        label: "永久",
                        value: "1",
                    },
                    {
                        label: "1周",
                        value: "2",
                    },
                    {
                        label: "2周",
                        value: "3",
                    },
                    {
                        label: "4周",
                        value: "4",
                    },
                ],
            }
        },
        methods: {
            reset() {
                this.collectForm = {
                    collectName: "",
                    desc: "",
                    limitTime: "1",
                };
            },
            calExpired(type) {
                let expiredAt = "";
                console.log("type", type)
                switch (Number(type)) {
                    case 1:
                        expiredAt = "";
                        break;
                    case 2:
                        expiredAt = time.getFutureDate(new Date(), 7);
                        break;
                    case 3:
                        expiredAt = time.getFutureDate(new Date(), 14);
                        break;
                    case 4:
                        expiredAt = time.getFutureDate(new Date(), 28);
                        break;
                }
                return expiredAt;
            },
            onSubmit(values) {
                console.log('submit', values, "collectForm", this.collectForm);
                let expiredAt = this.calExpired(this.collectForm.limitTime);
                let param = {
                    channel: "market",
                    title: this.collectForm.collectName,
                    content: this.collectForm.desc,
                    type: 1
                };
                if (expiredAt) {
                    expiredAt = time.dateFormat(new Date(expiredAt), "{Y}-{MM}-{DD}");
                    param["expired_at"] = expiredAt;
                }
                postCreate(param)
                    .then((res) => {
                        console.log("res",res)
                        if (res && res.error == 0) {
                            // this.closeCollect();
                            this.$router.push({
                                path: "Creative"
                            })
                        } else {
                            Toast.fail((res && res.msg) || "发布创意失败，请稍后重试");
                        }
                    })
                    .catch((err) => {
                        Toast.fail((err && err.msg) || "发布创意失败，请稍后重试");
                    });
            },
        }
    }
</script>
<style lang="less" scoped>
    .market-box {
        padding-top: 100px;

        .market-content {

            .collect-form {
                .collect-title {
                    font-weight: bold;
                    font-size: 27px;
                    color: #1a232f;
                    line-height: 38px;
                    text-align: left;
                    margin-top: 38px;
                }

                .collect-desc {
                    font-weight: 400;
                    font-size: 23px;
                    color: #626A83;
                    line-height: 32px;
                    text-align: left;
                    font-style: normal;
                    margin-top: 10px;
                }

                ::v-deep .van-cell {
                    width: 100%;
                    background: #FFFFFF;
                    border-radius: 10px;
                    border: 1px solid #CCCFDA;
                    margin-top: 18px;
                }

                ::v-deep .van-radio-group {

                    .van-radio {
                        width: 50%;
                        margin-right: 0;
                        margin-bottom: 0;
                        margin-top: 38px;

                        .van-radio__label {
                            font-weight: 400;
                            font-size: 27px;
                            color: #1A232F;
                            line-height: 38px;
                        }
                    }
                }
            }
        }

        .creative-bottom {
            height: 168px;
            background: rgba(243, 243, 248, 0.8);
            box-shadow: inset 0 1px 0 0 #E7E8ED;
            backdrop-filter: blur(10px);
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            .create-btn {
                width: 611px;
                height: 92px;
                background: #3672FD;
                box-shadow: 0 4px 8px 0 #D9E5FF;
                border-radius: 46px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 400;
                font-size: 32px;
                color: #fff;
            }
        }
    }
</style>