import Vue from 'vue'
import App from './App.vue'
import "./assets/css/reset.css";
import "./assets/css/common.css";
import 'animate.css';
import VueRouter from "vue-router";
import routes from "./router/index";
import '@/utils/rem.js';
import store from './store'
// import { NavBar } from 'vant-green';

// Vue.use(NavBar);

import VConsole from 'vconsole';
 
// 初始化 vConsole
const vConsole = new VConsole();
Vue.use(VueRouter);

Vue.config.productionTip = false;

Vue.prototype.$bus = new Vue();

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  // if (to.path == "/login") {
  //   sessionStorage.removeItem("user");
  // }
  // let user = sessionStorage.getItem("user");
  // if (!user && to.path != "/login") {
  //   next({
  //     path: "/login"
  //   })
  // } else {
  //   next()
  // }
  next()
})


new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
