<template>
  <div class="flex flex-column safe-area-inset-bottom">
    <CommonHeader :title="title"></CommonHeader>
    <div class="mt-110 flex tab-list justify-center ">
      <div class="tab-item"
        :class="[tabIdx == idx && 'active']"
        v-for="(item,idx) in tabList" :key="idx"
        @click="tabChange(item,idx)">{{item.name}}</div>
    </div>
    <div class="content-list flex flex-column px-38 mt-38">
      <van-pull-refresh v-model="refreshing"
        @refresh="onRefresh">
        <van-list v-model="loading" :offset="1500"
          :finished="finished" finished-text="没有更多了"
          @load="onLoad">
          <div class="content-item flex"
            v-for="(item,idx) in contentList" :key="idx"
            @click="goDetail(item)">
            <div class="content-img mr-20"
              v-if="item.imgSrc">
              <image :src="item.imgSrc"></image>
            </div>
            <div class="flex flex-column flex-1">
              <div
                class="title-ellipsis-two font-27 text-default line-h-38 flex-1 text-left">
                {{item.title}}</div>
              <!-- <div v-if="item.desc" class="desc font-23 text-normal line-h-32">{{item.desc}}</div> -->
              <div
                class="flex align-center justify-between mt-14">
                <div class="font-24 text-gray">
                  {{item.created_at}}</div>
                <div class="flex align-center "><img
                    class="icon-fav"
                    src="@/assets/images/fav.png"><span
                    class="font-23 text-normal">{{item.like ||
                                0}}</span></div>
              </div>

            </div>
          </div>
        </van-list>
      </van-pull-refresh>
      <van-empty v-if="contentList.length == 0"
        description="暂无内容" />
    </div>
  </div>
</template>

<script>
import CommonHeader from "@/components/header/commonHeader.vue";
import util from "@/utils/util";
import { getUserFav, getUserLikes, getUserPosts } from "@/api/user";
import { Empty, List, PullRefresh, Toast, Icon } from "vant";
export default {
  components: {
    VanEmpty: Empty,
    CommonHeader,
    VanPullRefresh: PullRefresh,
    VanList: List,
    VanIcon: Icon,
  },
  data() {
    return {
      title: "我的内容",
      tabList: [
        {
          name: "我发布的",
        },
        {
          name: "已点赞",
        },
        {
          name: "已收藏",
        },
      ],
      tabIdx: 0,
      statusLoadmore: "loadmore",
      current: 1,
      isLoading: true,
      contentList: [],
      params: {
        type: 0,
        pageSize: 0,
        offset: 0,
        limit: 20,
      },
      userInfo: null,
      reqFun: getUserPosts,
      loading: false,
      finished: false,
      refreshing: false,
      isFirst: true,
    };
  },
  created() {
    // util.onLogin().then(() => {
    //   this.getUserPostsReq();
    // });
  },
  methods: {
    onLoad() {
      console.log("onload");
      if (this.refreshing) {
        this.currentList = [];
        this.refreshing = false;
      }

      this.finished = true;
      if (!this.isFirst) {
        this.params.pageSize += 1;
        this.params.offset = this.params.pageSize * this.params.limit;
      } else {
        this.isFirst = false;
      }

      this.initTabReq();
    },
    onRefresh() {
      console.log("ref");
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.params.pageSize = 0;
      this.params.offset = 0;
      this.initTabReq();
    },
    clear() {
      this.params.pageSize = 0;
      this.params.offset = 0;
      this.contentList = [];
    },
    tabChange(item, idx) {
      if (this.tabIdx == idx) {
        return;
      }
      this.tabIdx = idx;
      this.initTabReq();
    },
    initTabReq() {
      if (this.tabIdx == 0) {
        this.reqFun = getUserPosts;
        this.clear();

        this.getUserPostsReq();
      } else if (this.tabIdx == 1) {
        this.reqFun = getUserLikes;
        this.clear();

        this.getUserPostsReq();
      } else {
        this.reqFun = getUserFav;
        this.clear();

        this.getUserFavReq();
      }
    },
    getUserFavReq() {
      this.reqFun({
        offset: this.params.offset,
        limit: this.params.limit,
        type: "post",
      })
        .then((res) => {
          console.log("res", res);
          this.loading = false;
          this.refreshing = false;
          if (res && res.data && res.data.list) {
            res.data.list.forEach((billItem) => {
              const isExist = this.contentList.find(
                (item) => item.id === billItem.id
              );
              if (isExist) {
                return;
              } else {
                this.contentList.push(billItem);
              }
            });
          }

          if (res && res.data && res.data.has_more) {
            this.statusLoadmore = "loadmore";
            this.finished = false;
          } else {
            this.statusLoadmore = "nomore";
            this.finished = true;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.refreshing = false;
          this.finished = true;
          Toast.fail((err && err.msg) || "服务器异常，请稍后重试");
        });
    },
    getUserPostsReq() {
      this.reqFun({
        offset: this.params.offset,
        limit: this.params.limit,
      })
        .then((res) => {
          console.log("res", res);
          this.loading = false;
          this.refreshing = false;
          if (res && res.data && res.data.list) {
            res.data.list.forEach((billItem) => {
              const isExist = this.contentList.find(
                (item) => item.id === billItem.id
              );
              if (isExist) {
                return;
              } else {
                this.contentList.push(billItem);
              }
            });
          }

          if (res && res.data && res.data.has_more) {
            this.statusLoadmore = "loadmore";
            this.finished = false;
          } else {
            this.statusLoadmore = "nomore";
            this.finished = true;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.refreshing = false;
          this.finished = true;
          Toast.fail((err && err.msg) || "服务器异常，请稍后重试");
        });
    },
    goDetail(item) {
      this.$router.push({
        path: "ContentDetail?id=" + item.id,
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import url(@/assets/css/tabList.less);

.content-list {
  .content-item {
    width: 100%;
    background: #f8f8fa;
    border-radius: 19px;
    padding: 20px;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    .content-img {
      img {
        width: 137px;
        height: 137px;
        border-radius: 20px;
      }
    }

    .desc {
      margin: 13px 0 22px 0;
    }

    .icon-fav {
      width: 32px;
      height: 32px;
    }
  }
}
</style>