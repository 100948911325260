<template>
  <div class="content-detail-box flex-column">
    <div class="flex justify-end">
      <div class="problem-info">

        <div
          class="problem-right-desc position-relative align-center flex flex-column">
          <div class="strong-right"
            v-if="problemItem && problemItem.msg && problemItem.msg.text">
            <span>{{problemItem.msg.text}}</span>
          </div>
          <div style="padding: 0 20px;"
            v-if="problemItem && problemItem.msg && problemItem.msg.image">
            <img style="max-width:460px;"
              :src="problemItem.msg.image"
              @click="previewImage(problemItem.msg.image)"
              show-menu-by-longpress="true">
          </div>
        </div>
      </div>
      <div class="problem-avatar ml-20">
        <img v-if="problemItem && problemItem.avatar"
          :src="problemItem.avatar">
        <img v-else
          src="https://files.sssrise.com/files/uploads/static/default_avatar.png">
      </div>
    </div>

  </div>
</template>

<script>
import { Toast, ImagePreview } from "vant";
export default {
  props: ["problemItem", "problemIdx"],
  data() {
    return {};
  },
  methods: {
    previewImage(image) {
      console.log("image",image)
      ImagePreview([image]);
    },
    showEditInput() {},
  },
};
</script>

<style lang="less" scoped>
@import url(@/assets/css/contentDetail.less);
</style>
