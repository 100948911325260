<template>
    <div class="sales-data-box">
        <div class="rank-title position-relative"><span>产品信息</span>
            <!-- <div class="edit-box">
                <img src="@/assets/images/market/icon_edit.png">
                <div class="modify-info">修改</div>
            </div> -->
        </div>
        <div class="info-detail">
            <div class="info-item">
                <div class="desc">品类</div>
                <div class="info-val">{{category}}</div>
            </div>
            <div class="info-item">
                <div class="desc">IP</div>
                <div class="info-val">{{ipVal}}</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ["category","ipVal"],
    components: {
    },
    data() {
        return {
        }
    },
    methods: {
    }
}
</script>
<style lang="less" scoped>
@import url("@/assets/css/rankTitle.less");
.sales-data-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border-radius: 20px;
    border: 1px solid #E7E8ED;
    .edit-box {
        position: absolute;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        right: 26px;
        img {
            width: 32px;
            height: 32px;
        }
        .modify-info {
            font-weight: 400;
            font-size: 27px;
            color: #626A83;
            margin-left: 14px;
        }
    }

    .info-detail {
        display: flex;
        flex-direction: column;
        padding: 26px 0;
        .info-item {
            display: flex;
            padding: 8px 38px;

            .desc {
                font-weight: 400;
                font-size: 27px;
                color: #626A83;
                line-height: 38px;
                width: 100px;
                text-align: left;
            }

            .info-val {
                font-weight: bold;
                font-size: 27px;
                color: #1A232F;
                line-height: 38px;
            }
        }
    }
}
</style>