<template>
  <div class="flex flex-column pt-110">
    <CommonHeader title="会员中心"></CommonHeader>
    <!-- <div class="flex px-38 align-center mt-50">
			<div class="text-border"></div>
			<div class="font-27 text-default font-weight-bolder line-h-38">荣誉墙</div>
		</div>
		<div class="flex flex-column honor-list mt-28">
			<div class="honor-item flex align-end" v-for="(item,idx) in currentList" :key="idx">
				<div class="font-27 text-normal font-weight-bolder" :class="[idx == 0 ? 'text-primary' : '']">{{item.dateTime}}</div>
				<div class="flex align-center circle-box" :class="[idx == 0 ? 'pt-50' : '']"><div class="circle flex align-center justify-center"><div class="sub-circle"></div></div></div>
				<div class="flex align-center">
					<div class="font-23 text-normal" :class="[idx == 0 ? 'text-default' : '']">{{item.desc}}</div>
					<div class="vip ml-08 mt-0"  v-if="item.vipLevel && item.vipLevel > 0">VIP {{item.vipLevel}}</div>
				</div>
			</div>
		</div> -->
    <div class="flex px-38 align-center mt-30">
      <div class="text-border"></div>
      <div
        class="font-27 text-default font-weight-bolder line-h-38">
        会员权益</div>
    </div>
    <div
      class="level-h font-23 flex align-center text-default font-weight-bolder mt-30">
      <div class="level-name flex-1">VIP等级</div>
      <div class="condition text-center">获取条件</div>
      <div class="sign-in text-center">每日签到</div>
      <div class="discount text-center">消费折扣</div>
      <div class="give text-center">赠送玩具</div>
    </div>
    <div class="level-list">
      <div class="level-item flex align-center"
        :class="[userInfo && userInfo.vip_level == item.vipLevel ? 'active' : '']"
        v-for="(item,idx) in levelList" :key="idx">
        <div div="level-name flex-1">
          <div class="vip-box">
            <div class="vip">VIP {{item.vipLevel}}</div>
          </div>

        </div>

        <div
          class="condition font-23 text-normal text-center">
          {{item.desc}}</div>
        <div
          class="sign-in font-23 text-normal text-center">
          {{item.signin}}</div>
        <div
          class="discount font-23 text-normal text-center">
          {{item.discount || "/"}}</div>
        <div class="give font-23 text-normal text-center">
          {{item.give || "/"}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import util from "@/utils/util";
import CommonHeader from "@/components/header/commonHeader.vue";
export default {
  components: {
    CommonHeader,
  },
  data() {
    return {
      honorList: [
        {
          dateTime: "2024.3.10",
          desc: "首次进入产品",
        },
        {
          dateTime: "2024.3.11",
          desc: "转发了第一篇文章",
        },
        {
          dateTime: "2024.3.12",
          desc: "收获了第一个赞",
        },
        {
          dateTime: "2024.3.16",
          desc: "升级为",
          vipLevel: 2,
        },
      ],
      currentList: [],
      levelList: [
        {
          vipLevel: 1,
          desc: "实名认证",
          signin: 10,
          discount: "",
          give: "",
        },
        {
          vipLevel: 2,
          desc: "30",
          signin: 20,
          discount: "",
          give: "",
        },
        {
          vipLevel: 3,
          desc: "90",
          signin: 30,
          discount: "",
          give: "",
        },
        {
          vipLevel: 4,
          desc: "180",
          signin: 50,
          discount: "9.5",
          give: "1",
        },
        {
          vipLevel: 5,
          desc: "588",
          signin: 100,
          discount: "9.3",
          give: "2",
        },
        {
          vipLevel: 6,
          desc: "1888",
          signin: 100,
          discount: "9.2",
          give: "3",
        },
        {
          vipLevel: 7,
          desc: "5888",
          signin: 100,
          discount: "9.1",
          give: "6",
        },
        {
          vipLevel: 8,
          desc: "18888",
          signin: 110,
          discount: "9",
          give: "8",
        },
        {
          vipLevel: 9,
          desc: "58888",
          signin: 100,
          discount: "8.9",
          give: "10",
        },
        {
          vipLevel: 10,
          desc: "88888",
          signin: 100,
          discount: "8.8",
          give: "12",
        },
      ],
      userInfo: {
        id: "",
        nickname: "",
        mobile: "",
        avatar: "",
        gender: "",
        desc: "",
        real_name: "",
        character: "",
        vip_level: 0,
        vip_expires_in: "",
        coin: 0,
        income: 0,
        balance: 0,
        freeze: 0,
        token: "",
      },
    };
  },
  created() {
    this.currentList = this.honorList.reverse();
    console.log("currentList", this.currentList);
    util.onLogin().then(() => {
      this.getUserInfo();
    });
  },
  methods: {
    getUserInfo() {
      this.$store
        .dispatch("GetInfo", "")
        .then((res) => {
          this.userInfo = res;
          console.log("userInfo", this.userInfo);
        })
        .catch((err) => {
          Notify((err && err.msg) || "服务器出错，请稍后重试");
        });
    },
  },
};
</script>

<style lang="less" scoped>
.honor-list {
  padding: 0 38px;
  .honor-item {
    position: relative;

    .circle-box {
      padding-top: 85px;
      position: relative;
      &::before {
        position: absolute;
        content: "";
        width: 2px;
        background: #88acff;
        top: 0;
        right: 0;
        bottom: 23px;
        left: 50%;
      }
    }
    .circle {
      width: 23px;
      height: 23px;
      background: #f8f8fa;
      border: 2px solid #3672fd;
      border-radius: 100%;
      margin: 0 20px;
      position: relative;

      .sub-circle {
        width: 8px;
        height: 8px;
        background: #3672fd;
        border-radius: 100%;
      }
    }
  }
}
.level-h {
  padding: 0 38px;
  height: 69px;
  background: #f8f8fa;
  border-radius: 19px;
  margin: 0 auto;
  padding: 0 20px;
}
.level-list {
  padding: 0 38px;
  .level-item {
    width: 100%;
    height: 69px;

    border-radius: 19px;
    margin: 0 auto;
    padding: 0 20px;
    &:nth-child(2n) {
      background: #f8f8fa;
    }
    &.active {
      background: #d9e5ff;
      box-shadow: 0 4px 8px 0 #d9e5ff;
      border: 1px solid #3672fd;
    }
  }
}

.level-name {
  // width: 100px;
}
.condition {
  width: 130px;
}
.sign-in {
  width: 130px;
}
.discount {
  width: 130px;
}
.give {
  width: 130px;
}
</style>
