<template>
  <div class="company-info-popup">
    <div class="layer"></div>
    <div class="company-info-content">
      <div class="title">请完善企业资料</div>
      <van-form class="info-box" @submit="onSubmit">
        <div class="info-form">
          <van-field v-model="companyForm.companyName"
            name="companyName" label="用户名"
            placeholder="请输入公司名称" />
          <van-field v-model="companyForm.address"
            name="address" label="地址"
            placeholder="请输入公司地址" />
          <van-field v-model="companyForm.phone"
            name="phone" label="电话" placeholder="请输入电话" />
          <van-field v-model="companyForm.job" name="job"
            label="职位" placeholder="请输入公司职位" />
        </div>

        <van-button class="submit-btn" round block
          type="info" native-type="submit">提交</van-button>
      </van-form>
      <div class="icon-close">
        <img @click="closeInfo" src="@/assets/images/common/icon_close.png">
      </div>
    </div>

  </div>
</template>
<script>
import { Form, Field, Button } from "vant";
export default {
  components: {
    vanForm: Form,
    vanField: Field,
    vanButton: Button,
  },
  data() {
    return {
      companyForm: {
        companyName: "",
        address: "",
        phone: "",
        job: "",
      },
    };
  },
  methods: {
    onSubmit(values) {
      console.log("submit", values);
    },
    closeInfo() {
        this.$emit("closeInfo")
    }
  },
};
</script>
<style lang="less" scoped>
.company-info-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10001;
  background: transparent;
  .layer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    background: rgba(26, 35, 47, 0.7);
  }
  .company-info-content {
    position: absolute;
    width: 611px;
    height: 687px;
    background: #ffffff;
    border-radius: 38px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .title {
      padding-top: 40px;
      font-weight: bold;
      font-size: 32px;
      color: #1a232f;
      line-height: 46px;
      text-align: center;
    }
    .info-box {
      margin-top: 36px;
      display: flex;
      flex-direction: column;
    }
    .info-form {
      width: 534px;
      height: 401px;
      background: #f8f8fa;
      border-radius: 19px;
      margin: 0 auto;
      ::v-deep .van-cell {
        background: transparent;
      }
    }
    .submit-btn {
      width: 458px;
      height: 92px;
      background: #3672fd;
      box-shadow: 0 4px 8px 0 #d9e5ff;
      border-radius: 52px;
      margin: 0 auto;
      margin-top: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-size: 34px;
      color: #ffffff;
    }
    .icon-close {
      position: absolute;
      left: 0;
      right: 0;
      bottom: -100px;
      display: flex;
      justify-content: center;
      img {
        width: 46px;
        height: 46px;
      }
    }
  }
}
</style>