<template>
    <div class="sales-data-box">
        <div class="rank-title"><span>爆款消费者印象</span></div>
        <RankCom class="mt-28" :hideTitle="true" :hiddenLabel="true" title="TOP5 品类" :currentList="topRank" bgColor="#9893EB"></RankCom>
    </div>
</template>
<script>
import RankCom from "./rank.vue";
export default {
    components: {
        RankCom
    },
    data() {
        return {
            topRank: [{
                name: "",
                rankList: [{
                    name: "电动玩具",
                    percent: "15.5",
                },{
                    name: "益智玩具",
                    percent: "14.7",
                },{
                    name: "绘画玩具",
                    percent: "12.1",
                },{
                    name: "积木拼装",
                    percent: "9.2",
                },{
                    name: "橡皮泥",
                    percent: "7.3",
                }]
            }],
        }
    },
    methods: {
    }
}
</script>
<style lang="less" scoped>
@import url("@/assets/css/rankTitle.less");
.sales-data-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border-radius: 20px;
    border: 1px solid #E7E8ED;
}
</style>