<template>
    <div class="rank-box">
        <div class="flex align-center pl-20 mb-20" v-if="!hideTitle">
            <div class="text-border"></div>
            <div class="default-title mt-0">{{title}}<span class="sub-title" v-if="subTitle">({{subTitle}})</span></div>

        </div>
        <div class="rank-list">
            <div class="rank-item" v-for="(item,idx) in currentList" :key="idx" v-show="idx == (showIdx -1 )">
                <div class="zhanbi" v-if="!hiddenLabel">占比</div>
                <div class="rank-info-list" v-for="(subItem,secIdx) in item.rankList" :key="secIdx">

                    <div class="rank-info">
                        <div class="num"><span>{{secIdx + 1}}</span>
                        </div>
                        <div class="name one-text">{{subItem.category_name || subItem.distribution_range ||
                            subItem.ip_name || subItem.region_name || ""}}
                        </div>
                        <div class="percent">
                            <div class="active"
                                :style="{width: calPerWidth(item.rankList,subItem.rate || subItem.point_index || subItem.region_index || 0) + '%',background: bgColor || '#da8ff5'}">
                            </div>
                        </div>
                        <div class="percent-count">{{subItem.rate || subItem.point_index || subItem.region_index_trend || 0}}<span
                                v-if="!hiddenLabel">%</span>
                        </div>
                        <div class="up-down" v-if="showUpDown">
                            <span v-if="subItem.region_index_trend > 0"
                              class="up" style="color:#E6182E;font-size: 14px;font-weight: bolder;">
                              &#9650;</span>
                            <span v-else-if="subItem.region_index_trend < 0" class="down" style="color:#13B918;font-size: 14px;font-weight: bolder;">&#9660;</span>
                            <span v-else>-</span>
                          </div>
                    </div>
                </div>
                <van-empty v-if="item.rankList.length == 0"
                description="暂无数据" />
            </div>
        </div>
    </div>
</template>
<script>
    import { Empty,Icon } from "vant";
    export default {
        components: {
            VanEmpty: Empty,
            VanIcon: Icon
        },
        props: ["title", "subTitle", "currentList", "bgColor", "hiddenLabel", "hideTitle", "showIdx","showUpDown"],
        methods: {
            calPerWidth(rankList, percent) {
                let sum = 0;
                for (let i = 0, j = rankList.length; i < j; i++) {
                    if (rankList[i].rate) {
                        sum += Number(rankList[i].rate);
                    }
                    if (rankList[i].point_index) {
                        sum += Number(rankList[i].point_index);
                    }
                    if(rankList[i].region_index) {
                        sum += Number(rankList[i].region_index);
                    }
                }
                if (percent == 0) {
                    return 0;
                }
                if (sum == 0) {
                    return 0;
                } else {
                    return parseFloat(Number(percent) / sum * 100).toFixed(2);
                }

            }
        }
    };
</script>
<style lang="less" scoped>
    @import url("@/assets/css/rankTitle.less");

    .rank-box {
        display: flex;
        width: 100%;
        background: #ffffff;
        margin-bottom: 20px;
        flex-direction: column;

        .rank-list {
            display: flex;
            padding: 0 20px;

            ::v-deep .van-empty {
                padding: 20px 0;
                .van-empty__image {
                    width: 200px;
                    height: 200px;
                }
            }
            .rank-item {
                flex: 1;
                display: flex;
                flex-direction: column;
                position: relative;

                .zhanbi {
                    font-weight: 400;
                    font-size: 24px;
                    color: #979db1;
                    line-height: 28px;
                    position: absolute;
                    right: 20px;
                    top: -60px;
                }

                .rank-info-list {
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    margin-bottom: 16px;
                    justify-content: center;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                .rank-info {
                    display: flex;
                    padding: 0 6px 0 10px;
                    align-items: center;

                    .num {
                        width: 32px;
                        height: 32px;
                        background: #fbfbfc;
                        box-shadow: 0px 4px 8px 0px #e7e8ed;
                        border: 1px solid #e7e8ed;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 100%;

                        span {
                            font-weight: bold;
                            font-size: 19px;
                            color: #1a232f;
                        }
                    }

                    .name {
                        width: 100px;
                        font-weight: 400;
                        font-size: 23px;
                        line-height: 32px;
                        color: #1a232f;
                        text-align: left;
                        margin-left: 20px;
                    }

                    .percent {
                        flex: 1;
                        height: 32px;
                        background: #f3f3f8;
                        border-radius: 8px;
                        margin-left: 20px;
                        position: relative;

                        .active {
                            position: absolute;
                            left: 0;
                            top: 0;
                            bottom: 0;
                            background: #da8ff5;
                            border-radius: 4px;
                        }
                    }

                    .percent-count {
                        width: 72px;
                        font-weight: 400;
                        font-size: 24px;
                        color: #626a83;
                        margin-left: 12px;
                    }
                }

                .up-down {
                    font-size: 14px;
                    width: 10px;
                    display: flex;
                    justify-content: center;
                    color: #979db1;
                }
            }
        }
    }
</style>