<template>
    <div class="sales-data-box">
        <div class="rank-title"><span>销售数据</span></div>
        <AnalysisData :total="total"></AnalysisData>
        <div class="flex align-center mt-20 pl-20" v-if="!hiddenCategory">
            <div class="text-border"></div>
            <div class="default-title mt-0">TOP5 品类</div>
        </div>
        <SaleTab class="mt-16" :tabList="saleList" :currentType="saleType" @changeTab="changeSaleType"></SaleTab>
        <RankCom v-if="!hiddenCategory" class="mt-28" :hideTitle="true" :currentList="categoryRank" :showIdx="saleType"></RankCom>
        <RankCom class="mt-38" :hideTitle="false" title="价格分布排行" subTitle="单位:元"  :currentList="distributionRank" :showIdx="saleType"  bgColor="#9893EB"></RankCom>
    </div>
</template>
<script>
import AnalysisData from "./analysisData.vue";
import SaleTab from "./saleTab.vue";
import RankCom from "./rank.vue";
export default {
    props: ["total","categoryRank","distributionRank","hiddenCategory"],
    components: {
        AnalysisData,
        RankCom,
        SaleTab
    },
    data() {
        return {
            saleList: [{
                name: "销量",
                type: 1
            },{
                name: "销售额",
                type: 2
            }],
            saleType: 1
        }
    },
    created() {
        console.log("categoryRank",this.categoryRank)
    },
    methods: {
        changeSaleType(type) {
            this.saleType = type;
        }
    }
}
</script>
<style lang="less" scoped>
@import url("@/assets/css/rankTitle.less");
.sales-data-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border-radius: 20px;
    border: 1px solid #E7E8ED;
}
</style>