<template>
  <div
    class="flex flex-column  content-detail-wrap px-38 bg-white"
    :class="[showPay ? 'overflow-hidden' : '']">
    <CommonHeader title="详情"></CommonHeader>
    <div
      class="font-32 line-h-46 text-default font-weight-bolder text-left">
      {{contentDetail && contentDetail.title || ""}}</div>
    <div class="user-info mt-20 align-center">
      <div class="avatar">
        <img
          v-if="contentDetail && contentDetail.user && contentDetail.user.avatar"
          :src="contentDetail.user.avatar">
        <img v-else
          src="https://files.sssrise.com/files/uploads/static/default_avatar.png">
      </div>
      <div class="detail">
        <div class="name">
          {{contentDetail && contentDetail.user && contentDetail.user.nickname || ""}}
        </div>
      </div>
    </div>
    <div class="title-border mt-20"></div>
    <div class="mt-20 content-detail"
      v-if="contentDetail && contentDetail.content">
      {{contentDetail.content}}
    </div>
    <div
      v-if="contentDetail && contentDetail.messages && contentDetail.messages.length > 0">
      <div class="problem-wrap mt-20"
        v-for="(item,idx) in contentDetail.messages"
        :key="idx">
        <DetailLeft v-if="item.cat == 1" :problemItem="item"
          :problemIdx="idx"></DetailLeft>
        <DetailRight v-if="item.cat == 0"
          :problemItem="item" :problemIdx="idx">
        </DetailRight>
      </div>
    </div>
    <DetailBottom v-if="showBottom"
      :contentDetail="contentDetail"
      @submitLike="submitLike" @submitFav="submitFav">
    </DetailBottom>
    <FavCom v-if="showFavPopup"
      @chooseReason="chooseReason"></FavCom>
  </div>
</template>

<script>
import DetailLeft from "@/components/market/detailLeft.vue";
import DetailRight from "@/components/market/detailRight.vue";
import util from "@/utils/util";
import { getPostInfo, postFav, postLike } from "@/api/content";
import { userFav, userLike } from "@/api/user";
import { Toast } from "vant";
import CommonHeader from "@/components/header/commonHeader.vue";
import DetailBottom from "@/components/market/detailBottom.vue";
import FavCom from "@/components/market/fav.vue";
export default {
  components: {
    DetailLeft,
    DetailRight,
    CommonHeader,
    DetailBottom,
    FavCom,
  },
  data() {
    return {
      problemList: [],
      showPay: false,
      showBottom: false,
      showFavPopup: false,
      id: null,
      contentDetail: null,
      from: "",
    };
  },
  created() {
    util.onLogin().then(async () => {
      this.initData();
    });
  },
  watch: {
    $route(to, from) {
      if (to.path.indexOf("ContentDetail") >= 0) {
        this.initData();
      }
    },
  },
  methods: {
    chooseReason(reason) {
      this.showFavPopup = false;
      this.postLikeReq(1, false, reason);
    },
    initData() {
      this.id = this.$route.query.id || "";
      this.from = this.$route.query.from || "";
      if (this.from == "creative") {
        this.showBottom = true;
      } else {
        this.showBottom = false;
      }
      this.getPostInfoReq();
    },
    submitPay() {
      this.showPay = false;
    },
    submitLike(val) {
      if (val.type == 1 && !val.cancel) {
        this.showFavPopup = true;
      } else {
        this.postLikeReq(val.type, val.cancel, "");
      }
    },
    postLikeReq(type, cancel, reason = "") {
      let text =
        type == 1
          ? cancel
            ? "取消点赞内容成功"
            : "点赞内容成功"
          : cancel
          ? "取消点踩内容成功"
          : "点踩内容成功";
      userLike({
        cancel: cancel,
        id: this.contentDetail.id,
        like: type,
        reason: reason,
        type: "post",
      })
        .then((res) => {
          if (res.error == 0) {
            Toast.success(text);
          } else {
            Toast.fail((res && res.msg) || "服务器异常，请稍后重试");
          }
          this.getPostInfoReq();
        })
        .catch((err) => {
          Toast.fail((err && err.msg) || "服务器异常，请稍后重试");
        });
    },
    submitFav(fav) {
      let text = fav == 1 ? "收藏内容成功" : "取消收藏内容成功";
      userFav({ id: this.contentDetail.id, fav: fav, type: "post" })
        .then((res) => {
          if (res.error == 0) {
            Toast.success(text);
          } else {
            Toast.fail((res && res.msg) || "服务器异常，请稍后重试");
          }
          this.getPostInfoReq();
        })
        .catch((err) => {
          Toast.fail((err && err.msg) || "服务器异常，请稍后重试");
        });
    },
    getPostInfoReq() {
      getPostInfo(this.id)
        .then((res) => {
          console.log("res", res);
          if (res && res.error == 0) {
            this.contentDetail = res.data;
          } else {
            Toast.fail((res && res.msg) || "服务器异常，请稍后重试");
          }
        })
        .catch((err) => {
          Toast.fail((err && err.msg) || "服务器异常，请稍后重试");
        });
    },
  },
};
</script>

<style lang="less" scoped>
.content-detail-wrap {
  padding-top: 120px;
  padding-bottom: 130px;
  .user-info {
    display: flex;
    flex: 1;
    .avatar {
      width: 46px;
      height: 46px;
      display: flex;
      img {
        width: 46px;
        height: 46px;
        border-radius: 100%;
      }
    }

    .detail {
      display: flex;
      flex-direction: column;
      padding-left: 20px;
      flex: 1;
      justify-content: center;

      .name {
        font-weight: bolder;
        font-size: 22px;
        color: #1a232f;
        line-height: 38px;
        display: flex;

        img {
          width: 40px;
          height: 40px;
          border-radius: 100%;
        }
      }
    }
  }
  .content-detail {
    font-weight: 400;
    font-size: 24px;
    color: #626a83;
    line-height: 32px;
    text-align: justify;
    font-style: normal;
    padding-bottom: 60px;
  }
}
</style>
