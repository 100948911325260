<template>
  <div class="ip-detail-box ">
    <CommonHeader :title="title" :showOrder="showOrder"
      :showAddFav="showAddFav" :isFav="isFav">
    </CommonHeader>
    <div class="market-pay-content px-38">
      <ProductInfo :category="category" :ipVal="ipVal">
      </ProductInfo>
      <!-- <ReportDay class="mt-20"></ReportDay> -->
      <SalesData class="mt-20" :total="total"
        :distributionRank="distributionRank"
        :hiddenCategory="true"></SalesData>
      <!-- <BrandRank class="mt-20"></BrandRank> -->
      <IpAnalysis class="mt-20" :ipInfo="ipInfo">
      </IpAnalysis>
      <!-- <HotSale class="mt-20"></HotSale> -->

      <SuggestionCom class="mt-20" :suggestion="ipInfo">
      </SuggestionCom>
      <FlowInfo class="mt-20" :flowIndex="flowIndex"
        :ipInfo="ipInfo" :distributionAge="distributionAge"
        :distributionAgeDefault="distributionAgeDefault">
      </FlowInfo>
      <YxAanlysis class="mt-20" :ipMarket="ipMarket">
      </YxAanlysis>
    </div>

  </div>
</template>
<script>
import CommonHeader from "@/components/header/commonHeader.vue";
import DetailInfo from "./components/detailInfo.vue";
import SuggestionCom from "./components/suggestion.vue";
import SalesData from "./components/salesData.vue";
import BrandRank from "./components/brandRank.vue";
import YxAanlysis from "./components/yxAnalysis.vue";
import FlowInfo from "./components/flowInfo.vue";
import ProductInfo from "./components/productInfo.vue";
import ReportDay from "./components/reportDay.vue";
import HotSale from "./components/hotSale.vue";
import IpAnalysis from "./components/ipAnalysis.vue";
import { Search, Icon, Rate, Toast } from "vant";
import { getStatisticsProduct } from "@/api/statistics";

export default {
  components: {
    CommonHeader,
    vanSearch: Search,
    vanIcon: Icon,
    vanRate: Rate,
    SalesData,
    BrandRank,
    YxAanlysis,
    SuggestionCom,
    DetailInfo,
    FlowInfo,
    ProductInfo,
    ReportDay,
    HotSale,
    IpAnalysis,
  },
  data() {
    return {
      title: "产品策略报告",
      showOrder: false,
      showAddFav: false,
      isFav: false,
      backgroudBg: "transparent",
      category: null,
      categoryId: null,
      ipId: null,
      ipVal: null,
      currentDate: new Date(),
      total: null,
      distributionRank: [
        {
          name: "销量",
          rankList: [],
        },
        {
          name: "销售额",
          rankList: [],
        },
      ],
      ipMarket: [
        {
          name: "平台热度",
          rankList: [
            {
              region_name: "头条",
              typeIdx: "point_toutiao_index",
              region_index: 0,
              region_index_trend: 0,
            },
            {
              region_name: "抖音",
              typeIdx: "point_douyin_index",
              region_index: 0,
              region_index_trend: 0,
            },
            {
              region_name: "快手",
              typeIdx: "point_kuaishou_index",
              region_index: 0,
              region_index_trend: 0,
            },
            {
              region_name: "微博",
              typeIdx: "point_weibo_index",
              region_index: 0,
              region_index_trend: 0,
            },
            {
              region_name: "百度",
              typeIdx: "point_search_index",
              region_index: 0,
              region_index_trend: 0,
            },
            {
              region_name: "小红书",
              typeIdx: "point_xiaohongshu_index",
              region_index: 0,
              region_index_trend: 0,
            },
          ],
        },
        {
          name: "地域热度",
          rankList: [],
        },
      ],
      ipInfo: null,
      flowIndex: {
        search_index: 0,
        search_index_trend: 0,
        media_index: 0,
        media_index_trend: 0,
      },
      distributionAge: [],
      distributionAgeDefault: [],
    };
  },
  created() {
    console.log("this.$route", this.$route);
    this.categoryId = this.$route.query.categoryId || null;
    this.category = this.$route.query.category
      ? decodeURIComponent(this.$route.query.category)
      : null;
    this.ipId = this.$route.query.ipId || null;
    this.ipVal = this.$route.query.ipVal
      ? decodeURIComponent(this.$route.query.ipVal)
      : null;
    if (!this.categoryId || !this.ipId || !this.ipVal || !this.category) {
      this.$router.replace({
        path: "/MarketIdea",
      });
    } else {
      this.getStatisticsProductReq();
    }
  },
  methods: {
    getStatisticsProductReq() {
      let year = this.currentDate.getFullYear();
      let month = this.parseNumber(this.currentDate.getMonth() + 1);
      let param = {
        // ym: Number(year + "" + month),
        category_id: this.categoryId,
        ip_id: this.ipId,
      };
      getStatisticsProduct(param)
        .then((res) => {
          if (res && res.error == 0 && res.data) {
            this.total = res.data.total || null;
            if (res.data && res.data.distribution_price) {
              this.distributionRank[1]["rankList"] =
                res.data.distribution_price || [];
            }
            if (res.data && res.data.distribution_sales) {
              this.distributionRank[0]["rankList"] =
                res.data.distribution_sales || [];
            }
            this.ipInfo = res.data.ip_info || null;
            if (res.data && res.data.region_index) {
              this.ipMarket[1]["rankList"] = res.data.region_index || [];
            }
            if (res.data && res.data.ip_info) {
              let tempList = this.ipMarket[0]["rankList"];
              for (let i = 0, j = tempList.length; i < j; i++) {
                let item = tempList[i];
                if (this.ipInfo && this.ipInfo[item.typeIdx]) {
                  item["region_index"] = this.ipInfo[item.typeIdx];
                }
                if (this.ipInfo && this.ipInfo[item.typeIdx + "_trend"]) {
                  item["region_index_trend"] =
                    this.ipInfo[item.typeIdx + "_trend"];
                }
              }
            }
            this.flowIndex["search_index"] = res.data.search_index || 0;
            this.flowIndex["search_index_trend"] =
              res.data.search_index_trend || 0;
            this.flowIndex["media_index"] = res.data.media_index || 0;
            this.flowIndex["media_index_trend"] =
              res.data.media_index_trend || 0;
            if (res.data && res.data.distribution_age_default) {
              this.distributionAgeDefault =
                res.data.distribution_age_default || [];
            }
            if (res.data && res.data.distribution_age) {
              this.distributionAge = res.data.distribution_age || [];
            }
          } else {
            Toast.fail(res.msg || "获取数据失败，请稍后重试");
            this.initDefalutData();
          }
        })
        .catch((err) => {
          if (err.error == 20912) {
            this.$router.push("Market");
          } else {
            this.initDefaultData();
          }
        });
    },
    parseNumber(num) {
      return num < 10 ? "0" + num : num;
    },
    initDefaultData() {
      this.total = null;
      this.distributionRank = [
        {
          name: "销量",
          rankList: [],
        },
        {
          name: "销售额",
          rankList: [],
        },
      ];
      this.ipMarket = [
        {
          name: "平台热度",
          rankList: [
            {
              region_name: "头条",
              typeIdx: "point_toutiao_index",
              region_index: 0,
              region_index_trend: 0,
            },
            {
              region_name: "抖音",
              typeIdx: "point_douyin_index",
              region_index: 0,
              region_index_trend: 0,
            },
            {
              region_name: "快手",
              typeIdx: "point_kuaishou_index",
              region_index: 0,
              region_index_trend: 0,
            },
            {
              region_name: "微博",
              typeIdx: "point_weibo_index",
              region_index: 0,
              region_index_trend: 0,
            },
            {
              region_name: "百度",
              typeIdx: "point_search_index",
              region_index: 0,
              region_index_trend: 0,
            },
            {
              region_name: "小红书",
              typeIdx: "point_xiaohongshu_index",
              region_index: 0,
              region_index_trend: 0,
            },
          ],
        },
        {
          name: "地域热度",
          rankList: [],
        },
      ];
      this.ipInfo = null;
      this.flowIndex = {
        search_index: 0,
        search_index_trend: 0,
        media_index: 0,
        media_index_trend: 0,
      };
      this.distributionAge = [];
      this.distributionAgeDefault = [];
    },
  },
};
</script>
<style lang="less" scoped>
.ip-detail-box {
  padding-top: 110px;

  .market-pay-content {
    margin-top: 30px;
    position: relative;
  }
}
</style>