<template>
  <div class="ip-index-box px-38">
    <CommonHeader :title="title" :showOrder="showOrder"
      :showFav="showFav"></CommonHeader>
    <div class="market-pay-content">
      <div class="fav-list">
        <div class="fav-item" v-for="(item,idx) in favList"
          :key="idx" @click="goReport">
          <div class="title">{{item.name}}</div>
          <div class="time">{{item.createTime}}</div>
        </div>
      </div>
    </div>

  </div>
</template>
  <script>
import CommonHeader from "@/components/header/commonHeader.vue";
export default {
  components: {
    CommonHeader,
  },
  data() {
    return {
      title: "收藏夹",
      showOrder: false,
      showFav: false,
      favList: [
        {
          name: "益智玩具",
          createTime: "2024/03/12 14:26:55",
        },
        {
          name: "益智玩具",
          createTime: "2024/03/12 14:26:55",
        },
        {
          name: "益智玩具",
          createTime: "2024/03/12 14:26:55",
        },
        {
          name: "益智玩具",
          createTime: "2024/03/12 14:26:55",
        },
        {
          name: "益智玩具",
          createTime: "2024/03/12 14:26:55",
        },
      ],
    };
  },
  methods: {
    goReport() {
      this.$router.push({
        path: "/ProductReport",
      });
    },
  },
};
</script>
  <style lang="less" scoped>
.ip-index-box {
  padding-top: 110px;

  .market-pay-content {
    margin-top: 30px;
    .fav-list {
      display: flex;
      flex-direction: column;
      .fav-item {
        padding: 30px 36px 34px 36px;
        background: #f8f8fa;
        border-radius: 20px;
        margin-bottom: 20px;
        .title {
          font-weight: bold;
          font-size: 27px;
          color: #1a232f;
          line-height: 38px;
          text-align: left;
        }
        .time {
          font-weight: 400;
          font-size: 19px;
          color: #979db1;
          line-height: 27px;
          margin-top: 4px;
          text-align: left;
        }
      }
    }
  }
}
</style>