<template>
  <div class="market-pay-box px-38">
    <CommonHeader :title="title" :showOrder="showOrder">
    </CommonHeader>
    <DayTab :currentDate="currentDate"
      @changeDate="changeDate"></DayTab>
    <div class="market-pay-content">
      <SalesData :total="total" :categoryRank="categoryRank"
        :distributionRank="distributionRank"></SalesData>
      <!-- <BrandRank class="mt-20"></BrandRank> -->
      <IpRank class="mt-20" :ipRank="ipRank"></IpRank>
      <FlowIndex class="mt-20" :flowRank="flowRank">
      </FlowIndex>

    </div>
  </div>
</template>
<script>
import CommonHeader from "@/components/header/commonHeader.vue";
import DayTab from "./components/dayTab.vue";
import SalesData from "./components/salesData.vue";
import BrandRank from "./components/brandRank.vue";
import FlowIndex from "./components/flowIndex.vue";
import IpRank from "./components/ipRank.vue";
import { getStatisticsMarket } from "@/api/statistics";
import { Toast } from "vant";
export default {
  components: {
    CommonHeader,
    DayTab,
    SalesData,
    BrandRank,
    FlowIndex,
    IpRank,
  },
  data() {
    return {
      title: "市场综合分析",
      showOrder: false,
      currentDate: new Date(),
      categoryRank: [
        {
          name: "销量",
          rankList: [],
        },
        {
          name: "销售额",
          rankList: [],
        },
      ],
      distributionRank: [
        {
          name: "销量",
          rankList: [],
        },
        {
          name: "销售额",
          rankList: [],
        },
      ],
      ipRank: [
        {
          name: "销量",
          rankList: [],
        },
        {
          name: "销售额",
          rankList: [],
        },
      ],
      flowRank: [
        {
          name: "用户需求指数",
          rankList: [],
        },
        {
          name: "媒体关注指数",
          rankList: [],
        },
      ],
      total: null,
    };
  },
  created() {
    this.getStatisticsMarketReq();
  },
  methods: {
    changeDate(val) {
      this.currentDate = val;
      this.getStatisticsMarketReq();
    },
    getStatisticsMarketReq() {
      let year = this.currentDate.getFullYear();
      let month = this.parseNumber(this.currentDate.getMonth() + 1);
      let param = {
        ym: Number(year + "" + month),
      };
      getStatisticsMarket(param)
        .then((res) => {
          console.log("res", res);
          if (res && res.error == 0) {
            this.total = res.data.total || null;
            if (res.data && res.data.category_price) {
              this.categoryRank[1]["rankList"] = res.data.category_price || [];
            }
            if (res.data && res.data.category_sales) {
              this.categoryRank[0]["rankList"] = res.data.category_sales || [];
            }
            if (res.data && res.data.distribution_price) {
              this.distributionRank[1]["rankList"] =
                res.data.distribution_price || [];
            }
            if (res.data && res.data.distribution_sales) {
              this.distributionRank[0]["rankList"] =
                res.data.distribution_sales || [];
            }
            if (res.data && res.data.ip_price) {
              this.ipRank[1]["rankList"] = res.data.ip_price || [];
            }
            if (res.data && res.data.ip_sales) {
              this.ipRank[0]["rankList"] = res.data.ip_sales || [];
            }
            if (res.data && res.data.media_index) {
              this.flowRank[1]["rankList"] = res.data.media_index || [];
            }
            if (res.data && res.data.search_index) {
              this.flowRank[0]["rankList"] = res.data.search_index || [];
            }
          } else {
            Toast.fail((res && res.msg) || "服务器异常，请稍后重试");
            this.initDefalutData();
          }
          this.toTop();
        })
        .catch((err) => {
          Toast.fail((err && err.msg) || "服务器异常，请稍后重试");
          if (err.error == 20912) {
            this.$router.push("Market");
          } else {
            this.initDefaultData();
          }
          this.toTop();
        });
    },
    initDefalutData() {
      this.categoryRank = [
        {
          name: "销量",
          rankList: [],
        },
        {
          name: "销售额",
          rankList: [],
        },
      ];
      this.distributionRank = [
        {
          name: "销量",
          rankList: [],
        },
        {
          name: "销售额",
          rankList: [],
        },
      ];
      this.ipRank = [
        {
          name: "销量",
          rankList: [],
        },
        {
          name: "销售额",
          rankList: [],
        },
      ];
      this.flowRank = [
        {
          name: "用户需求指数",
          rankList: [],
        },
        {
          name: "媒体关注指数",
          rankList: [],
        },
      ];
      this.total = null;
    },
    parseNumber(num) {
      return num < 10 ? "0" + num : num;
    },
    toTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>
<style lang="less" scoped>
.market-pay-box {
  padding-top: 160px;

  .market-pay-content {
    margin-top: 50px;
    padding-bottom: 20px;
  }
}
</style>