<template>
    <div class="sale-tab-box">
      <div class="tab-list">
        <div class="tab-item"
          :class="[currentType == item.type ? 'active' :'']"
          v-for="(item,idx) in tabList" :key="idx"
          @click="changeTab(item)">
          {{item.name}}
        </div>
      </div>
    </div>
  </template>
  <script>
  export default {
    props: ["tabList","currentType"],
    data() {
      return {
      };
    },
    methods: {
      changeTab(item) {
        this.$emit("changeTab",item.type);
      }
    },
  };
  </script>
  <style lang="less" scoped>
  .sale-tab-box {
    position: relative;
    display: flex;
    .tab-list {
      display: flex;
      height: 60px;
      background: #f3f3f8;
      border-radius: 31px;
      align-items: center;
      margin: 0 auto;
      .tab-item {
        padding: 0 32px;
        font-weight: 400;
        font-size: 27px;
        color: #626a83;
        display: flex;
        justify-content: center;
        height: 100%;
        align-items: center;
        &.active {
          background: #D9E5FF;
          box-shadow: 0 4px 8px 0 #d9e5ff;
          border-radius: 31px;
          color: #3672FD;
        }
      }
    }
  }
  </style>
  