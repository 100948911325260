<template>
  <div class="market-list">
    <div class="market-item"
      v-for="(item,idx) in marketList" :key="idx">
      <div class="market-info">
        <div class="name">{{item.cat && item.cat.name}}</div>
        <div class="desc">{{item.cat && item.cat.desc}}</div>
        <div class="delay">剩余时间：{{item.days}}天</div>
      </div>
      <div class="market-opt">
        <div class="market-btn pay-btn"
          @click="goMarketPay">{{item.days > 0 ? '续费' : '购买'}}</div>
        <div class="market-btn in-btn" :class="[item.days <= 0 && 'disable-btn']"
          @click="goAnalysis(item,idx + 1)">进入</div>
      </div>
    </div>
  </div>
</template>
<script>
import { getUserStatisticsCats } from "@/api/user";
import { Toast } from "vant";
import util from "@/utils/util";
export default {
  data() {
    return {
      marketList: [
      ],
      params: {
          offset: 0,
          limit: 20
      },
    };
  },
  created() {
    util.onLogin().then((res) => {
      if (!res) {
        this.$bus.$emit("openLogin");
      } else {
        this.getUserStatisticsCatsReq();
      }
    });

    this.$bus.$off("initData");
    this.$bus.$on("initData", () => {
      this.getUserStatisticsCatsReq();
    });
  },
  methods: {
    getUserStatisticsCatsReq() {
      getUserStatisticsCats(this.params).then((res) => {
        this.marketList = res && res.data && res.data.list || [];
      }).catch(() => {
        this.marketList = [];
      })
    },
    goMarketPay() {
        this.$router.push({
            path: "/MarkeyPay"
        })
    },
    goAnalysis(item,type) {
      if(item.days <= 0) {
        Toast.fail("请先购买本产品")
        return;
      }
      switch (type) {
        case 1:
          this.$router.push({
            path: "/MarketAnalysis",
          });
          break;
        case 2:
          this.$router.push({
            path: "/MarketIdea",
          });
          break;
        case 3:
          this.$router.push({
            path: "/IpDetail",
          });
          break;
      }
    },
  }
};
</script>
<style lang="less" scoped>
.market-list {
  display: flex;
  flex-direction: column;
  .market-item {
    display: flex;
    padding: 34px 38px 38px 38px;
    margin-bottom: 20px;
    width: 100%;
    background: #f8f8fa;
    border-radius: 20px;
    .market-info {
      flex: 1;
      .name {
        font-weight: bold;
        font-size: 32px;
        color: #1a232f;
        line-height: 46px;
        text-align: left;
      }
      .desc {
        font-weight: 400;
        font-size: 27px;
        color: #626a83;
        line-height: 38px;
        text-align: left;
        margin-top: 6px;
      }
      .delay {
        font-weight: 400;
        font-size: 27px;
        color: #979db1;
        line-height: 38px;
        text-align: left;
        margin-top: 12px;
      }
    }
    .market-opt {
      display: flex;
      align-items: flex-end;
      .market-btn {
        width: 122px;
        height: 53px;
        border-radius: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: 23px;
        margin-right: 20px;
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
      }
      .pay-btn {
        background: #3672fd;
        box-shadow: 0px 4px 8px 0px #d9e5ff;
        color: #ffffff;
      }
      .in-btn {
        background: #d9e5ff;
        box-shadow: 0px 4px 8px 0px #d9e5ff;
        border: 1px solid #3672fd;
        color: #3672fd;
      }
      .disable-btn {
        background: #e7e8ed;
        box-shadow: 0px 4px 8px 0px #e7e8ed;
        border: 1px solid #cccfda;
        color: #979DB1;
      }
    }
  }
}
</style>