<template>
    <div class="sales-data-box">
        <div class="rank-title"><span>应用建议</span></div>
        <AdviceCom :suggestion="suggestion"></AdviceCom>
    </div>
</template>
<script>
    import AdviceCom from "./advice.vue";
    export default {
        props: ["suggestion"],
        components: {
            AdviceCom
        },
        data() {
            return {}
        },
        methods: {
            changeSaleType(type) {
                this.saleType = type;
            }
        }
    }
</script>
<style lang="less" scoped>
    @import url("@/assets/css/rankTitle.less");

    .sales-data-box {
        width: 100%;
        display: flex;
        flex-direction: column;
        background: #FFFFFF;
        border-radius: 20px;
        border: 1px solid #E7E8ED;
    }
</style>