<template>
    <div class="advice-box">
        <div class="advice-content">
            <div class="advice-desc" v-html="markdownToHtml(suggestion && suggestion.suggestion)"></div>
        </div>
    </div>
</template>
<script>
import { marked } from 'marked';
export default {
    props: ["suggestion"],
    methods: {
        markdownToHtml(val) {
            if(val) {
                return marked(val); 
            } else {
                return ""
            }
            
        }
    }
}
</script>
<style lang="less" scoped>
@import url("@/assets/css/rankTitle.less");
.advice-box {
    width: 100%;
    background: #FFFFFF;
    border-radius: 20px;
    .advice-content {
        padding:24px 38px 20px 38px;
        .advice-desc {
            font-weight: 400;
            font-size: 24px;
            color: #626A83;
            line-height: 32px;
            text-align: justify;
            ::v-deep ol, ul {
                padding: revert;
                list-style: decimal;
            }
        }
    }
}
</style>