<template>
    <div class="market-box px-38">
        <NavTab :currentType="currentType"></NavTab>
        <div class="market-content">
            <ContentList></ContentList>
        </div>
        <CompanyInfo v-if="showCompanyInfo" @closeInfo="closeInfo"></CompanyInfo>
    </div>
</template>
<script>
import NavTab from "@/components/header/navTab.vue";
import ContentList from "@/components/market/contentList.vue";
import CompanyInfo from "@/components/market/companyInfo.vue";
import util from "@/utils/util";
export default {
    components: {
        NavTab,
        ContentList,
        CompanyInfo
    },
    data() {
        return {
            currentType: 1,
            showCompanyInfo: false
        }
    },
    created() {
    },
    methods: {
        closeInfo() {
            this.showCompanyInfo = false;
        }
    }
}
</script>
<style lang="less" scoped>
.market-box {
    padding-top: 200px;
    .market-content {
        margin-top: 30px;
    }
}
</style>