<template>
  <div class="problem-box">
    <div class="problem-avatar">
      <img
        src="https://files.sssrise.com/files/uploads/static/ai_avatar.png">
    </div>
    <div class="problem-info">
      <div class="problem-desc">
        <div class="dhk-arrow"><img
            style="width: 40px;height: 32px;"
            src="https://files.sssrise.com/files/uploads/static/dhk_arrow.png">
        </div>
        <div class="input-cursor-box"
          v-if="chatItem.type != 2 && chatItem.type != 3">
          <div class="dot-container">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
          </div>
          <div class="input-cursor"
            style="margin-top:-30px;margin-left: 10px;">
          </div>
        </div>
        <div v-else class="position-relative"
          style="padding: 50px 60px;">
          <img class="position-absolute loading-img"
            src="@/assets/images/photo-fill.png">
          <van-loading mode="circle" size="60rpx;">
          </van-loading>
        </div>
      </div>
    </div>
  </div>
</template>	

<script>
import { Loading } from "vant";
export default {
  components: {
    VanLoading: Loading,
  },
  name: "probleOne",
  props: ["problemItem", "problemIdx", "streamStatus", "chatItem"],
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
@import url(@/assets/css/problemDesc.less);
.share-img {
  position: relative;
  image {
    position: relative;
  }
  .share-btn {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    background: transparent;
    border: none;
    &:after {
      display: none;
    }
  }
}

.dot-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10px;
}

.dot {
  width: 10px;
  height: 10px;
  margin-right: 6px;
  background-color: #333;
  border-radius: 50%;
  position: relative;
  animation: pulse 2s infinite;
}

.dot:nth-child(2) {
  animation-delay: 0.5s;
}

.dot:nth-child(3) {
  animation-delay: 1s;
}

@keyframes pulse {
  0% {
    transform: scale(0.5);
    opacity: 0.5;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
.loading-img {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>