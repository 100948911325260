<template>
  <div class='flex flex-column px-38'>
    <CommonHeader title="订单支付"></CommonHeader>
    <div class="mt-110 station-detail">
      <div
        class="font-27 text-default line-h-38 font-weight-bolder text-left">
        {{orderInfo && orderInfo.product && orderInfo.product.name || ""}}
      </div>
      <div
        class="font-23 text-gray line-h-32 mt-10 text-left">
        {{orderInfo && orderInfo.product && orderInfo.product.desc  || ""}}
      </div>
    </div>
    <div class="pay-info flex flex-column mt-20">
      <div
        class="flex justify-between line-h-42 pay-item px-38">
        <span class="font-27 text-normal">商品价格</span>
        <span
          class="font-27 text-default font-weight-bolder">¥{{orderInfo && orderInfo.price /100 || 0}}</span>
      </div>
      <div
        class="flex justify-between line-h-42 pay-item px-38">
        <span class="font-27 text-normal">VIP优惠</span>
        <span
          class="font-27 text-red font-weight-bolder">-¥{{Number((orderInfo && orderInfo.price) - (orderInfo && orderInfo.pay_price))/100}}</span>
      </div>
      <div
        class="flex justify-between line-h-42 pay-item px-38">
        <span class="font-27 text-normal">合计</span>
        <span
          class="font-27 text-default font-weight-bolder">¥{{orderInfo && orderInfo.pay_price/100 || 0}}</span>
      </div>
      <div class="qrcode">
        <canvas ref="qrcodeCanvas"></canvas>
      </div>
      <div class="bottom-desc">手机微信扫一扫直接支付</div>
    </div>
    <!-- <div
      class="pay-bottom flex justify-between align-center">
      <div class="flex align-center">
        <span class="font-23 text-gray">待支付金额</span>
        <div
          class="font-38 text-primary font-weight-bolder ml-20">
          <text
            class="font-23 mr-10">¥</text>{{orderInfo && orderInfo.pay_price / 100 || 0}}
        </div>
      </div>
      <div class="submit-normal-btn"
        @click="coinOrderPayReq">确认支付</div>
    </div> -->
  </div>
</template>

<script>
import util from "@/utils/util";
import {
  getCoinOrderInfo,
  coinOrderCreate,
  coinOrderPay,
} from "@/api/coinOrder";
import CommonHeader from "@/components/header/commonHeader.vue";
import { Toast } from "vant";
import QRCode from "qrcode";
export default {
  components: {
    CommonHeader,
  },
  data() {
    return {
      stationDetail: {},
      id: null,
      userInfo: null,
      canClick: true,
      orderInfo: null,
      userPrice: 0,
      chatId: "",
      messageIds: "",
      name: "",
      from: "",
      payInter: null,
      currentChatMessageItem: null
    };
  },
  mounted() {
    let query = this.$route.query;
    this.id = query.id || null;
    this.userPrice = (query.userPrice && query.userPrice) || 0;
    this.chatId = (query && query.chatId) || "";
    this.messageIds =
      query && query.messageIds ? decodeURI(query.messageIds) : "";
    this.name = query && query.name ? decodeURI(query.name) : "";
    this.from = query && query.from ? query.from : "";
    this.orderInfo =
      query && query.orderCurrentInfo
        ? JSON.parse(query.orderCurrentInfo)
        : null;
    this.currentChatMessageItem = query && query.currentChatMessageItem ? query.currentChatMessageItem : null;
    util.onLogin().then((res) => {
      if (!res) {
        this.$bus.$emit("openLogin");
      } else {
        if (this.orderInfo && this.from == "order") {
          this.coinOrderPayReq();
        } else {
          this.createCoinOrderReq();
        }
      }
    });

    this.$bus.$off("initData");
    this.$bus.$on("initData", () => {
      if (this.orderInfo && this.from == "order") {
        this.coinOrderPayReq();
      } else {
        this.createCoinOrderReq();
      }
    });
  },
  beforeDestroy() {
    this.clearPayInter();
  },
  deactivated() {
    this.clearPayInter();
  },
  methods: {
    clearPayInter() {
      this.payInter && clearInterval(this.payInter);
    },
    getCoinOrderInfoReq(id) {
      let self = this;
      getCoinOrderInfo(id).then((res) => {
        console.log("res", res);
        if (res && res.error == 0 && res.data && res.data.status == 3) {
          Toast.success("支付完成");
          if (self.chatId && self.messageIds && self.name && self.from == "zhuanfa") {
            this.$router.push({
              path:
                "zhuanfa?chatId=" +
                self.chatId +
                "&messageIds=" +
                encodeURI(self.messageIds) +
                "&name=" +
                encodeURI(self.name),
            });
          } else if (self.from == "index") {
            this.$router.push({
              path: "Ai?currentChatMessageItem=" + this.currentChatMessageItem,
            });
          } else if (self.from == "order") {
            this.$router.push({
              path: "Order",
            });
          } else {
            this.$router.push({
              path: "Station",
            });
          }

          self.$bus.$emit("paySuccess");
          self.$bus.$emit("refreshHeaderUser");
          if (self.isFromOrder) {
            self.$bus.$emit("payOrderSuccess");
          }
          self.clearPayInter();
          self.$emit("closePay");
        }
      });
    },
    createCoinOrderReq() {
      let param = {
        price: this.userPrice,
        product_id: this.id,
      };
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: "创建订单中,请稍后",
      });
      coinOrderCreate(param)
        .then((res) => {
          console.log("coinOrderCreate", res);

          if (res && res.error == 0) {
            this.orderInfo = res.data || null;
            this.coinOrderPayReq();
          } else {
            Toast.clear();
            Toast.fail((res && res.msg) || "服务器异常，请稍后重试");
          }
          // this.getCoinOrderInfoReq();
        })
        .catch((err) => {
          this.canClick = true;
          Toast.clear();
          Toast.fail((err && err.msg) || "服务器异常，请稍后重试");
        });
    },
    coinOrderPayReq() {
      let self = this;
      if (self.from == "order") {
        Toast.loading({
          duration: 0,
          forbidClick: true,
          message: "创建支付二维码中,请稍后",
        });
      }
      if (self.orderInfo && self.orderInfo.id) {
        if (!self.canClick) {
          return;
        }
        self.canClick = false;
        let param = {
          channel: "wx_native",
          id: self.orderInfo && self.orderInfo.id,
        };
        coinOrderPay(param)
          .then((res) => {
            if (res && res.error == 0) {
              // Toast.loading({
              //   duration: 0,
              //   forbidClick: true,
              //   message: "支付中,请稍后",
              // });
              let data = res;
              Toast.clear();
              self.generateQRCode(res.data.code_url);
              self.payInter = setInterval(() => {
                self.getCoinOrderInfoReq(self.orderInfo.id);
              }, 1000);
              // const payParam = {
              //   timeStamp: data.timeStamp.toString(),
              //   nonceStr: data.nonceStr,
              //   package: data.package,
              //   signType: data.signType,
              //   paySign: data.paySign,
              // };
              // console.log("pay param :>> ", payParam);
              // wx.requestPayment({
              //   timeStamp: data.timeStamp.toString(),
              //   nonceStr: data.nonceStr,
              //   package: data.package,
              //   signType: data.signType,
              //   paySign: data.paySign,
              //   success: (sucRes) => {
              //     console.log("pay success res :>> ", sucRes);
              //     setTimeout(() => {
              //       Toast.clear();

              //       if (self.chatId && self.messageIds && self.name) {
              //         // uni.redirectTo({
              //         // 	url: "/pages/zhuanfa/zhuanfa?chatId=" + self.chatId + "&messageIds="+ encodeURI(self.messageIds) +"&name=" + encodeURI(self.name)
              //         // })
              //       } else if ((self.from = "index")) {
              //         // uni.redirectTo({
              //         // 	url:"/pages/release/index"
              //         // })
              //       } else {
              //         // uni.navigateTo({
              //         // 	url: "/pages/order/order"
              //         // })
              //       }
              //       uni.setStorageSync("isReload", 1);
              //       // success && success();
              //     }, 500);
              //   },
              //   fail: (failRes) => {
              //     Toast.clear();
              //     console.log("pay fail res :>> ", failRes);
              //     // fail && fail();
              //   },
              // });
            }
          })
          .catch((err) => {
            Toast.clear();
            this.canClick = true;
            Toast.fail((err && err.msg) || "服务器异常，请稍后重试");
          });
      } else {
        Toast.clear();
        Toast.fail("暂无订单信息");
      }
    },
    generateQRCode(url) {
      QRCode.toCanvas(this.$refs.qrcodeCanvas, url, (error) => {
        if (error) console.error(error);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.station-detail {
  width: 674px;
  padding: 32px 38px 20px 38px;
  background: #d9e5ff;
  border-radius: 19px;
}
.pay-info {
  width: 687px;
  padding: 38px 0;
  background: #f8f8fa;
  border-radius: 19px;
  &.pay-type {
    padding: 30px 0;
  }
  .pay-item {
    margin-bottom: 36px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .pay-image {
    width: 46px;
    height: 46px;
  }
}
.pay-bottom {
  width: 750px;
  padding: 24px 38px 45px 32px;
  background: rgba(243, 243, 248, 0.8);
  box-shadow: inset 0px 1px 0px 0px #e7e8ed;
  backdrop-filter: blur(10px);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
}
.icon-circle {
  width: 38px;
  height: 38px;
  background: #ffffff;
  border: 2px solid #cccfda;
  border-radius: 100%;
  display: inline-block;
}
.qrcode {
  margin-top: 20px;

  img {
    width: 240px;
    height: 240px;
  }

  canvas {
    width: 240px;
    height: 240px;
  }
}

.bottom-desc {
  font-weight: 400;
  font-size: 24px;
  color: #979db1;
  line-height: 32px;
  margin-top: 20px;
}
</style>
